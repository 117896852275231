import { SearchFormValue } from '@applications-terrains/birdz-react-library';
import { Grid } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import moment from 'moment';
import { Dispatch, SetStateAction } from 'react';
import useReinitializeForm from '../../../hooks/useReinitializeForm';

const DatePickerRange = ({
    setFiltersValues,
    filtersValues,
    dateAfterLabel,
    dateBeforeLabel
}: {
    setFiltersValues: Dispatch<SetStateAction<SearchFormValue>>;
    filtersValues: SearchFormValue;
    dateAfterLabel: string;
    dateBeforeLabel: string;
}) => {
    const reset = useReinitializeForm();
    const EmptyDatePicker = () => <DatePicker value={null} />;
    return (
        <Grid container alignItems={'center'} sx={{ mb: 2 }} className='date-picker-range'>
            <Grid container xs={6} alignItems={'center'} sx={{ pr: 1 }}>
                <Grid item xs={3}>
                    Entre le
                </Grid>
                <Grid item xs={9}>
                    {reset ? (
                        <EmptyDatePicker />
                    ) : (
                        <DatePicker
                            value={filtersValues.filterValues[dateAfterLabel] || null}
                            onAccept={(newValue) => {
                                const created_at_after = new Date(
                                    moment(newValue).format('YYYY-MM-DD')
                                );
                                setFiltersValues({
                                    formValue: {
                                        ...filtersValues.formValue,
                                        [dateAfterLabel]: created_at_after
                                    },
                                    filterValues: {
                                        ...filtersValues.filterValues,
                                        [dateAfterLabel]: created_at_after
                                    }
                                });
                            }}
                        />
                    )}
                </Grid>
            </Grid>
            <Grid container xs={6} alignItems={'center'} sx={{ pl: 1 }}>
                <Grid item xs={3}>
                    Et le
                </Grid>
                <Grid item xs={9}>
                    {reset ? (
                        <EmptyDatePicker />
                    ) : (
                        <DatePicker
                            value={filtersValues.filterValues[dateBeforeLabel] || null}
                            onAccept={(newValue) => {
                                const created_at_before = new Date(
                                    moment(newValue).format('YYYY-MM-DD')
                                );
                                setFiltersValues({
                                    formValue: {
                                        ...filtersValues.formValue,
                                        [dateBeforeLabel]: created_at_before
                                    },
                                    filterValues: {
                                        ...filtersValues.filterValues,
                                        [dateBeforeLabel]: created_at_before
                                    }
                                });
                            }}
                        />
                    )}
                </Grid>
            </Grid>
        </Grid>
    );
};

export default DatePickerRange;
