import { TextField } from "@mui/material";
import React from "react";
type ParametersRandomProps = {
  field: any;
  onFieldChange: any;
  value: any;
};

export const ParametersRandom = ({
  field,
  onFieldChange,
  value,
}: ParametersRandomProps) => {
  const generateRandomHexaValue = (
    length: number = 2,
    min: string | null,
    max: string | null
  ) => {
    let minValue = min ? parseInt(min, 16) : null;
    let maxValue = max ? parseInt(max, 16) : null;

    let value = generateRandomNumberValue(length, minValue, maxValue);
    return value.toString(16).padStart(length, "0").toUpperCase();
  };

  const generateRandomBinaryValue = (
    length: number = 2,
    min: string | null,
    max: string | null
  ) => {
    let value = "";
    for (let i = 0; i < length; i++) {
      value += Math.round(Math.random());
    }
    return value;
  };

  const generateRandomNumberValue = (
    length: number = 2,
    min: number | null,
    max: number | null
  ) => {
    let value: number;
    if (min && max) {
      value = Math.round(Math.random() * (max - min) + min);
    } else {
      value = parseInt(
        Math.random()
          .toString()
          .substring(2, length + 2)
      );
    }
    return value;
  };

  let type =
    typeof field.visible !== "undefined" && !field.visible ? "hidden" : "text";
  let size = field.length || 6;
  let min = field.min || null;
  let max = field.max || null;

  switch (field.format) {
    case "binary":
      field.value = generateRandomBinaryValue(size, min, max);
      field.validate = (value: any) => {
        return /^[0-1]+$/i.test(value);
      };
      break;
    case "hexadecimal":
      size *= 2; // Un octet hexa = 2 caractères
      field.value = generateRandomHexaValue(size, min, max);

      field.validate = (value: any) => {
        return /^[0-9A-Fa-f]+$/i.test(value);
      };
      break;
    case "integer":
    default:
      field.value = field.validate = (value: any) => {
        return /^[0-9]+$/i.test(value);
      };
      break;
  }

  if (!value) {
    onFieldChange(field.id, field.value);
  } else {
    field.value = value;
  }

  field.value = field.value || "";
  return (
    <TextField
      type={type}
      size="small"
      fullWidth
      onChange={(e: any) => {
        onFieldChange(field.id, e.target.value);
      }}
      value={field.value}
      disabled={field.disabled === true}
    />
  );
};
