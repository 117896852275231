import React from "react";
import { useDialog, BirdzDialog, Item, FormField, StyledTitle, AppPaper, useNotif, BirdzNotif } from '@applications-terrains/birdz-react-library';
import { Link } from "react-router-dom";
import moment from "moment";
import * as Yup from "yup";
import { DeviceGroup } from "../types";
import Axios from "axios";
import { authService } from "../..";
import { Button, Grid, IconButton } from "@mui/material";
import { AddCircle, Delete, Edit } from "@mui/icons-material";
import ListPageWrapper from "../Tools/ListPageWrapper";


type DeviceGroupsProps = {
  action: "list" | "add" | "edit";
}

const DeviceGroups = ({ action }: DeviceGroupsProps) => {
  const endpoint = "/api/device-groups/";
  const { confirmDialog, closeDialog, dialogOptions } = useDialog();
  const { notif, notifOptions } = useNotif();

  let formFields: FormField[] = [
    {
      name: "name", type: "text", label: "Nom",
      validation: Yup.string()
        .required("Le nom est obligatoire")
    },
    {
      name: "technical_code",
      type: "text",
      label: "Code technique",
      validation: Yup.string()
        .required("Le code technique est obligatoire")
    },
    {
      name: "point_type",
      type: "text",
      label: "PointsTypes",
    },
    {
      name: "is_active",
      type: "radio",
      label: "Etat",
      options: {
        values: [
          { value: true, label: "Actif" },
          { value: false, label: "Inactif" },
        ],
      },
      defaultValue: true,
      permissions: ["IS_ACTIVE"],
    },
    {
      name: "updated_at",
      type: "readonly",
      label: "Dernière modification",
      transform: (value: any) => {
        let date =
          value && moment.unix(value).format("DD/MM/YYYY à HH:mm:ss");
        return date || "";
      },
    },
  ];
  let listFields = [
    { name: "name", label: "Nom", orderable: true },
    {
      name: "is_active",
      label: "Actif",
      className: "text-center",
      options: { width: "100px" },
      orderable: true,
    },
    {
      name: "updated_at",
      label: "Date de modification",
      options: { width: "220px" },
      orderable: true,
      transform: (value: any) => {
        return (
          (value && moment.unix(value).format("DD/MM/YYYY à HH:mm:ss")) || ""
        );
      },
    },
  ];

  let actions = [
    {
      name: "edit",
      label: "Modifier le device group",
      render: (deviceGroup: DeviceGroup) => {
        return <IconButton component={Link} to={`/models/parameters/device-groups/edit/${deviceGroup.id}`}>
          <Edit fontSize="small" />
        </IconButton>
      }
    },
    {
      name: "delete-devicegroup",
      render: (deviceGroup: DeviceGroup) => {
        return <IconButton onClick={() => {
          confirmDialog({
            title: "Supprimer le device group",
            content: "Êtes-vous sûr de vouloir supprimer ce device group?",
            onValidate: () => {
              Axios.delete(`${endpoint}${deviceGroup.id}/`).then(
                () => {
                  notif({
                    content: "Le device group a été supprimé avec succès",
                    type: "success",
                  });
                  document.location.reload();
                },
                () => {
                  notif({
                    content: "Une erreur est survenue lors de la suppression",
                    type: "error",
                  });
                }
              )
              closeDialog();
            },
            onCancel: () => {
              closeDialog();
            }
          })
        }}><Delete fontSize="small" /></IconButton>
      }
    },
  ];

  return (
    <AppPaper>
      {action === "list" && (
        <>
          <Grid justifyContent="space-between" container>
            <Grid item>
              <StyledTitle>Liste des device groups</StyledTitle>
            </Grid>
            {authService.canAccess("WRITE_PARAMETERS") && (
              <Grid item>
                <Link to="/models/parameters/device-groups/add">
                  <Button variant="contained" sx={{ mt: 3 }}>
                    <AddCircle sx={{ mr: 1 }} /> Ajouter un device group
                  </Button>
                </Link>
              </Grid>
            )}
          </Grid>

          <ListPageWrapper
            endpoint={endpoint}
            fields={listFields}
            actions={authService.canAccess("WRITE_PARAMETERS") ? actions : []}
            defaultOrder={['name']}
          />
          <BirdzDialog options={dialogOptions} />
        </>
      )}
      {action === "add" && (
        <Item
          action="add"
          endpoint={endpoint}
          fields={formFields}
          name="Ajouter un device group"
        />
      )}
      {action === "edit" && (
        <Item
          action="edit"
          endpoint={endpoint}
          fields={formFields}
          name="Modifier le device group"
        />
      )}
      <BirdzNotif options={notifOptions} />
    </AppPaper>
  );
}

export default DeviceGroups;