import React, { useCallback, useEffect, useState } from 'react';
import _ from 'lodash';
import { SelectedValue } from '../types';
import dataService from '../Common/Services/dataService';
import { Alert, Grid } from '@mui/material';
import { AutocompleteField, FormField } from '@applications-terrains/birdz-react-library';
import { useDuplicateCampaignContext } from '../../contexts/DuplicateCampaignContext';

type CampaignFrameTemplateProps = {
    onChange(selectedFrameTemplate: SelectedValue): void;
    type?: string;
    protocolId?: number;
    device_types?: number[];
    hasCombinedAddress?: boolean;
};

export const CampaignFrameTemplate = ({
    onChange,
    type,
    protocolId,
    device_types,
    hasCombinedAddress
}: CampaignFrameTemplateProps) => {
    const defaultEndpoint = '/api/frame-templates/';
    let [endpoint, setEndpoint] = useState<string>('');
    const [refresh, setRefresh] = useState<boolean>(true); // used to refresh the component when the protocol changes
    const [selectedFrameTemplate, setSelectedFrameTemplate] = useState<any>();
    const [displayWarning, setDisplayWarning] = useState<boolean>(false);

    const { duplicate } = useDuplicateCampaignContext();

    useEffect(() => {
        if (duplicate) {
            setSelectedFrameTemplate({
                value: duplicate.campaign.frame_template,
                label: duplicate.campaign.template_name
            });
        }
    }, [duplicate]);

    const buildUrl: any = useCallback(() => {
        let url: string = defaultEndpoint;

        let criterias: string[] = [];

        if (type) {
            criterias.push('type=' + type);
        }

        if (protocolId) {
            criterias.push('protocol=' + protocolId);
        }

        if (device_types) {
            criterias.push('device_types=' + _.uniq(device_types).join(','));
        }

        if (typeof hasCombinedAddress === 'boolean') {
            criterias.push(`with_combined_template=${hasCombinedAddress ? 'true' : 'false'}`);
        }

        const newEndpoint = url + '?' + criterias.join('&') + '&ordering=name&';
        if (endpoint !== '' && newEndpoint !== endpoint) {
            setRefresh(false);
            setTimeout(() => {
                setRefresh(true);
            }, 100);
        }
        setEndpoint(newEndpoint);
        //eslint-disable-next-line
    }, [device_types, protocolId, type, hasCombinedAddress]);

    useEffect(() => {
        buildUrl();
    }, [device_types, type, buildUrl]);

    return (
        <>
            {refresh && (
                <Grid container spacing={1}>
                    <Grid item xs={4}>
                        Sélection modèle intention
                    </Grid>
                    <Grid item xs={8}>
                        {endpoint && (
                            <>
                                {displayWarning && (
                                    <Alert severity="info" sx={{ mb: 1 }}>
                                        Aucun template commun disponible pour les types de module :{' '}
                                        {device_types &&
                                            _.uniq(device_types)
                                                .map((deviceType) => {
                                                    return (
                                                        dataService.getDeviceType(deviceType) ||
                                                        deviceType
                                                    );
                                                })
                                                .join(', ')}
                                    </Alert>
                                )}

                                <AutocompleteField
                                    field={
                                        {
                                            options: {
                                                source: endpoint,
                                                searchIsMulti: false,
                                                label: 'name'
                                            },
                                            name: 'campaignModel'
                                        } as FormField
                                    }
                                    onSelect={(selectedFrameTemplate: SelectedValue) => {
                                        setSelectedFrameTemplate(selectedFrameTemplate);
                                        if (
                                            !selectedFrameTemplate ||
                                            !selectedFrameTemplate.value
                                        ) {
                                            onChange(null);
                                        } else {
                                            onChange(selectedFrameTemplate);
                                        }
                                    }}
                                    onDataLoaded={(data: any) => {
                                        setDisplayWarning(!data || data.length === 0);
                                    }}
                                    value={selectedFrameTemplate}
                                />
                            </>
                        )}
                    </Grid>
                </Grid>
            )}
        </>
    );
};
