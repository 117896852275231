import { useId, useState } from "react";
import { useTranslation } from "react-i18next";
import moment from "moment";
import "./PurposeDetail.scss";
import { Box, Grid, Paper, Table, TableBody, TableCell, TableContainer, TableHead } from "@mui/material";
import { StyledTableBodyRow, StyledTableHeaderRow } from "@applications-terrains/birdz-react-library";
import { ArrowDropDown, ArrowRight } from "@mui/icons-material";

interface Props {
    value: any;
}

function PurposeDetail(props: Props) {
    const { t } = useTranslation();
    const id = useId();

    const basicFields = [
        "campaign__operator__name",
        "campaign__template_name",
        "state",
        "frame",
        "encrypted_frame",
        "encapsulated_frame",
        "operator_command_id",
    ];

    const [payloadVisibility, setPayloadVisibility] = useState<{
        [field: string]: boolean;
    }>({});
    const [historyVisibility, setHistoryVisibility] = useState<any>({});

    return (
        <span className="purpose-detail" key={id}>
            <Grid container spacing={1}>
                {Object.keys(props.value).map((fieldName: any) => {
                    if (basicFields.includes(fieldName)) {
                        return (<>
                            <Grid item xs={2}>{t(fieldName)}</Grid>
                            <Grid item xs={10} className="word-break">
                                {props.value[fieldName]}
                            </Grid>
                        </>
                        );
                    } else {
                        switch (fieldName) {
                            case "device":
                                return (
                                    <>
                                        <Grid item xs={2}>{t(fieldName)}</Grid>
                                        <Grid item xs={10}>{props.value.device.module_address} {props.value.device.concentrator ? `(concentrateur: ${props.value.device.concentrator})` : ''}</Grid>


                                        {(props.value.device.deveui) ? (
                                            <>
                                                <Grid item xs={2}>DevEUI</Grid>
                                                <Grid item xs={10}>{props.value.device.deveui}</Grid>
                                            </>
                                        ) : (
                                            null
                                        )}
                                    </>
                                );
                            case "campaign__max_days":
                                return (
                                    <>
                                        <Grid item xs={2}>{t(fieldName)}</Grid>
                                        <Grid item xs={10}>{props.value?.campaign__max_days}</Grid>
                                    </>
                                );
                            case "codec_path":
                                return (
                                    <>
                                        <Grid item xs={2}>{t(fieldName)}</Grid>
                                        <Grid item xs={10}>{props.value?.codec_path}</Grid>
                                    </>
                                );
                            case "created_at":
                                return (
                                    <>
                                        <Grid item xs={2}>{t(fieldName)}</Grid>
                                        <Grid item xs={10}>
                                            {moment
                                                .unix(props.value.created_at)
                                                .format("DD/MM/YYYY à HH:mm:ss")}
                                        </Grid>
                                    </>
                                );
                            case "scheduled_date":
                                return (
                                    <>
                                        <Grid item xs={2}>{t(fieldName)}</Grid>
                                        <Grid item xs={10}>
                                            {props.value.scheduled_date
                                                ? moment(props.value.scheduled_date)
                                                    .format("DD/MM/YYYY à HH:mm:ss")
                                                : "-"}
                                        </Grid>
                                    </>
                                );
                            case "payload_clear":
                            case "campaign__header_config":
                                return (
                                    <>
                                        <Grid item xs={2}>{t(fieldName)}</Grid>
                                        <Grid item xs={10}>
                                            <Box
                                                onClick={() => {
                                                    setPayloadVisibility({
                                                        ...payloadVisibility,
                                                        ...{ [fieldName]: !payloadVisibility[fieldName] },
                                                    });
                                                }}
                                                className="link"
                                                sx={{ display: 'flex', alignItems: 'center' }}
                                            >
                                                {payloadVisibility[fieldName]
                                                    ? [
                                                        <ArrowDropDown fontSize="small" key={'arrowDropDown'+fieldName}/>,
                                                        " Masquer",
                                                    ]
                                                    : [
                                                        <ArrowRight fontSize="small" key={'arrowRight'+fieldName} />,
                                                        " Afficher",
                                                    ]}{" "}
                                                le payload
                                            </Box>
                                            {payloadVisibility[fieldName] && (
                                                <pre className="word-break">
                                                    {JSON.stringify(props?.value[fieldName], null, 2)}
                                                </pre>
                                            )}
                                        </Grid>
                                    </>
                                );
                            case "history":
                                return (
                                    <>
                                        <Grid item xs={2}>{t(fieldName)}</Grid>
                                        <Grid item xs={10}>
                                            {props.value.history &&
                                                props.value.history.map(
                                                    (historyItem: any, historyIndex: number) => (
                                                        <TableContainer component={Paper} key={'historyItem'+historyIndex}>
                                                            <Table size="small"
                                                                key={historyItem.created_at}
                                                            >
                                                                <TableHead>
                                                                    <StyledTableHeaderRow>
                                                                        <TableCell>
                                                                            {moment(historyItem.created_at).format(
                                                                                "DD/MM/YYYY à HH:mm:ss"
                                                                            )}
                                                                            <span className="status">
                                                                                {historyItem.state}{" "}
                                                                                {historyItem.error_string
                                                                                    ? "(" + historyItem.error_string + ")"
                                                                                    : ""}
                                                                            </span>
                                                                        </TableCell>
                                                                    </StyledTableHeaderRow>
                                                                </TableHead>
                                                                <TableBody>
                                                                    <StyledTableBodyRow>
                                                                        <TableCell>
                                                                            <Box
                                                                                onClick={() => {
                                                                                    setHistoryVisibility({
                                                                                        ...historyVisibility,
                                                                                        ...{
                                                                                            [historyIndex]: !historyVisibility[
                                                                                                historyIndex
                                                                                            ],
                                                                                        },
                                                                                    });
                                                                                }}
                                                                                className="link"
                                                                                sx={{ display: 'flex', alignItems: 'center' }}
                                                                            >
                                                                                {historyVisibility[historyIndex]
                                                                                    ? [
                                                                                        <ArrowDropDown fontSize="small" key={'arrowDropDown'+historyIndex}/>,
                                                                                        " Masquer",
                                                                                    ]
                                                                                    : [
                                                                                        <ArrowRight fontSize="small" key={'arrowRight'+historyIndex} />,
                                                                                        " Afficher",
                                                                                    ]}{" "}
                                                                                la réponse
                                                                            </Box>
                                                                            {historyVisibility[historyIndex] && (
                                                                                <pre className="word-break">
                                                                                    {JSON.stringify(
                                                                                        historyItem.response_detail,
                                                                                        null,
                                                                                        2
                                                                                    )}
                                                                                </pre>
                                                                            )}
                                                                        </TableCell>
                                                                    </StyledTableBodyRow>
                                                                </TableBody>
                                                            </Table>
                                                        </TableContainer>
                                                    )
                                                )}
                                        </Grid>
                                    </>
                                );
                            default:
                                return null;
                        }
                    }
                })
                }
            </Grid>
        </span>
    );
}

export default PurposeDetail;
