import { useEffect, useState } from 'react';
import { csvHeaderType } from '../CampaignUploadDevicesList/CampaignDevicesString';
import { useDuplicateCampaignContext } from '../../../contexts/DuplicateCampaignContext';

type Value = string | number | string[];

const useDuplicationDevicesAndHeaders = (availableHeaders?: csvHeaderType[]) => {
    const { duplicate, setClickToCheckDevices, clickToCheckDevices } =
        useDuplicateCampaignContext();

    const [valuesToDuplicate, setValuesToDuplicate] = useState<{ header: string; value: Value }[]>(
        []
    );

    const [shouldImportDuplicationValues, setShouldImportDuplicationValues] = useState<{
        headers: boolean;
        values: boolean;
    }>({
        headers: false,
        values: false
    });

    useEffect(() => {
        if (duplicate) {
            if (availableHeaders) {
                const { purpose } = duplicate;
                const baseHeaders = Object.keys(purpose.device).filter((el) => el !== 'deveui');
                const values: { header: string; value: Value }[] = [];
                baseHeaders.forEach((header) => {
                    const key =
                        !header.includes('address') && header[header.length - 1] === 's'
                            ? header.substring(0, header.length - 1)
                            : header;

                    const value = purpose.device[header] as Value;

                    if (typeof value === 'string' || key === 'affiliate') {
                        values.push({ header: key, value });
                    } else {
                        return (
                            Array.isArray(value) &&
                            value.map((val, index) => {
                                return Object.entries(val).forEach((el) => {
                                    const objHeader = el[0] === 'module_address' ? key : el[0];
                                    values.push({
                                        header: `${objHeader}_${index + 1}`,
                                        value: el[1] as string | number
                                    });
                                });
                            })
                        );
                    }
                });

                const orderedValues = availableHeaders
                    .map((header) => {
                        const correspondance = values.find((value) => value.header === header.name);
                        return correspondance;
                    })
                    .filter((el) => el) as { header: string; value: string | number }[];
                setValuesToDuplicate(orderedValues);
            }
        } else {
            setValuesToDuplicate([]);
        }
    }, [duplicate, availableHeaders]);

    useEffect(() => {
        valuesToDuplicate.length &&
            setShouldImportDuplicationValues((obj) => ({
                ...obj,
                values: true,
                headers: true
            }));
    }, [valuesToDuplicate]);

    const setDevices = (cb: (a: string) => void) => {
        const affiliate = valuesToDuplicate.find((el) => el.header === 'affiliate');
        if (affiliate) {
            const affiliates = affiliate?.value as string[];
            const rows = affiliates.map((el) => {
                const objWithSingleValue = {
                    header: 'affiliate',
                    value: el
                };
                const row = valuesToDuplicate.map((el) =>
                    el.header === 'affiliate' ? objWithSingleValue : el
                );
                return row.map((el) => el.value).join(';');
            });
            cb(rows.join('\n'));
        } else {
            const values = valuesToDuplicate.map((el) => el.value).join(';');
            cb(values);
        }
        setShouldImportDuplicationValues((obj) => ({
            ...obj,
            values: false
        }));
    };

    const setHeaders = (cb: (a: { label: string; value: string }[]) => void) => {
        const headers = valuesToDuplicate.map((el) => ({ value: el.header, label: el.header }));
        cb(headers);
        setShouldImportDuplicationValues((obj) => ({
            ...obj,
            headers: false
        }));
    };

    return {
        shouldImportDuplicationValues,
        setDevices,
        setHeaders,
        duplicate: duplicate !== null,
        setClickToCheckDevices,
        clickToCheckDevices
    };
};

export default useDuplicationDevicesAndHeaders;
