import { Alert } from '@mui/material';
import React from 'react';
import useExtendableList from '../../hooks/useExtendableList';
type CampaignDeviceWarningProps = {
    warnings?: string[] | undefined;
};

const CampaignDeviceWarning = ({ warnings }: CampaignDeviceWarningProps) => {
    const { listToDisplay, SeeMore } = useExtendableList(warnings || []);

    return (
        <>
            {warnings && Array.isArray(warnings) && warnings.length > 0 && (
                <Alert severity="warning">
                    <ul>
                        {(listToDisplay as string[]).map((warning: string, index: number) => (
                            <li key={'warning-' + index}>{warning}</li>
                        ))}
                    </ul>
                    <SeeMore />
                </Alert>
            )}
        </>
    );
};

export default CampaignDeviceWarning;
