import { Delete, Refresh, Search } from '@mui/icons-material';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { authService } from '../../../..';
import { SpeedDial, SpeedDialAction, SpeedDialIcon, styled } from '@mui/material';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import {
    BirdzDialog,
    BirdzNotif,
    useDialog,
    useNotif
} from '@applications-terrains/birdz-react-library';

const DisplayActions = ({
    campaign,
    refreshComponent
}: {
    campaign: any;
    refreshComponent: () => void;
}) => {
    const navigate = useNavigate();
    const { notif, notifOptions } = useNotif();
    const { confirmDialog, closeDialog, dialogOptions } = useDialog();

    const actions = [
        {
            icon: <Search />,
            name: 'Voir les intentions de la campagne',
            onClick: () => navigate('/sent-list?tab=purposes&campaign=' + campaign.id)
        }
    ];

    if (campaign.state === 'error')
        actions.push({
            icon: <Refresh />,
            name: 'Relancer la campagne',
            onClick: () => {
                axios.post('/api/retry-campaign/', { campaign_id: campaign.id }).then(
                    () => {
                        notif({
                            type: 'success',
                            content:
                                "La campagne va être relancée, vous pourrez la consulter d'ici quelques instants"
                        });
                    },
                    (error: any) => {
                        notif({
                            type: 'error',
                            content: (
                                <>
                                    Une erreur est survenue lors de la relance de la campagne
                                    <br />
                                    {error?.response?.data?.message}
                                </>
                            )
                        });
                    }
                );
            }
        });

    const deleteCampaignAction: any = {
        icon: <Delete />,
        name: 'Supprimer la campagne',
        onClick: () => {
            confirmDialog({
                title: 'Suppression de la campagne ',
                content:
                    'Voulez-vous supprimer la campagne `' +
                    campaign.name +
                    '` (' +
                    campaign.id +
                    ') ?',
                onValidate: () => {
                    axios
                        .delete('/api/campaigns/' + campaign.id + '/')
                        .then(
                            (response: any) => {
                                notif({
                                    content: 'Suppression effectuée avec succès',
                                    type: 'success'
                                });
                                refreshComponent();
                            },
                            (error) => {
                                notif({
                                    content: 'Une erreur est survenue lors de la suppression',
                                    type: 'error'
                                });
                            }
                        )
                        .finally(() => {
                            closeDialog();
                        });
                },
                onCancel: () => {
                    closeDialog();
                }
            });
        }
    };

    if (authService.canAccess('DELETE_CAMPAIGN')) {
        actions.push(deleteCampaignAction);
    }

    const StyledSpeedDial = styled(SpeedDial)(() => ({
        position: 'absolute',
        right: -4,
        height: '20px',
        '& .MuiSpeedDial-actions': {
            paddingRight: '25px'
        },
        '& .MuiSpeedDialAction-fab': {
            transform: 'scale(0.85)',
            margin: '2px'
        }
    }));

    return (
        <div style={{ position: 'relative', width: '50px', height: '20px' }}>
            <StyledSpeedDial
                ariaLabel={'actions' + campaign.id}
                FabProps={{
                    style: { boxShadow: 'none', transform: 'scale(0.5)' },
                    color: 'inherit'
                }}
                direction="left"
                icon={
                    <SpeedDialIcon
                        style={{ transform: 'scale(1.5)' }}
                        icon={<MoreHorizIcon />}
                        openIcon={<MoreVertIcon />}
                    />
                }>
                {actions.map((action) => (
                    <SpeedDialAction
                        key={action.name}
                        icon={action.icon}
                        tooltipTitle={action.name}
                        onClick={action.onClick}
                    />
                ))}
            </StyledSpeedDial>
            <BirdzDialog options={dialogOptions} />
            <BirdzNotif options={notifOptions} />
        </div>
    );
};

export default DisplayActions;
