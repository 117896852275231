import React from "react";
import axios from "axios";
import { BirdzDialog, useDialog, AppPaper, StyledTitle, FormField, Item, BirdzNotif, useNotif } from '@applications-terrains/birdz-react-library';
import "./Users.scss";
import { Link } from "react-router-dom";
import { User } from "@sentry/browser";
import moment from "moment";
import dataService from "../Common/Services/dataService";
import * as Yup from "yup";
import { Group } from "../types";
import Axios from "axios";
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { Box, Button, Grid, IconButton, LinearProgress, Typography } from "@mui/material";
import { Delete, Edit, ManageAccounts } from "@mui/icons-material";
import { authService } from "../..";
import ListPageWrapper from "../Tools/ListPageWrapper";

const Users = (params: any) => {
  const endpoint = "/api/users/";
  const { confirmDialog, closeDialog, dialogOptions } = useDialog();
  const { notif, notifOptions } = useNotif();

  const transformItemOnSave = (user: User) => {
    if (!user.password) {
      delete user.password;
    }
    if (user.contracts) {
      user.contracts = user.contracts.map((contract: any) => {
        if (typeof contract !== "string") {
          return contract.code;
        }
        return contract;
      });
    }
    if (user.customers) {
      user.customers = user.customers.map((customer: any) => {
        if (typeof customer !== "string") {
          return customer.code;
        }
        return customer;
      });
    }

    user.purposes_quota = user.purposes_quota || null;

    return user;
  }

  let formFields: FormField[] = [
    {
      name: "name",
      type: "text",
      label: "Nom",
      validation: Yup.string().required("Vous devez renseigner le nom"),
    },
    {
      name: "email",
      type: "text",
      label: "Email",
      validation: Yup.string()
        .email("Vous devez renseigner un e-mail valide")
        .required("L'email ne peut être vide"),
    },
    {
      name: "password",
      type: "password",
      label: "Mot de passe",
    },
    {
      name: "is_active",
      type: "radio",
      label: "Etat",
      options: {
        values: [
          { value: true, label: "Actif" },
          { value: false, label: "Inactif" },
        ],
      },
      defaultValue: true,
      permissions: ["IS_ACTIVE"],
    },
    { name: "avatar", type: "image", label: "Photo" },

    {
      name: "groups",
      type: "select-multiple",
      label: "Groupes rattachés",
      options: {
        values: dataService.getSelectData("groups", { is_active: true }, { label: 'name' })
      },
    },
    {
      name: "customers",
      type: "autocomplete",
      label: "Clients rattachés",
      options: {
        source: "/api/customers/?ordering=code&",
        identifier: "code",
        label: "code",
      }
    },
    {
      name: "contracts",
      type: "autocomplete",
      label: "Contrats rattachés",
      options: {
        source: "/api/contracts/?ordering=code&",
        identifier: "code",
        label: "code",
      },
    },
    {
      name: "purposes_quota",
      type: "number",
      label: "Quota utilisateur",
    },
    {
      name: "used_quota",
      type: "readonly",
      label: "Quota utilisé pour le mois en cours",
    },
    {
      name: "last_login",
      type: "readonly",
      label: "Dernière connexion",
      transform: (value: any) => {
        let date = value && moment(value).format("DD/MM/YYYY à HH:mm:ss");
        return date || "";
      },
    },
  ];

  let listFields = [
    {
      name: "name",
      label: "Nom",
      orderable: true,
    },
    {
      name: "groups",
      label: "Groupes",
      transform: (values: any) => {
        let dataServiceGroups = dataService.getData("groups") as Group[];
        let groups = values.map((value: number) => {
          let group = dataServiceGroups.find(
            (dataServiceGroup) => dataServiceGroup.id === value
          );
          return group?.name || value;
        });
        return groups.join(", ");
      },
    },
    {
      name: "is_active",
      label: "Actif",
      className: "text-center",
      orderable: true,
      options: { width: "100px" },
    },
    {
      name: "last_login",
      label: "Dernière connexion",
      transform: (value: any) => {
        return (value && moment(value).format("DD/MM/YYYY à HH:mm:ss")) || "";
      },
      orderable: true,
      options: { width: "210px" },
    },
    {
      name: "used_quota",
      label: "Quota intentions",
      transform: (value: any, object: any) => {
        let rate =
          value && object.purposes_quota
            ? (+value / object.purposes_quota) * 100
            : 0;

        return (
          <Box sx={{ position: 'relative', dsplay: 'flex' }}>
            <LinearProgress
              variant="determinate"
              value={rate || 0}
              className="linearProgress"
              sx={{ height: '20px' }}
            />
            <Typography sx={{ 'position': 'absolute', top: 0, left: 0, right: 0, fontSize: 10, textAlign: 'center', pt: '4px', color: 'white', textShadow: '0px 0px 4px black' }}>{`${rate || 0}%`}</Typography>
          </Box>);
      },
      orderable: true,
    },
  ];

  let searchFields = [
    {
      name: "groups",
      label: "Groupe",
      options: {
        source: "/api/lists/groups/",
        searchIsMulti: false
      },
    },
  ];

  let actions = [
    {
      name: "edit",
      render: (value: any) => {
        return <IconButton component={Link} to={`/admin/users/edit/${value.id}`}>
          <Edit fontSize="small" />
        </IconButton>
      }
    },
    {
      name: "delete-user",
      render: (model: any) => {
        return <IconButton onClick={() => {
          confirmDialog({
            title: "Supprimer l'utilisateur",
            content: "Êtes-vous sûr de vouloir supprimer cet utilisateur?",
            onValidate: () => {
              Axios.delete(`${endpoint}${model.id}/`).then(
                () => {
                  notif({
                    content: "L'utilisateur a été supprimé avec succès",
                    type: "success",
                  });
                  document.location.reload();
                },
                () => {
                  notif({
                    content: "Une erreur est survenue lors de la suppression",
                    type: "error",
                  });
                }
              )
              closeDialog();
            },
            onCancel: () => {
              closeDialog();
            }
          })
        }}><Delete fontSize="small" /></IconButton>
      }
    },
  ];

  const connectAsAction: any = {
    name: "connect-as",
    label: "Se connecter en tant que",
    render: (user: any) => <IconButton onClick={() => {
      axios.post("/api/auth/as/ ", { user_id: user.id }).then(
        () => {
          notif({
            type: "success",
            content:
              "Vous êtes maintenant connectés en tant que " +
              user.name +
              ". La page va maintenant se recharger",
          });

          setTimeout(() => {
            window.location.reload();
          }, 2500);
        },
        (error) => {
          let message = "";
          if (error.response.status === 401) {
            message = "Vous n'êtes pas autorisés à utiliser cette fonction";
          }

          notif({
            type: "error",
            content: message,
          });
        }
      );
    }}><ManageAccounts fontSize="small" /></IconButton>

  };

  if (authService.canAccess("LOGIN_AS")) {
    actions.push(connectAsAction);
  }

  return (
    <AppPaper>
      {params.action === "list" && (
        <>
          <Grid justifyContent="space-between" container>
            <Grid item>
              <StyledTitle>Liste des utilisateurs</StyledTitle>
            </Grid>
            <Grid item>
              <Link to="/admin/users/add">
                <Button variant="contained" sx={{ mt: 3 }}>
                  <AddCircleIcon sx={{ mr: 1 }} /> Ajouter un utilisateur
                </Button>
              </Link>
            </Grid>
          </Grid>

          <ListPageWrapper
            endpoint={endpoint}
            fields={listFields}
            actions={actions}
            searchFields={searchFields}
            defaultOrder={['name']}
          />
          <BirdzDialog options={dialogOptions} />
        </>
      )}
      {params.action === "add" && (
        <Item
          action="add"
          endpoint={endpoint}
          fields={formFields}
          name="Ajouter un utilisateur"
          transformItemOnSave={transformItemOnSave}
        />
      )}
      {params.action === "edit" && (
        <Item
          action="edit"
          endpoint={endpoint}
          fields={formFields}
          name="Modifier l'utilisateur"
          transformItemOnSave={transformItemOnSave}
        />
      )}
      <BirdzNotif options={notifOptions} />
    </AppPaper>
  );
}

export default Users;