import { Button, Grid, TextField } from "@mui/material";
import React from "react";
import UploadIcon from '@mui/icons-material/Upload';

type UploadFileProps = {
  onChange(data: any): any;
  label?: string;
  buttonLabel?: string;
};

export const UploadFile = ({
  onChange,
  label,
  buttonLabel,
}: UploadFileProps) => {
  const handleFile = (e: React.ChangeEvent<HTMLInputElement>) => {
    let selectedFile = e.target.files?.length ? e.target.files[0] : null;
    const reader = new FileReader();
    reader.onload = (event: any) => {
      onChange(event.target.result.toString());
    };
    if (selectedFile !== null) {
      reader.readAsText(selectedFile);
    }
  };

  return (
    <Grid container spacing={1}>
      <Grid item xs={3}>{label || "Importer un fichier csv"}</Grid>
      <Grid item xs={9}>
        <Button variant="outlined" component="label">
          <UploadIcon sx={{ mr: 1 }} /> Choisir un fichier
          <TextField
            margin="dense"
            id="file"
            label={buttonLabel || "Choisir un fichier"}
            type="file"
            fullWidth
            variant="standard"
            hidden
            inputProps={{ accept: ".csv" }}
            sx={{ display: 'none' }}
            onChange={(event: any) => {
              handleFile(event);
            }}
          />
        </Button>
      </Grid>
    </Grid>
  );
};
