import { AppPaper } from '@applications-terrains/birdz-react-library';
import Parameters from '../Parameters/Parameters';
import React from 'react';
import { Trans } from 'react-i18next';

type CampaignHeaderConfigProps = {
    onChange(selectedFrameTemplate: any): any;
    label: string;
    value?: any;
    onErrors?(errors: string[]): void;
};

const HeaderConfig = ({
    onChange,
    onErrors,
    label = '',
    value = {}
}: CampaignHeaderConfigProps) => {
    return (
        <AppPaper sx={{ mt: 1 }}>
            <h3>
                <Trans>{label ? label : 'Étape: header'}</Trans>
            </h3>

            <Parameters
                codec={'header_config'}
                onChange={onChange}
                onErrors={onErrors}
                values={value || {}}
                setEmptyValuesToNull={false}
            />
        </AppPaper>
    );
};

export const CampaignHeaderConfig = React.memo(HeaderConfig);
