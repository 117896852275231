import { ReactNode, createContext, useContext, useState } from 'react';

type IsLoadingContextType = {
    isLoading: boolean;
    setIsLoading: (value: boolean) => void;
};

const IsLoadingContext = createContext<IsLoadingContextType | undefined>(undefined);

export const useIsLoadingContext = () => {
    const context = useContext(IsLoadingContext);

    if (context === undefined) {
        throw new Error('useIsLoadingContext must be used with a IsLoadingContext');
    }

    return context;
};

export const IsLoadingContextProvider = ({ children }: { children: ReactNode }) => {
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const value = {
        isLoading,
        setIsLoading
    };

    return <IsLoadingContext.Provider value={value}>{children}</IsLoadingContext.Provider>;
};
