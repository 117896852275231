import React, { useEffect, useState } from 'react';
import { AppPaper, StyledTitle } from '@applications-terrains/birdz-react-library';
import { Grid } from '@mui/material';
import axios from 'axios';
import ListPageWrapper from '../Tools/ListPageWrapper';
import { useIsLoadingContext } from '../../contexts/IsLoadingContext';

const Customers = () => {
    const [data, setData] = useState<object[]>([]);
    const { setIsLoading } = useIsLoadingContext();

    useEffect(() => {
        const controller = new AbortController();
        const endpoint = '/api/customers/'
        if (data.length === 0) {
            setIsLoading(true);
            axios
                .get(endpoint, { signal: controller.signal })
                .then((res) => {
                    setData(res.data);
                    setIsLoading(false);
                })
                .catch((err) => {
                    if (axios.isCancel(err)) {
                        console.log('Request was canceled:', err.message);
                    } else {
                        setData([]);
                        setIsLoading(false);
                    }
                });
        }

        return () => {
            controller.abort(`Request to ${endpoint} was canceled because the URL changed.`);
        };
    }, [data, setIsLoading]);

    return (
        <AppPaper>
            <Grid justifyContent="space-between" container>
                <Grid item>
                    <StyledTitle>Liste des clients</StyledTitle>
                </Grid>
            </Grid>
            <ListPageWrapper
                fields={[
                    { name: 'code', label: 'Code' },
                    { name: 'label', label: 'Client' }
                ]}
                searchFields={[
                    {
                        name: 'code',
                        label: 'Code',
                        type: 'text'
                    }
                ]}
                loadedData={data}
                displayPaginationOptions={true}
            />
        </AppPaper>
    );
};

export default Customers;
