
import { BirdzModal, BirdzModalContent, BirdzModalTitle } from "@applications-terrains/birdz-react-library";
import { Grid } from "@mui/material";
import React, { useState } from "react";

type DetailField = {
    label: string;
    name: keyof Log;
};

type LogsDetailProps = {
    logItem: Log;
    onClose(): void;
};

type Log = {
    id: number;
    date: any;
    ip: string;
    content: string;
    message: string;
    type: number;
    type_label: string;
    user_agent: string;
    user__name: string;
};


export const LogsDetail = ({ logItem, onClose }: LogsDetailProps) => {
    const [show, setShow] = useState(true);

    const detailFields: DetailField[] = [
        { label: "Id", name: "id" },
        { label: "Date", name: "date" },
        { label: "Utilisateur", name: "user__name" },
        { label: "Type d'action", name: "type_label" },
        { label: "Message", name: "message" },
        { label: "Adresse IP", name: "ip" },
        { label: "Informations complémentaires", name: "user_agent" },
    ];

    return (
        <BirdzModal
            open={show}
            onClose={() => {
                setShow(false);
                onClose();
            }}
        >
            <BirdzModalTitle>Détail journal</BirdzModalTitle>
            <BirdzModalContent>
                {detailFields.map((field: DetailField) => {
                    return (
                        <Grid container key={field.name}>
                            <Grid item xs={4}>
                                {field.label}
                            </Grid>
                            <Grid item xs={8}>
                                {logItem[field.name]}
                            </Grid>
                        </Grid>
                    );
                })}
            </BirdzModalContent>
        </BirdzModal>
    );
};
