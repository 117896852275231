import { blue } from "@mui/material/colors";
import { createTheme, PaletteColor } from "@mui/material/styles";
import { frFR } from "@mui/x-date-pickers/locales";
const customBlue: PaletteColor = {
  light: "",
  main: "#253053",
  dark: "",
  contrastText: "rgb(255, 255, 255)",
};

export const mainTheme = createTheme(
  {
    palette: {
      primary: customBlue,
      secondary: blue,
    },
    components: {
      MuiCircularProgress: {
        styleOverrides: {
          root: {
            verticalAlign: "middle",
          },
        },
      },
    },
  },
  frFR
);
