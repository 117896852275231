import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import LinkForm from "../LinkForm";
import CreateLinkContractsSelection from "./CreateLinkContractsSelection";
import { AppPaper, StyledTitle } from "@applications-terrains/birdz-react-library";

export const CreateLink = () => {
  const { contractsParam } = useParams<any>();
  const [contracts, setContracts] = useState<string[]>([]);

  useEffect(() => {
    setContracts(contractsParam ? contractsParam.split(',') : []);
  }, [contractsParam]);

  return <>
    {contracts?.length ? <AppPaper>
      <StyledTitle>
        Créer un chantier de primo-raccordement (sur {contracts.length} contrat{contracts.length > 1 && 's'})
      </StyledTitle>
      <LinkForm contracts={contracts} linkType="primo"></LinkForm>
    </AppPaper> : <CreateLinkContractsSelection />}
  </>
};

export default CreateLink;