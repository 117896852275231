import { Fragment, useEffect, useState } from 'react';
import { UploadFile } from '../UploadFile';
import { CampaignType } from '../../types';
import { Box, Divider, Grid, IconButton, TextField } from '@mui/material';
import HelpIcon from '@mui/icons-material/Help';
import SortableSelect from '../../Select/SortableSelect';
import useDuplicationDevicesAndHeaders from '../hooks/useDuplicationDevicesAndHeaders';
import ExtendableChip from '../../Sent/Search/Campaigns/ExtendableChip';
import LightTooltip from '../../Tools/LightTooltip';

export type csvHeaderType = {
    name: string;
    label: string;
    default?: boolean;
    type?: CampaignType | CampaignType[];
};

const csvHeaders: csvHeaderType[] = [
    {
        name: 'module_address',
        label: "Équipement cible de l'intention",
        type: ['affiliation', 'configuration', 'clock_synchro', 'gutermann', 'raw', 'custom'],
        default: true
    },
    {
        name: 'frames_counter',
        label: 'Compteur de trames',
        type: ['raw', 'custom']
    },
    {
        name: 'concentrator',
        label: "Concentrateur à utiliser pour envoyer l'intention"
    },
    {
        type: 'broadcast',
        name: 'concentrator',
        label: "Concentrateur à utiliser pour envoyer l'intention",
        default: true
    },
    {
        name: 'repeater_1',
        label: "1er répéteur en charge de répéter l'intention"
    },
    {
        name: 'channel_1',
        label: "Canal d'écoute du 1er répéteur"
    },
    {
        name: 'repeater_2',
        label: "2ème répéteur en charge de répéter l'intention"
    },
    {
        name: 'channel_2',
        label: "Canal d'écoute du 2ème répéteur"
    },
    {
        name: 'repeater_3',
        label: "3ème répéteur en charge de répéter l'intention"
    },
    {
        name: 'channel_3',
        label: "Canal d'écoute du 3ème répéteur"
    },
    {
        name: 'combined_address',
        label: "Équipement cible de l'intention combinée"
    },
    {
        name: 'combined_concentrator',
        label: "Concentrateur à utiliser pour envoyer l'intention combinée"
    },
    {
        type: 'affiliation',
        name: 'affiliate',
        label: 'Équipement à affilier dans le module cible',
        default: true
    },
    {
        type: 'clock_synchro',
        name: 'clock_synchro',
        label: "Recalage d'horloge à appliquer",
        default: true
    },
    {
        type: 'gutermann',
        name: 'gut_cre_v',
        label: 'Paramètres Gutermann',
        default: true
    },
    {
        type: 'gutermann',
        name: 'gut_cre_t',
        label: 'Paramètres Gutermann',
        default: true
    },
    {
        type: 'gutermann',
        name: 'gut_tx_p',
        label: 'Paramètres Gutermann',
        default: true
    },
    {
        type: 'gutermann',
        name: 'gut_rx_p24_s',
        label: 'Paramètres Gutermann',
        default: true
    },
    {
        type: 'gutermann',
        name: 'gut_rx_p24_d',
        label: 'Paramètres Gutermann',
        default: true
    },
];

type CampaignDevicesStringProps = {
    onChange(devicesString: string): void;
    operatorId?: number;
    campaignType: CampaignType;
};

type Options = { value: string; label: string }[];

const CampaignDevicesString = ({
    onChange,
    operatorId,
    campaignType
}: CampaignDevicesStringProps) => {
    const [devicesString, setDevicesString] = useState<string>('');
    const [headerOptions, setHeaderOptions] = useState<Options>([]);
    const [selectedHeaders, setSelectedHeaders] = useState<Options>([]);
    const [availableHeaders, setAvailableHeaders] = useState<csvHeaderType[]>([]);

    const {
        setDevices,
        setHeaders,
        shouldImportDuplicationValues,
        setClickToCheckDevices,
        duplicate,
        clickToCheckDevices
    } = useDuplicationDevicesAndHeaders(availableHeaders);

    useEffect(() => {
        shouldImportDuplicationValues.values && setDevices(setDevicesString);
        shouldImportDuplicationValues.headers && setHeaders(setSelectedHeaders);
    }, [shouldImportDuplicationValues, setHeaders, setDevices]);

    useEffect(() => {
        if (
            duplicate &&
            clickToCheckDevices === null &&
            devicesString.length > 0 &&
            selectedHeaders.length > 0
        ) {
            setClickToCheckDevices('click');
        }
    }, [duplicate, devicesString, setClickToCheckDevices, selectedHeaders, clickToCheckDevices]);

    useEffect(() => {
        const availableHeaders = csvHeaders.filter((csvHeader) => {
            if (csvHeader?.type === undefined) {
                return true;
            } else {
                if (Array.isArray(csvHeader?.type)) {
                    return csvHeader.type.includes(campaignType);
                } else {
                    return csvHeader.type === campaignType;
                }
            }
        });
        setAvailableHeaders(availableHeaders);
        setHeaderOptions(
            availableHeaders.map((header) => {
                return { value: header.name, label: header.name };
            })
        );

        const defaultHeaders = availableHeaders
            .filter((csvHeader) => {
                return csvHeader.default;
            })
            .map((header) => {
                return { value: header.name, label: header.name };
            });
        setSelectedHeaders(defaultHeaders);
    }, [campaignType, operatorId]);

    useEffect(() => {
        const onChangeValue =
            selectedHeaders?.map((selectedHeader) => selectedHeader.value).join(';') +
            '\n' +
            devicesString;
        onChange(onChangeValue);
    }, [devicesString, onChange, selectedHeaders]);

    return (
        <Grid container spacing={1} sx={{ mt: 1, mb: 1 }}>
            <Grid item xs={12}>
                <UploadFile
                    onChange={(valueString: string) => {
                        const csvLines = valueString.split('\n');
                        const headerLine = csvLines.shift();
                        setSelectedHeaders(
                            headerLine?.split(';').map((headerField: string) => {
                                return { value: headerField, label: headerField };
                            }) || []
                        );
                        setDevicesString(csvLines.join('\n'));
                    }}
                />
            </Grid>
            <Grid item xs={3}>
                <Box sx={{ textAlign: 'center' }}>
                    <strong>ou</strong>
                </Box>
                <br />
                liste libre
            </Grid>
            <Grid item xs={9}>
                <i>Entête CSV (vous pouvez les réordonner par un glisser-déposer)</i>
                <LightTooltip
                    title={
                        <>
                            {availableHeaders?.map((availableHeader, index) => {
                                return (
                                    <Fragment key={availableHeader.name}>
                                        <Box
                                            className="d-flex align-items-center"
                                            sx={{ py: 2, fontSize: '0.9rem', fontWeight: '400' }}
                                            >
                                            <div style={{ width: '200px' }}>
                                                <ExtendableChip
                                                    label={availableHeader.name}
                                                    title="Modèle utilisé"
                                                    icon={undefined}
                                                    maxNbOfChar={25}
                                                />
                                            </div>
                                            <div>{availableHeader.label}</div>
                                        </Box>
                                        {index !== availableHeaders.length - 1 && <Divider />}
                                    </Fragment>
                                );
                            })}
                        </>
                    }>
                    <IconButton color="info">
                        <HelpIcon />
                    </IconButton>
                </LightTooltip>

                <SortableSelect
                    options={headerOptions}
                    values={selectedHeaders}
                    setValues={setSelectedHeaders}
                />

                <Box sx={{ mt: 1 }}>
                    <i>Équipements</i>
                    <TextField
                        type={'textarea'}
                        multiline
                        rows={6}
                        fullWidth
                        onChange={(e: any) => {
                            setDevicesString(e.target.value);
                        }}
                        sx={{ mt: 1 }}
                        value={devicesString}
                    />
                </Box>
            </Grid>
        </Grid>
    );
};

export default CampaignDevicesString;
