import React, { useCallback, useEffect, useState } from 'react';
import { registerLocale, setDefaultLocale } from 'react-datepicker';
import fr from 'date-fns/locale/fr';
import moment from 'moment';
import { campaignService } from '../../Campaigns/CampaignService';
import { FormControlLabel, Grid, Radio, RadioGroup } from '@mui/material';
import dataService from '../Services/dataService';
import { DateTimePicker } from '@mui/x-date-pickers';

registerLocale('fr', fr);
setDefaultLocale('fr');

export type Action = {
    type?: 'now' | 'scheduled' | 'now_with_time_windows';
    scheduledDate?: any;
};

type ScheduleOptionsProps = {
    onChange(action: any): any;
    value: Action;
    operatorId?: number;
    protocolId?: number;
    displayLabel?: boolean;
};

export const ScheduleOptions = ({
    onChange,
    value,
    operatorId,
    protocolId,
    displayLabel = true
}: ScheduleOptionsProps) => {
    const [action, setAction] = useState<Action>(value);
    const [isHrProtocol, setIsHrProtocol] = useState(false);

    const setScheduledDate = useCallback(
        (date: Date | null = null) => {
            let _action = { ...action };
            _action.type = 'scheduled';
            if (date) {
                _action.scheduledDate = moment(date).format();
            } else {
                _action.scheduledDate = moment(new Date()).add(6, 'hours').format();
            }
            setAction({ ..._action });
            onChange(_action);
        },
        [action, onChange]
    );

    useEffect(() => {
        let isHR = false;
        if (protocolId) {
            isHR = dataService.getProtocol(protocolId)?.name === 'Homerider';
            setIsHrProtocol(isHR);
        } else if (operatorId) {
            isHR = campaignService.operatorUseHRProtocol(operatorId);
            setIsHrProtocol(isHR);
        }

        if (typeof value.type === 'undefined') {
            if (isHR) {
                setScheduledDate();
            } else {
                setAction({ type: 'now' });
                onChange({ type: 'now', scheduledDate: null });
            }
        }
    }, [operatorId, protocolId, value, setScheduledDate, onChange, action]);

    const ScheduleForm = () => {
        return (
            <RadioGroup sx={{ mt: 1 }}>
                <Grid container>
                    <Grid item xs={4}>
                        <FormControlLabel
                            control={
                                <Radio
                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                        let _action = { ...action };
                                        _action.type = 'now';
                                        _action.scheduledDate = null;
                                        setAction({ ..._action });
                                        onChange(_action);
                                    }}
                                />
                            }
                            label={
                                isHrProtocol
                                    ? "Envoi immédiat sans respect des créneaux d'écoute"
                                    : 'Envoi immédiat'
                            }
                            sx={{
                                '& .MuiSvgIcon-root': { fontSize: 18 },
                                '& .MuiFormControlLabel-label': {
                                    fontSize: '15px',
                                    fontFamily: 'inherit'
                                }
                            }}
                            checked={action.type === 'now'}
                        />
                    </Grid>

                    {isHrProtocol ? (
                        <Grid item xs={4}>
                            <FormControlLabel
                                control={
                                    <Radio
                                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                            let _action = { ...action };
                                            _action.type = 'now_with_time_windows';
                                            _action.scheduledDate = null;
                                            setAction({ ..._action });
                                            onChange(_action);
                                        }}
                                    />
                                }
                                label="Envoi immédiat avec respect des créneaux d'écoute"
                                checked={action.type === 'now_with_time_windows'}
                                sx={{
                                    '& .MuiSvgIcon-root': { fontSize: 18 },
                                    '& .MuiFormControlLabel-label': {
                                        fontSize: '15px',
                                        fontFamily: 'inherit'
                                    }
                                }}
                            />
                        </Grid>
                    ) : null}

                    <Grid item xs={4}>
                        <FormControlLabel
                            control={
                                <Radio
                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                        setScheduledDate();
                                    }}
                                />
                            }
                            label="Envoi planifié"
                            sx={{
                                '& .MuiSvgIcon-root': { fontSize: 18 },
                                '& .MuiFormControlLabel-label': {
                                    fontSize: '15px',
                                    fontFamily: 'inherit'
                                }
                            }}
                            checked={action.type === 'scheduled'}
                        />

                        {action.type === 'scheduled' && (
                            <DateTimePicker
                                value={
                                    new Date(moment(value.scheduledDate).format('YYYY-MM-DDTHH:mm'))
                                }
                                onAccept={(newValue) => {
                                    setScheduledDate(newValue);
                                }}
                            />
                        )}
                    </Grid>
                </Grid>
            </RadioGroup>
        );
    };
    return (
        <>
            {displayLabel ? (
                <Grid container sx={{ mt: 1 }}>
                    <Grid item xs={4}>
                        Planification
                    </Grid>
                    <Grid item xs={8}>
                        <ScheduleForm />
                    </Grid>
                </Grid>
            ) : (
                <ScheduleForm />
            )}
        </>
    );
};
