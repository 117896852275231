import { Box, CircularProgress } from '@mui/material';
import ContractDetails from '../ContractDetails';
import { SearchForm } from '@applications-terrains/birdz-react-library';
import dataService from '../../Common/Services/dataService';
import { OptimisationTabPanelProps } from '../types';
import ListPageWrapper from '../../Tools/ListPageWrapper';

const OptimisationTabPanel = ({
    contract,
    instruction,
    filtersData,
    setFilterValues,
    filterValues,
    setFilters,
    filters,
    setTotals
}: OptimisationTabPanelProps) => {

    return (
        <Box key={`box-${contract}`}>
            <ContractDetails instruction={instruction} contract={contract} />
            <div>
                <h3>Modules éligibles</h3>

                {filtersData === null ? (
                    <div className="d-flex">
                        <CircularProgress color="inherit" size={'1rem'} />
                        <Box sx={{ ml: 1 }} color="inherit">
                            récupération des données
                        </Box>
                    </div>
                ) : (
                    <SearchForm
                        fields={[
                            {
                                name: 'city',
                                label: 'Commune',
                                type: 'select-multiple',
                                options: {
                                    values: filtersData?.city
                                        .map((cityName) => ({
                                            value: cityName,
                                            label: cityName
                                        }))
                                        .sort((a, b) => a.value.localeCompare(b.value))
                                },
                                labelSize: 6,
                                fieldSize: 6
                            },
                            {
                                name: 'device_type',
                                label: 'Device type module',
                                type: 'select-multiple',
                                options: {
                                    values: dataService
                                        .getData('deviceTypes')
                                        .filter((deviceType: any) => {
                                            return (
                                                deviceType.type === 'WA' &&
                                                (filtersData.device_type.length > 0
                                                    ? filtersData.device_type.includes(
                                                          deviceType.id
                                                      )
                                                    : true)
                                            );
                                        })
                                        .map((deviceType: any) => {
                                            return {
                                                value: deviceType.id,
                                                label: deviceType.name
                                            };
                                        })
                                        .sort(
                                            (
                                                a: {
                                                    label: string;
                                                },
                                                b: {
                                                    label: string;
                                                }
                                            ) => a.label.localeCompare(b.label)
                                        )
                                },
                                labelSize: 6,
                                fieldSize: 6
                            },
                            {
                                name: 'mr_type',
                                type: 'select-multiple',
                                label: 'Device type répéteur potentiel',
                                options: {
                                    values: dataService
                                        .getData('deviceTypes')
                                        .filter((deviceType: any) => {
                                            return (
                                                deviceType.type === 'MR' &&
                                                (filtersData.mr_type.length > 0
                                                    ? filtersData.mr_type.includes(deviceType.id)
                                                    : true)
                                            );
                                        })
                                        .map((deviceType: any) => {
                                            return {
                                                value: deviceType.id,
                                                label: deviceType.name
                                            };
                                        })
                                        .sort(
                                            (
                                                a: {
                                                    label: string;
                                                },
                                                b: {
                                                    label: string;
                                                }
                                            ) => a.label.localeCompare(b.label)
                                        )
                                },
                                labelSize: 6,
                                fieldSize: 6
                            },
                            {
                                name: 'father_type',
                                type: 'select-multiple',
                                label: 'Device type répéteur actuel',
                                options: {
                                    values: dataService
                                        .getData('deviceTypes')
                                        .filter((deviceType: any) => {
                                            return (
                                                deviceType.type === 'MR' &&
                                                (filtersData.father_type.length > 0
                                                    ? filtersData.father_type.includes(
                                                          deviceType.id
                                                      )
                                                    : true)
                                            );
                                        })
                                        .map((deviceType: any) => {
                                            return {
                                                value: deviceType.id,
                                                label: deviceType.name
                                            };
                                        })
                                        .sort(
                                            (
                                                a: {
                                                    label: string;
                                                },
                                                b: {
                                                    label: string;
                                                }
                                            ) => a.label.localeCompare(b.label)
                                        )
                                },
                                labelSize: 6,
                                fieldSize: 6
                            },
                            {
                                name: 'current_performance_min',
                                label: 'Performance actuelle entre',
                                type: 'select',
                                options: {
                                    values: [
                                        {
                                            label: '0%',
                                            value: 0
                                        },
                                        {
                                            label: '25%',
                                            value: 0.25
                                        },
                                        {
                                            label: '50%',
                                            value: 0.5
                                        },
                                        {
                                            label: '75%',
                                            value: 0.75
                                        }
                                    ]
                                },
                                labelSize: 6,
                                fieldSize: 6
                            },
                            {
                                name: 'current_performance_max',
                                label: 'et',
                                type: 'select',
                                options: {
                                    values: [
                                        {
                                            label: '25%',
                                            value: 0.25
                                        },
                                        {
                                            label: '50%',
                                            value: 0.5
                                        },
                                        {
                                            label: '75%',
                                            value: 0.75
                                        },
                                        {
                                            label: '100%',
                                            value: 1
                                        }
                                    ]
                                },
                                labelSize: 6,
                                fieldSize: 6
                            },
                            {
                                name: 'potential_performance_min',
                                label: 'Performance potentielle entre',
                                type: 'select',
                                options: {
                                    values: [
                                        {
                                            label: '0%',
                                            value: 0
                                        },
                                        {
                                            label: '25%',
                                            value: 0.25
                                        },
                                        {
                                            label: '50%',
                                            value: 0.5
                                        },
                                        {
                                            label: '75%',
                                            value: 0.75
                                        },
                                        {
                                            label: '100%',
                                            value: 1
                                        }
                                    ]
                                },
                                labelSize: 6,
                                fieldSize: 6
                            },
                            {
                                name: 'potential_performance_max',
                                label: 'et',
                                type: 'select',
                                options: {
                                    values: [
                                        {
                                            label: '25%',
                                            value: 0.25
                                        },
                                        {
                                            label: '50%',
                                            value: 0.5
                                        },
                                        {
                                            label: '75%',
                                            value: 0.75
                                        },
                                        {
                                            label: '100%',
                                            value: 1
                                        },
                                        {
                                            label: '+ de 100%',
                                            value: null
                                        }
                                    ]
                                },
                                labelSize: 6,
                                fieldSize: 6
                            }
                        ]}
                        onSubmit={(filterString: any, rawFiltersValues: any) => {
                            setFilterValues((filterValues) => {
                                return {
                                    ...filterValues,
                                    [contract]: rawFiltersValues
                                };
                            });
                            let filters = '';
                            Object.keys(rawFiltersValues.filterValues).forEach(
                                (fieldKey: string) => {
                                    const fieldValue = rawFiltersValues.filterValues[fieldKey];
                                    if (fieldValue === null || fieldValue === undefined) return;
                                    else {
                                        filters += `&${fieldKey}${fieldKey.includes('performance') ? '' : '__in'}=${fieldValue}`;
                                    }
                                }
                            );
                            setFilters((filt) => ({
                                ...filt,
                                [contract]: filters
                            }));
                        }}
                        values={filterValues[contract]}
                    />
                )}
                <div id={contract}>
                    <ListPageWrapper
                        name=""
                        endpoint={`/api/optimisation/devices/`}
                        filters={`${filters[contract]}&contract_code=${contract}`}
                        onLoadItems={(items: []) => {
                            setTotals((totals) => ({
                                ...totals,
                                [contract]: {
                                    value: items.length > 0 ? totals[contract].value : 0,
                                    hasChanged: true
                                }
                            }));
                        }}
                        fields={[
                            {
                                name: 'device_id',
                                label: 'Device',
                                orderable: true,
                                transform: (device_id: string, row: any) => {
                                    return `${device_id} (${dataService.getDeviceType(row.device_type)})`;
                                }
                            },
                            {
                                name: 'mr_id',
                                label: 'Répéteur potentiel',
                                orderable: true,
                                transform: (mr_id: string, row: any) => {
                                    return `${mr_id} (${dataService.getDeviceType(row.mr_type)})`;
                                }
                            },
                            {
                                name: 'father_id',
                                label: 'Répéteur actuel',
                                orderable: true,
                                transform: (father_id: string, row: any) => {
                                    return father_id
                                        ? `${father_id} (${dataService.getDeviceType(row.father_type)})`
                                        : '-';
                                }
                            },
                            {
                                name: 'current_performance',
                                label: 'Perf actuelle',
                                orderable: true,
                                transform: (perf: number) => {
                                    return (perf * 100).toFixed(2) + '%';
                                }
                            },
                            {
                                name: 'potential_performance',
                                label: 'Perf potentielle',
                                transform: (perf: number) => {
                                    return (perf * 100).toFixed(2) + '%';
                                },
                                orderable: true
                            }
                        ]}
                        // onSelectItems={(
                        //     selectedModulesItems: any[]
                        // ) => {
                        //     const newSelectedModules: OptimisationEligibleModule[] =
                        //         selectedModulesItems.map(
                        //             (selectedModule) => {
                        //                 return {
                        //                     city: selectedModule.city,
                        //                     father_id:
                        //                         selectedModule.father_id,
                        //                     device_id:
                        //                         selectedModule.device_id,
                        //                     mr_id: selectedModule.mr_id,
                        //                     current_performance:
                        //                         selectedModule.current_performance,
                        //                     potential_performance:
                        //                         selectedModule.potential_performance
                        //                 };
                        //             }
                        //         );

                        //     if (
                        //         !_.isEqual(
                        //             selectedModules[
                        //                 contract
                        //             ],
                        //             newSelectedModules
                        //         )
                        //     ) {
                        //         setSelectedModules({
                        //             ...selectedModules,
                        //             ...{
                        //                 [contract]:
                        //                     newSelectedModules
                        //             }
                        //         } as SelectedModules);
                        //     }
                        // }}
                    />
                </div>
            </div>
        </Box>
    );
};

export default OptimisationTabPanel;
