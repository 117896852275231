import React, { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import "./TimeWindows.scss";
import { Box, Button, Checkbox, FormControlLabel, IconButton } from "@mui/material";
import { Add, Delete } from "@mui/icons-material";
import { BirdzNotif, useNotif } from "@applications-terrains/birdz-react-library";

type TimeWindowsProps = {
  value: any;
  onChange(value: any, isValid: boolean): void;
};

type TimeSlot = {
  startHour: string;
  endHour: string;
  days: number[];
};

const emptySlot: TimeSlot = {
  startHour: "",
  endHour: "",
  days: [],
};

export const TimeWindows = ({ value, onChange }: TimeWindowsProps) => {
  const { notif, notifOptions } = useNotif();
  const loadSlot = () => {
    let newTimeSlot: TimeSlot[] = [];
    if (value && value.length) {
      value.forEach((slot: any) => {
        newTimeSlot.push({
          startHour: slot.start_time,
          endHour: slot.end_time,
          days: slot.week_days,
        });
      });
    }
    return newTimeSlot;
  };

  const [timeSlot, setTimeSlot] = useState<TimeSlot[]>(loadSlot());

  const checkIfEndHourIsAfterStartHour = (slotIndex: number, endHour: Date) => {
    let slot: TimeSlot = timeSlot[slotIndex];
    if (!endHour) {
      return (
        slot &&
        slot.startHour &&
        transformHour(slot.endHour) > transformHour(slot.startHour)
      );
    } else {
      return slot && slot.startHour && endHour > transformHour(slot.startHour);
    }
  };

  const setStartHour = (slotIndex: number, startHour: Date) => {
    let newTimeSlot = [...timeSlot];
    newTimeSlot[
      slotIndex
    ].startHour = `${startHour?.getHours()}:${startHour?.getMinutes()}:${startHour?.getSeconds()}`;
    setTimeSlot([...newTimeSlot]);
  };

  const setEndHour = (slotIndex: number, endHour: Date) => {
    let newTimeSlot = timeSlot;
    newTimeSlot[
      slotIndex
    ].endHour = `${endHour?.getHours()}:${endHour?.getMinutes()}:${endHour?.getSeconds()}`;
    setTimeSlot([...newTimeSlot]);
  };

  const setDay = (slotNumber: number, dayNumber: number) => {
    let newTimeSlot = timeSlot;
    let slot = timeSlot[slotNumber];
    if (slot) {
      if (slot.days.includes(dayNumber)) {
        newTimeSlot[slotNumber].days = slot.days.filter(
          (day) => day !== dayNumber
        );
      } else {
        newTimeSlot[slotNumber].days = [
          ...newTimeSlot[slotNumber].days,
          dayNumber,
        ];
      }
      setTimeSlot([...newTimeSlot]);
    }
  };

  const isValid = (slotIndex: number) => {
    let slot = timeSlot[slotIndex];
    if (
      !slot.endHour ||
      !checkIfEndHourIsAfterStartHour(slotIndex, transformHour(slot.endHour))
    ) {
      return false;
    }
    if (slot.days.length === 0) {
      return false;
    }
    return true;
  };

  useEffect(() => {
    let formIsValid = true;
    timeSlot.forEach((slot, slotIndex) => {
      if (!isValid(slotIndex)) {
        formIsValid = false;
      }
    });
    onChange(
      timeSlot.map((slot) => {
        return {
          start_time: slot.startHour,
          end_time: slot.endHour,
          week_days: slot.days,
        };
      }),
      formIsValid
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timeSlot]);

  const transformHour = (hoursString: string) => {
    const [hours, minutes, seconds] = hoursString.split(":");
    let returnDate = new Date();
    if (hours && minutes && seconds) {
      returnDate.setHours(
        parseInt(hours),
        parseInt(minutes),
        parseInt(seconds)
      );
    } else {
      returnDate.setHours(0, 0, 0);
    }
    return returnDate;
  };

  const addSlot = () => {
    let newTimeSlot = [...timeSlot, { ...emptySlot }];
    setTimeSlot(newTimeSlot);
  };

  const deleteSlot = (slotIndex: number) => {
    let newTimeSlot = [...timeSlot];
    newTimeSlot.splice(slotIndex, 1);
    setTimeSlot(newTimeSlot);
  };
  return (
    <Box className="timewindows">
      {timeSlot.map((slot: any, slotIndex: number) => {
        return (
          <div
            className={!isValid(slotIndex) ? "block is-invalid" : "block"}
            key={slotIndex}
          >
            <div className="blockTitle">
              Créneau #{slotIndex + 1}
              <IconButton onClick={() => deleteSlot(slotIndex)}>
                <Delete fontSize="small" />
              </IconButton>
            </div>
            <div className="content">
              <div className="hourSlot">
                <span className="label">Plage horaire:</span>
                <span className="helper-label">de</span>
                <div className="hourPicker">
                  <DatePicker
                    selected={transformHour(slot.startHour)}
                    onChange={(date: any) => setStartHour(slotIndex, date)}
                    showTimeSelect
                    showTimeSelectOnly
                    timeIntervals={15}
                    timeCaption="Début"
                    dateFormat="HH:mm"
                  />
                </div>
                <span className="helper-label">à</span>
                <div className="hourPicker">
                  <DatePicker
                    selected={transformHour(slot.endHour)}
                    onChange={(date: any) => {
                      if (checkIfEndHourIsAfterStartHour(slotIndex, date)) {
                        setEndHour(slotIndex, date);
                      } else {
                        notif({
                          type: "warning",
                          content:
                            "Vous ne pouvez pas choisir une heure de fin antérieur à l'heure de début",
                        });
                      }
                    }}
                    showTimeSelect
                    showTimeSelectOnly
                    timeIntervals={15}
                    timeCaption="Fin"
                    dateFormat="HH:mm"
                    disabled={!slot.startHour}
                  />
                </div>
              </div>
              <div className="daySlot">
                <span className="label">Plage journalière:</span>
                <FormControlLabel control={
                  <Checkbox
                    checked={slot?.days && slot?.days.includes(0)}
                    onChange={() => setDay(slotIndex, 0)}
                    inputProps={{ 'aria-label': 'controlled' }}
                  />}
                  label="Lundi"
                  sx={{ '& .MuiSvgIcon-root': { fontSize: 18 }, '& .MuiFormControlLabel-label': { fontSize: '15px' } }}
                />

                <FormControlLabel control={
                  <Checkbox
                    checked={slot?.days && slot?.days.includes(1)}
                    onChange={() => setDay(slotIndex, 1)}
                    inputProps={{ 'aria-label': 'controlled' }}
                  />}
                  label="Mardi"
                  sx={{ '& .MuiSvgIcon-root': { fontSize: 18 }, '& .MuiFormControlLabel-label': { fontSize: '15px' } }}
                />

                <FormControlLabel control={
                  <Checkbox
                    checked={slot?.days && slot?.days.includes(2)}
                    onChange={() => setDay(slotIndex, 2)}
                    inputProps={{ 'aria-label': 'controlled' }}
                  />}
                  label="Mercredi"
                  sx={{ '& .MuiSvgIcon-root': { fontSize: 18 }, '& .MuiFormControlLabel-label': { fontSize: '15px' } }}
                />

                <FormControlLabel control={
                  <Checkbox
                    checked={slot?.days && slot?.days.includes(3)}
                    onChange={() => setDay(slotIndex, 3)}
                    inputProps={{ 'aria-label': 'controlled' }}
                  />}
                  label="Jeudi"
                  sx={{ '& .MuiSvgIcon-root': { fontSize: 18 }, '& .MuiFormControlLabel-label': { fontSize: '15px' } }}
                />

                <FormControlLabel control={
                  <Checkbox
                    checked={slot?.days && slot?.days.includes(4)}
                    onChange={() => setDay(slotIndex, 4)}
                    inputProps={{ 'aria-label': 'controlled' }}
                  />}
                  label="Vendredi"
                  sx={{ '& .MuiSvgIcon-root': { fontSize: 18 }, '& .MuiFormControlLabel-label': { fontSize: '15px' } }}
                />

                <FormControlLabel control={
                  <Checkbox
                    checked={slot?.days && slot?.days.includes(5)}
                    onChange={() => setDay(slotIndex, 5)}
                    inputProps={{ 'aria-label': 'controlled' }}
                  />}
                  label="Samedi"
                  sx={{ '& .MuiSvgIcon-root': { fontSize: 18 }, '& .MuiFormControlLabel-label': { fontSize: '15px' } }}
                />

                <FormControlLabel control={
                  <Checkbox
                    checked={slot?.days && slot?.days.includes(6)}
                    onChange={() => setDay(slotIndex, 6)}
                    inputProps={{ 'aria-label': 'controlled' }}
                  />}
                  label="Dimanche"
                  sx={{ '& .MuiSvgIcon-root': { fontSize: 18 }, '& .MuiFormControlLabel-label': { fontSize: '15px' } }}
                />
              </div>
            </div>
          </div>
        );
      })}
      <Button variant="contained" className="addSlot" onClick={() => addSlot()}>
        <Add /> Ajouter un créneau
      </Button>
      <BirdzNotif options={notifOptions} />
    </Box>
  );
};
