
import React, { useCallback, useEffect, useState } from "react";
import { XMetadata } from "../Common/Services/encoderService";
import { ParametersBitmask } from "./ParametersBitmask";
import { ParametersCalendar } from "./ParametersCalendar";
import { ParametersCheckbox } from "./ParametersCheckbox";
import { ParametersDropdownList } from "./ParametersDropdownList";
import { ParametersRadio } from "./ParametersRadio";
import { ParametersRandom } from "./ParametersRandom";
import { ParametersSlider } from "./ParametersSlider";
import { ParametersText } from "./ParametersText";
import { Grid } from "@mui/material";


type fieldKeys = {
    id: string,
    type: string
}
type fieldMetadataKeys = {
    [key in keyof XMetadata]: any
}
type FieldProps = fieldKeys & fieldMetadataKeys;

type ParametersFieldProps = {
    field: FieldProps;
    disabled: boolean;
    mandatoryCheckbox: any;
    onFieldChange(fieldName: string, fieldValue: any): void;
    defaultValue: any;
    valid: boolean;
    setValidity(validity: boolean): void;
}

const ParametersField = ({ field, disabled, mandatoryCheckbox, onFieldChange, defaultValue, valid, setValidity }: ParametersFieldProps) => {
    const [displayField, setDisplayField] = useState<boolean>(true);

    // use to reinit fields when we uncheck mandatory checkbox
    useEffect(() => {
        if (defaultValue === null) {
            setDisplayField(false);
            setTimeout(() => {
                setDisplayField(true);
            })
        }
    }, [defaultValue]);

    const renderField = useCallback(() => {
        let fieldValue = defaultValue;

        switch (field.web_type) {
            case "radio":
                fieldValue = typeof fieldValue === 'undefined' ? null : fieldValue;
                let id = Math.random().toString(); // todo replace this by a unique id
                return (
                    <ParametersRadio
                        id={id}
                        field={field}
                        value={fieldValue}
                        disabled={disabled}
                        onFieldChange={onFieldChange}
                    />
                );
            case "checkbox":
                fieldValue = fieldValue || null;
                return (
                    <ParametersCheckbox
                        field={field}
                        value={fieldValue}
                        disabled={disabled}
                        onFieldChange={onFieldChange}
                    />
                );
            case "text":
                // cast value only if field type is text or undefined
                fieldValue = typeof (fieldValue) === 'undefined' ? "" : fieldValue;
                if (field.type === "string") {
                    fieldValue = fieldValue || "";
                } else if (field.type === "number") {
                    fieldValue = typeof fieldValue === "number" ? fieldValue : "";
                } else {
                    fieldValue = fieldValue || "";
                }

                return (
                    <ParametersText
                        field={field}
                        value={fieldValue}
                        setValidity={(isValid) => {
                            setValidity(isValid);
                        }}
                        disabled={disabled}
                        onFieldChange={onFieldChange}
                    />
                );
            case "slider":
                fieldValue = fieldValue || 0;
                return (
                    <ParametersSlider
                        field={field}
                        value={fieldValue}
                        disabled={disabled}
                        onFieldChange={onFieldChange}
                    />
                );
            case "ddl":
                return (
                    <ParametersDropdownList
                        field={field}
                        value={fieldValue}
                        disabled={disabled}
                        onFieldChange={onFieldChange}
                    />
                );
            case "random":
                return (
                    <ParametersRandom
                        field={field}
                        onFieldChange={onFieldChange}
                        value={fieldValue}
                    />
                );
            case "bitmask":
                fieldValue = fieldValue || "";
                return (
                    <ParametersBitmask
                        field={field}
                        onFieldChange={onFieldChange}
                        disabled={disabled}
                        value={fieldValue}
                    />
                );

            case "calendar":
                return (
                    <ParametersCalendar
                        field={field}
                        value={fieldValue}
                        disabled={disabled}
                        onFieldChange={onFieldChange}
                        utcOffset={field.id.toUpperCase() === 'HDA' ? 2 : 0}
                    />
                );
        }

        return <></>
    }, [defaultValue, onFieldChange, field, disabled, setValidity])

    return (
        <Grid container className={`${disabled ? 'disabled' : ''}`} spacing={1} alignItems={"center"}>
            <Grid item xs={6}>
                <div className="field-label">{field?.mandatory === false && mandatoryCheckbox} {field.label} ({field.id})</div>
            </Grid>
            <Grid item xs={6}>
                <div className={`field-input ${valid ? '' : 'has-error'}`}>
                    {displayField && <>{renderField()}</>}
                </div>
            </Grid>
        </Grid>)
}

export default ParametersField;
