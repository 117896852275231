import React, { useState } from "react";
import { formatData } from "./ParametersUtils";

type ParametersSliderProps = {
  value: any;
  field: any;
  onFieldChange: any;
  disabled: boolean;
};
export const ParametersSlider = ({
  field,
  value,
  onFieldChange,
  disabled
}: ParametersSliderProps) => {
  const [fieldValue, setFieldValue] = useState<any>(value);

  return (
    <>
      <input
        type="range"
        min={field.min}
        max={field.max}
        step={field.step}
        defaultValue={value}
        key={field.id}
        onChange={(e) => {
          setFieldValue(e.target.value);
          onFieldChange(field.id, formatData(field.type, e.target.value));
        }}
        style={{ verticalAlign: "middle" }}
        disabled={disabled}
      />
      <span>({fieldValue})</span>
    </>
  );
};
