import { SearchFormValue } from '@applications-terrains/birdz-react-library';
import { Dispatch, SetStateAction, useEffect } from 'react';
import { getFilterString } from '../utils';
import moment from 'moment';

const useConvertedDatesForFilters = ({
    filters,
    setFiltersString
}: {
    filters: SearchFormValue;
    setFiltersString: Dispatch<SetStateAction<string>>;
}) => {
    useEffect(() => {
        const created_at_after = filters.filterValues.created_at_after;
        const created_at_before = filters.filterValues.created_at_before;
        const campaign__created_at_after = filters.filterValues.campaign__created_at_after;
        const campaign__created_at_before = filters.filterValues.campaign__created_at_before;
        const newFiltersValues = { ...filters.filterValues };
        if (created_at_after)
            newFiltersValues['created_at_after'] = moment(
                filters.filterValues.created_at_after
            ).format('YYYY-MM-DD');
        if (created_at_before)
            newFiltersValues['created_at_before'] = moment(
                filters.filterValues.created_at_before
            ).format('YYYY-MM-DD');
        if (campaign__created_at_after)
            newFiltersValues['campaign__created_at_after'] = moment(
                filters.filterValues.campaign__created_at_after
            ).format('YYYY-MM-DD');
        if (campaign__created_at_before)
            newFiltersValues['campaign__created_at_before'] = moment(
                filters.filterValues.campaign__created_at_before
            ).format('YYYY-MM-DD');
        setFiltersString(getFilterString(newFiltersValues));
    }, [filters, setFiltersString]);
};

export default useConvertedDatesForFilters;
