import React from "react";
import moment from "moment";
import ListPageWrapper from "../../Tools/ListPageWrapper";

export const ModuleExclusionList = () => {
    return (
        <div className="block">
            <ListPageWrapper
                name="Exclusion de modules"
                endpoint="/api/racsup/module-exclusions/"
                fields={[
                    {
                        name: "created_at", label: "Date de création", orderable: true, transform: (value: any) => {
                            let date =
                                value && moment.unix(value).format("DD/MM/YYYY à HH:mm:ss");
                            return date || "";
                        }
                    },
                    { name: "module_address", label: "Module", orderable: true },
                    { name: "repeater", label: "Répéteur (lu à partir de la commande)", orderable: true }
                ]}
            />
        </div>
    );
};

export default ModuleExclusionList;
