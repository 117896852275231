import React, { useEffect, useState } from "react";
import "react-datepicker/dist/react-datepicker.css";
import { Link } from "react-router-dom";
import { Cell, Pie, PieChart } from "recharts";
import axios from "axios";
import { Grid } from "@mui/material";

const CustomLabel = ({
    cx,
    cy,
    midAngle,
    innerRadius,
    outerRadius,
    percent,
    displayMode,
    value,
}: any) => {
    const RADIAN = Math.PI / 180;
    const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);

    return (
        <text
            x={x}
            y={y}
            textAnchor={x > cx ? "start" : "end"}
            dominantBaseline="central"
        >
            {displayMode === "values" ? `${value}` : `${(percent * 100).toFixed(0)}%`}
        </text>
    );
};

const COLORS = ['#61da7c', '#ec7d8d', '#b1d4ec', '#e7e7e7'];

type ChartProps = {
    title?: string;
    endpoint: string;
    detailsUrl?: string;
}

export const Chart = ({ title, endpoint, detailsUrl }: ChartProps) => {
    const [data, setData] = useState<any>({});

    useEffect(() => {
        axios.get(endpoint).then((response: any) => {
            let formatedData: any = {};
            for (let i in response.data) {
                formatedData[i] = [
                    { name: 'Succès', value: response.data[i]['success_count'] },
                    { name: 'Echec', value: response.data[i]['failure_count'] },
                    { name: 'En cours', value: response.data[i]['pending_count'] }
                ];
            }
            setData(formatedData);
        })
    }, [endpoint]);

    return (
        <>
            {Object.keys(data).length > 0 && (
                <Grid container>
                    <Grid item xs={3}>
                        {title && (<h3>{title}</h3>)}
                        <div className="chart-legend">
                            <Grid container>
                                <Grid item xs={12} className="legend-container"><div className="legend-color" style={{ backgroundColor: COLORS[0] }}></div> Succès</Grid>
                                <Grid item xs={12} className="legend-container"><div className="legend-color" style={{ backgroundColor: COLORS[1] }}></div> Echec</Grid>
                                <Grid item xs={12} className="legend-container"><div className="legend-color" style={{ backgroundColor: COLORS[2] }}></div> En cours</Grid>
                            </Grid>
                        </div>
                        {detailsUrl && (
                            <Grid container>
                                <Grid item xs={12} className="goToDetails"><Link to={detailsUrl}>Accèder au détail</Link></Grid>
                            </Grid>
                        )}
                    </Grid>
                    {Object.keys(data).map((dataKey: any) => (<Grid xs={3} key={dataKey}>
                        <PieChart width={200} height={200} style={{ margin: 'auto' }}>
                            <Pie
                                data={data[dataKey]}
                                labelLine={false}
                                label={<CustomLabel displayMode='values' legends={{}} />}
                                dataKey="value"
                            >
                                {data[dataKey].map((entry: any, index: number) => {
                                    return (
                                        <Cell
                                            key={`cell-${index}`}
                                            fill={COLORS[index % COLORS.length]}
                                        />
                                    );
                                })}
                            </Pie>
                        </PieChart>
                        <div className="text-center">Total envois: {data[dataKey].map((data: any) => data.value).reduce((a: any, b: any) => a + b)}</div>
                    </Grid>))}
                </Grid>)}
        </>
    );
};

export default Chart;
