import React, { useEffect, useState } from "react";
import "./SentDashboard.scss";
import axios from "axios";
import { colorCharts } from "./ColorCharts";
import SentPie from "./SentPie";

type SendingStatsProps = {
  filters?: string;
  title?: string;
  data?: any; // on peut passer data si on a déjà les données, plutôt que de refaire un appel api
};
export const SendingStats = ({ filters, title, data }: SendingStatsProps) => {
  const [pieLegends, setPieLegends] = useState({});
  const [pieData, setPieData] = useState<any[]>([]);

  useEffect(() => {
    if (data) {
      renderData(data);
    } else {
      axios.get("/api/purposes-stats/?" + filters).then((results: any) => {
        renderData(results.data.stats);
      });
    }
  }, [filters, data]);

  const renderData = (stats: any) => {
    let tmpPieData: any = [];
    let tempPieLegends: any = {};

    for (let status in stats) {
      tmpPieData.push({ name: status, value: stats[status] });
      if (!tempPieLegends[status]) {
        let color = colorCharts.getColor(status);
        tempPieLegends[status] = {
          label: status,
          color: color.color,
          bgColor: color.bgColor,
        };
      }
    }
    setPieData(tmpPieData);
    setPieLegends(tempPieLegends);
  };
  return pieData && pieData?.length > 0 ? <SentPie data={pieData} title={title} legends={pieLegends} /> : null;
};

export default SendingStats;
