import React, { useEffect, useState } from 'react';
import moment from 'moment';
import {
    AppPaper,
    BirdzModal,
    BirdzModalContent,
    BirdzModalTitle,
    BirdzTitle,
} from '@applications-terrains/birdz-react-library';
import './Statuses.scss';
import axios from 'axios';
import { Button, Grid, IconButton, Paper } from '@mui/material';
import { Refresh, Search } from '@mui/icons-material';
import SearchFormWithDatePickerRange from '../Tools/SearchFormWithDatePickerRange';
import ListPageWrapper from '../Tools/ListPageWrapper';

const links: any = {
    legacy_connection: 'Base commune',
    legacy_import: 'Import',
    shadow_connection: 'Shadow update',
    scheduler: 'Scheduler',
    transcoder: 'Transcoder',
    operator: 'Opérateur'
};

const statuses: any = {
    ok: 'Ok',
    error: 'Erreur'
};

export const Statuses = () => {
    const [linkStatuses, setLinkStatuses] = useState<any[]>([]);
    const [operatorStatuses, setOperatorStatuses] = useState<any[]>([]);
    const [loading, setLoading] = useState(false);
    const [showLogDetail, setShowLogDetail] = useState<any>();
    const [filters, setFilters] = useState<string>('');

    useEffect(() => {
        setLoading(true);
        loadData();
    }, []);

    const checkStatuses = () => {
        setLoading(true);
        axios.post('/api/check-statuses/').then(() => {
            setTimeout(() => {
                loadData();
            }, 2000);
        });
    };

    const loadData = () => {
        axios.get('/api/link-statuses/').then((response: any) => {
            let data = response.data;
            let links = data.results.filter((result: any) => result.type !== 'operator');
            let operators = data.results.filter((result: any) => result.type === 'operator');

            setLinkStatuses(links);
            setOperatorStatuses(operators);
            setLoading(false);
        });
    };

    const searchFields = [
        // {
        //     name: 'date_after',
        //     label: 'Entre le ',
        //     type: 'datepicker'
        // },
        // {
        //     name: 'date_before',
        //     label: 'et le',
        //     type: 'datepicker'
        // },
        {
            name: 'state',
            label: 'Etat',
            type: 'select',
            options: {
                values: [
                    { value: '', label: '' },
                    { value: 'ok', label: statuses['ok'] },
                    { value: 'error', label: statuses['error'] }
                ]
            },
            className: 'col-sm-12'
        },
        {
            name: 'type',
            label: 'Type',
            type: 'select',
            options: {
                values: [
                    { value: '', label: '' },
                    { value: 'FCM', label: 'FCM' },
                    { value: 'operator', label: 'Opérateur' },
                    { value: 'scheduler', label: 'Scheduler' },
                    { value: 'transcoder', label: 'Transcoder' }
                ]
            },
            className: 'col-sm-12'
        },
        {
            name: 'operator',
            label: 'Opérateur',
            options: {
                source: '/api/operators/',
                searchIsMulti: false
            }
        }
    ];

    let actions = [
        {
            name: 'detail',
            label: 'Détail',
            render: (item: any) => {
                return (
                    <IconButton
                        onClick={() => {
                            setShowLogDetail(item);
                        }}>
                        <Search fontSize="small" />
                    </IconButton>
                );
            }
        }
    ];

    let listFields = [
        {
            name: 'date',
            label: 'Date',
            transform: (value: any) => {
                return (value && moment(value).format('DD/MM/YYYY à HH:mm:ss')) || '';
            },
            orderable: true
        },
        {
            name: 'type',
            label: 'Type',
            orderable: true,
            transform: (value: any, object: any) => {
                let val = links[value] || value;
                if (value === 'operator') {
                    val += ' (' + object.operator__name + ')';
                }
                return val;
            }
        },
        {
            name: 'state',
            label: 'Etat',
            orderable: true,
            transform: (value: any) => {
                return <span className={'status ' + value}>{statuses[value] || value}</span>;
            },
            className: 'text-center',
            options: { width: '100px' }
        }
    ];

    return (
        <>
            <AppPaper>
                <Button
                    disabled={loading}
                    onClick={() => {
                        checkStatuses();
                    }}
                    className="checkStatus">
                    <Refresh fontSize="small" /> Rafraîchir
                </Button>
                <BirdzTitle>État des liens</BirdzTitle>
                <h4 className="services">Services</h4>

                {loading === false ? (
                    <div className="statuses">
                        {linkStatuses?.length > 1 && (
                            <Grid container spacing={1}>
                                {linkStatuses &&
                                    linkStatuses.map((linkStatus: any) => {
                                        return (
                                            <Grid
                                                component={Paper}
                                                item
                                                key={linkStatus.checked_date}
                                                sx={{ m: 1, p: 1 }}>
                                                {links[linkStatus.type]
                                                    ? links[linkStatus.type]
                                                    : linkStatus.type}
                                                <div className={'status ' + linkStatus.state}>
                                                    {statuses[linkStatus.state] || linkStatus.state}
                                                </div>
                                            </Grid>
                                        );
                                    })}
                            </Grid>
                        )}
                    </div>
                ) : (
                    <div className="alert">Chargement en cours...</div>
                )}
                <h4 className="operators">Opérateurs</h4>
                {loading === false ? (
                    <div className="statuses">
                        {operatorStatuses?.length > 1 && (
                            <Grid container spacing={1}>
                                {operatorStatuses &&
                                    operatorStatuses.map((operatorStatus: any) => {
                                        return (
                                            <Grid
                                                component={Paper}
                                                item
                                                key={operatorStatus.checked_date}
                                                sx={{ m: 1, p: 1 }}>
                                                {operatorStatus.operator__name}
                                                <div className={'status ' + operatorStatus.state}>
                                                    {statuses[operatorStatus.state] ||
                                                        operatorStatus.state}
                                                </div>
                                            </Grid>
                                        );
                                    })}
                            </Grid>
                        )}
                    </div>
                ) : (
                    <div className="alert">Chargement en cours...</div>
                )}
            </AppPaper>
            <AppPaper sx={{ mt: 1 }}>
                <h3>Logs</h3>

                <SearchFormWithDatePickerRange
                    dateAfterLabel="date_after"
                    dateBeforeLabel="date_before"
                    setFilters={setFilters}
                    fields={searchFields}
                />
                <ListPageWrapper
                    endpoint={'/api/scheduler-logs/'}
                    fields={listFields}
                    actions={actions}
                    filters={filters}
                />
                {showLogDetail && (
                    <LogsDetail
                        logItem={showLogDetail}
                        onClose={() => {
                            setShowLogDetail(null);
                        }}
                    />
                )}
            </AppPaper>
        </>
    );
};

export const LogsDetail = ({ logItem, onClose }: any) => {
    const [show, setShow] = useState(true);
    return (
        <BirdzModal
            open={show}
            onClose={() => {
                setShow(false);
                onClose();
            }}>
            <BirdzModalTitle>Détail</BirdzModalTitle>
            <BirdzModalContent>
                {logItem.detail !== null && <pre>{JSON.stringify(logItem.detail, null, 2)}</pre>}
                {logItem.detail === null && <>Aucun détail à afficher</>}
            </BirdzModalContent>
        </BirdzModal>
    );
};

export default Statuses;
