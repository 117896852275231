import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { PieChart, Pie, Cell } from "recharts";
import "./SentDashboard.scss";

const CustomLabel = ({
  cx,
  cy,
  midAngle,
  innerRadius,
  outerRadius,
  percent,
  index,
  displayMode,
  value,
  legends,
}: any) => {
  const RADIAN = Math.PI / 180;
  const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);

  return (
    <text
      x={x}
      y={y}
      fill={legends[Object.keys(legends)[index]]?.color}
      textAnchor={x > cx ? "start" : "end"}
      dominantBaseline="central"
    >
      {displayMode === "values" ? `${value}` : `${(percent * 100).toFixed(0)}%`}
    </text>
  );
};

type SentPieData = Array<{ name: string; value: number }>
type SendPieProps = {
  data: SentPieData;
  title?: string;
  displayMode?: "values" | "percent";
  legends: any;
};
export const SentPie = ({
  data,
  title,
  displayMode,
  legends,
}: SendPieProps) => {
  const { t } = useTranslation();
  const [hiddenLegends, setHiddenLegends] = useState<string[]>([]);

  const getStatus = (status: string) => {
    return t('status.' + status.toLowerCase())
  };

  const getSum = () => {
    let sum = 0;
    data.forEach((sents: any) => {
      sum += sents.value;
    });
    return Math.round(sum);
  };

  const toggleLegend = (legend: any) => {
    if (hiddenLegends.includes(legend.label)) {
      setHiddenLegends(hiddenLegends => {
        return hiddenLegends.filter(hiddenLegend => hiddenLegend !== legend.label)
      })
    } else {
      setHiddenLegends([...hiddenLegends, legend.label])
    }
  }

  const filterData = (data: SentPieData): SentPieData => {
    if (hiddenLegends.length > 0) {
      return data.filter(item => {
        return !hiddenLegends.includes(item.name)
      })
    }
    return data;
  }

  return (
    <div>
      <div className="text-center">
        {title && <h3>{title}</h3>}
        <div className="legends">
          {Object.keys(legends).map((legendIndex: string) => {
            const legend = legends[legendIndex];
            const isHidden = hiddenLegends.includes(legend.label);
            return (
              <div style={{ cursor: 'pointer', color: isHidden ? '#CCC' : 'initial' }} key={legend.label} onClick={() => toggleLegend(legend)}>
                <span
                  style={{
                    backgroundColor: legend.bgColor,
                    opacity: isHidden ? 0.3 : 1
                  }}
                ></span>{" "}
                {getStatus(legend.label)}
              </div>
            );
          })}
        </div>
      </div>
      <div style={{ margin: "auto", width: "250px" }}>
        <PieChart width={250} height={250}>
          <Pie
            data={filterData(data)}
            labelLine={false}
            label={<CustomLabel displayMode={displayMode} legends={legends} />}
            dataKey="value"
          >
            {data.map((entry: any, index: number) => {
              return (
                <Cell
                  key={`cell-${index}`}
                  fill={legends[entry.name]?.bgColor}
                />
              );
            })}
          </Pie>
        </PieChart>
      </div>
      <div className="text-center">Total envois: {getSum()}</div>
    </div>
  );
};

export default SentPie;
