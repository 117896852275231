import { Box, Button } from '@mui/material';
import { useEffect, useState } from 'react';

const useExtendableList = (list: unknown[]) => {
    const [listToDisplay, setListToDisplay] = useState<unknown[]>([]);
    const [lengthToDisplay, setLengthToDisplay] = useState<number>(20);

    useEffect(() => {
        list.length > 0 && setListToDisplay(list.slice(0, lengthToDisplay));
    }, [list, lengthToDisplay]);

    const SeeMore = () =>
        list.length > 20 ? (
            <Box sx={{ textAlign: 'center' }}>
                <div style={{ fontSize: '0.9rem', margin: '1rem 0' }}>
                    équipements affichés : de 1 à{' '}
                    {lengthToDisplay > list.length ? list.length : lengthToDisplay} / {list.length}
                </div>
                {
                    <Button
                        disabled={listToDisplay.length === list.length}
                        onClick={() =>
                            setLengthToDisplay((lengthToDisplay) => lengthToDisplay + 20)
                        }
                        variant="contained"
                        sx={{ mb: 2 }}>
                        afficher 20 de plus
                    </Button>
                }
            </Box>
        ) : (
            <></>
        );

    return {
        listToDisplay,
        SeeMore
    };
};

export default useExtendableList;
