const statuses: any = {
  RETRYING: {
    bgColor: '#9fc1d2',
    color: "#fff"
  },
  CANCELED: {
    bgColor: "#bd0909",
    color: "#fff",
  },
  SCHEDULED: {
    bgColor: "#fec7ab",
    color: "#fff",
  },
  PENDING: {
    bgColor: "#81abbf",
    color: "#fff",
  },
  PROCESSING: {
    bgColor: "#5891ad",
    color: "#fff",
  },
  PROCESSED: {
    bgColor: "#a7d8bd",
    color: "#fff",
  },
  ERROR: {
    bgColor: "#e88090",
    color: "#fff",
  },
  ACK_TIMEOUT: {
    bgColor: "rgba(217,172,155, 1)",
    color: "#fff",
  },
  COMMAND_SUCCEED: {
    bgColor: "#63d294",
    color: "#fff",
  },
  COMMAND_FAILED: {
    bgColor: "#dc4e64",
    color: "#fff",
  },
  SENT: {
    bgColor: "rgba(73,184,97,1)",
    color: "#fff",
  },
  EXPIRED: {
    bgColor: "#e9dab7",
    color: "#fff",
  },
};

class ColorCharts {
  public getColor = (status: string) => {
    if (statuses[status.toUpperCase()]) {
      return statuses[status.toUpperCase()];
    } else {
      // generate random color
      return {
        bgColor: "#" + Math.floor(Math.random() * 16777215).toString(16),
        color: "#" + Math.floor(Math.random() * 16777215).toString(16),
      };
    }
  };
}

export const colorCharts = new ColorCharts();
