import React, { useState } from 'react';
import './SentList.scss';
import { useLocation } from 'react-router';
import SearchCampaigns from './Search/Campaigns/SearchCampaigns';
import SearchPurposes from './Search/Purposes/SearchPurposes';
import * as _ from 'lodash';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { BirdzTitle, SearchFormValue } from '@applications-terrains/birdz-react-library';
import { Box, Tab, Tabs } from '@mui/material';
import { useIsLoadingContext } from '../../contexts/IsLoadingContext';

function useQuery() {
    return new URLSearchParams(useLocation().search);
}

enum TabIndex {
    CAMPAIGNS = 0,
    PURPOSES = 1
}

export const SentList = () => {
    const query = useQuery();
    const getTabQuery = () => {
        return query.get('tab');
    };
    const tabQuery = getTabQuery();
    const { search } = useLocation();
    const navigate = useNavigate();

    const [valueTab, setValueTab] = React.useState<null | number>(null);

    const { setIsLoading } = useIsLoadingContext();

    useEffect(() => {
        const newValueTab = TabIndex[tabQuery?.toUpperCase() as keyof typeof TabIndex];
        if (valueTab !== newValueTab && tabQuery) {
            setValueTab(TabIndex[tabQuery?.toUpperCase() as keyof typeof TabIndex]);
        }
    }, [search, tabQuery, valueTab]);

    const [searchFilters, setSearchFilters] = useState<SearchFormValue>({
        formValue: {},
        filterValues: {}
    });

    const handleChangeTab = (event: any, newValueTab: number) => {
        if (valueTab === newValueTab) {
            return;
        } else {
            const params = new URLSearchParams();
            params.set('tab', TabIndex[newValueTab].toLowerCase());
            navigate({ pathname: '', search: params.toString() });
            setValueTab(newValueTab);
            setIsLoading(true)
        }
    };

    return (
        <div className="block">
            <BirdzTitle>Récapitulatif des envois</BirdzTitle>
            {valueTab !== null && (
                <Tabs value={valueTab} onChange={handleChangeTab}>
                    <Tab label="Liste des campagnes" />
                    <Tab label="Liste des intentions" />
                </Tabs>
            )}
            <Box>
                {valueTab === TabIndex.CAMPAIGNS && (
                    <SearchCampaigns
                        filters={searchFilters}
                        onFiltersChange={(newSearchFilters) => {
                            if (valueTab === TabIndex.CAMPAIGNS && !_.isEqual(searchFilters, newSearchFilters)) {
                                setSearchFilters(newSearchFilters);
                            }
                        }}
                    />
                )}
                {valueTab === TabIndex.PURPOSES && (
                    <SearchPurposes
                        filters={searchFilters}
                        onFiltersChange={(newSearchFilters) => {
                            if (valueTab === TabIndex.PURPOSES && !_.isEqual(searchFilters, newSearchFilters)) {
                                setSearchFilters(newSearchFilters);
                            }
                        }}
                    />
                )}
            </Box>
        </div>
    );
};
export default SentList;
