import React from "react";
import moment from "moment";
import { TextField } from "@mui/material";

type ParametersCalendarProps = {
  onFieldChange: any;
  value: any;
  field: any;
  disabled: boolean;
  utcOffset?: number;
};

export const ParametersCalendar = ({
  onFieldChange,
  value,
  field,
  disabled,
  utcOffset = 0
}: ParametersCalendarProps) => {
  return (
    <TextField
      type="datetime-local"
      InputLabelProps={{
        shrink: true
      }}
      defaultValue={moment(value).utcOffset(0).format("YYYY-MM-DDTHH:mm")}
      onChange={(e: any) => {
        const value = moment(e.target.value).utcOffset(utcOffset).format("YYYY-MM-DDTHH:mm:ss") + "Z";
        onFieldChange(field.id, value);
      }}
      size="small"
      disabled={disabled}
    />
  );
};
