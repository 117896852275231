import React, { useState } from 'react';
import dataService from '../../Common/Services/dataService';
import { useTranslation } from 'react-i18next';
import { Device } from '../../types';
import { CampaignUploadDevicesList } from '../CampaignUploadDevicesList/CampaignUploadDevicesList';
import {
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead
} from '@mui/material';
import {
    StyledTableBodyRow,
    StyledTableHeaderRow
} from '@applications-terrains/birdz-react-library';
import useExtendableList from '../../../hooks/useExtendableList';

type CampaignDeviceAffiliationProps = {
    onChange(devices: Device[], deviceString: string, operator?: number): void;
    operatorId: number;
};

export const CampaignDeviceAffiliation = ({
    onChange,
    operatorId
}: CampaignDeviceAffiliationProps) => {
    const { t } = useTranslation();
    const [devices, setDevices] = useState<Device[]>([]);

    const {listToDisplay, SeeMore} = useExtendableList(devices);

    return (
        <>
            <CampaignUploadDevicesList
                campaignType="affiliation"
                onChange={(devices, deviceString, operator) => {
                    onChange(devices, deviceString, operator);
                    setDevices(devices);
                }}
                operatorId={operatorId}
            />

            {devices && devices.length > 0 && (
                <TableContainer component={Paper}>
                    <Table size="small">
                        <TableHead>
                            <StyledTableHeaderRow>
                                <TableCell>{t('affiliate_module_address')}</TableCell>
                                <TableCell>{t('affiliate_module')}</TableCell>
                                <TableCell>{t('module_type')}</TableCell>
                                <TableCell>{t('protocol')}</TableCell>
                                <TableCell>{t('operator')}</TableCell>
                                <TableCell>{t('type')}</TableCell>
                                <TableCell>{t('concentrator')}</TableCell>
                            </StyledTableHeaderRow>
                        </TableHead>
                        <TableBody>
                            {(listToDisplay as Device[]).map((device) => {
                                return (
                                    <StyledTableBodyRow
                                        key={device.module_address + device.affiliate}>
                                        <TableCell>{device?.module_address}</TableCell>
                                        <TableCell>{device?.affiliate}</TableCell>
                                        <TableCell>
                                            {dataService.getDeviceType(device?.device_type)}
                                        </TableCell>
                                        <TableCell>
                                            {dataService.getProtocol(device?.protocol)?.name}
                                        </TableCell>
                                        <TableCell>
                                            {dataService.getOperator(device?.operator)?.name}
                                        </TableCell>
                                        <TableCell>{device?.type}</TableCell>
                                        <TableCell>
                                            {dataService.getProtocol(device?.protocol)?.name ===
                                                'Homerider' && device?.concentrator
                                                ? device.concentrator
                                                : '-'}
                                        </TableCell>
                                    </StyledTableBodyRow>
                                );
                            })}
                        </TableBody>
                    </Table>
                    <SeeMore />
                </TableContainer>
            )}
        </>
    );
};
