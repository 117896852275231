import { Alert } from "@mui/material";
import React from "react";

type CampaignDeviceErrorsProps = {
    errors?: string[] | undefined
}

const CampaignDeviceErrors = ({ errors }: CampaignDeviceErrorsProps) => {
    return <>{errors && Array.isArray(errors) && errors.length > 0 && (
        <Alert severity="error">
            <ul>{errors.map((error: string, errorIndex: number) =>
                <li key={`error-${errorIndex}`}>{error}</li>)}
            </ul>
        </Alert>
    )}</>
}

export default CampaignDeviceErrors;