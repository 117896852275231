export const formatData = (dataType: string, dataValue: any) => {
    switch (dataType) {
      case 'number': // cast to number, except if value === "" (if we clean input)
        dataValue = dataValue !== '' ? +dataValue : dataValue;
            break;
        case 'boolean':
            dataValue = dataValue === 'true' || dataValue === true;
            break;
        case 'string':
            dataValue = dataValue.toString();
            break;
        case 'hexadecimal':
            dataValue = dataValue.toString();
    }

    return dataValue;
}

export const setNullValues = (object: any) => {
    if (typeof object !== "object") {
      object = null;
    } else {
      for (let field in object) {
        if (
          typeof object[field] === "object" &&
          !(object[field] instanceof Array)
        ) {
          object[field] = setNullValues(object[field]);
        } else {
          object[field] = null;
        }
      }
    }
    return object;
};