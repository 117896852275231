import React from 'react';
import { AppPaper, BirdzTitle } from '@applications-terrains/birdz-react-library';
import dataService from '../Common/Services/dataService';
import moment from 'moment';
import { Operator } from '../types';
import { IconButton } from '@mui/material';
import { Search } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import { PiFileXls } from 'react-icons/pi';
import ListPageWrapper from '../Tools/ListPageWrapper';
import { useIsLoadingContext } from '../../contexts/IsLoadingContext';

export const CreatedOptimisations = () => {
    const navigate = useNavigate();

    const { setIsLoading } = useIsLoadingContext();

    return (
        <AppPaper>
            <BirdzTitle>Suivi des chantiers d'optimisation</BirdzTitle>

            <ListPageWrapper
                endpoint="/api/optimisation/optimisations/"
                fields={[
                    { name: 'contract', label: 'Contrat', orderable: true },
                    { name: 'name', label: 'Chantier', orderable: true },
                    { name: 'description', label: 'Description', orderable: true },
                    {
                        name: 'operator',
                        label: 'Opérateur',
                        transform: (value: any) => {
                            let operator = (dataService.getData('operators') as Operator[]).find(
                                (operator: any) => operator.id === value
                            );
                            return operator?.name || value;
                        },
                        orderable: true
                    },
                    {
                        name: 'scheduled_date',
                        label: "Date d'envoi",
                        orderable: true,
                        transform: (value: any) => {
                            let date = value && moment(value).format('DD/MM/YYYY à HH:mm:ss');
                            return date || '';
                        }
                    }
                ]}
                paginationOptions={{ pageSize: 100 }}
                actions={[
                    {
                        name: 'see-campaign',
                        label: 'Récap des envois',
                        render: (value: any) => (
                            <IconButton
                                onClick={() => {
                                    const campaignId =
                                        value.campaign_simple || value.campaign_combined;
                                    navigate('/sent-list?tab=purposes&campaign=' + campaignId);
                                    setIsLoading(true)
                                }}>
                                <Search fontSize="small" />
                            </IconButton>
                        ),
                        isVisible: (value: any) => {
                            return value.campaign_simple || value.campaign_combined;
                        }
                    }
                ]}
                exportButtons={[
                    {
                        name: 'Exporter en xlsx',
                        fileName: 'Suivi_des_chantiers.xlsx',
                        type: 'xlsx',
                        icon: <PiFileXls />
                    }
                ]}
            />
        </AppPaper>
    );
};

export default CreatedOptimisations;
