import {
    SearchField,
    SearchForm,
    SearchFormValue
} from '@applications-terrains/birdz-react-library';
import DatePickerRange from '../Sent/Search/DatePickerRange';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import moment from 'moment';
import { getFilterString } from '../../utils';
import useReinitializeForm from '../../hooks/useReinitializeForm';

type SearchFormWithDatePickerRangeProps = {
    setFilters: Dispatch<SetStateAction<string>>;
    setFiltersValues?: Dispatch<SetStateAction<SearchFormValue>>;
    filtersValues?: SearchFormValue;
    fields: SearchField[];
    dateAfterLabel?: string;
    dateBeforeLabel?: string;
    onSubmit?: (rawFiltersValues: SearchFormValue) => void;
};
const SearchFormWithDatePickerRange = ({
    setFilters,
    setFiltersValues,
    filtersValues,
    fields,
    dateAfterLabel = 'created_at_after',
    dateBeforeLabel = 'created_at_before',
    onSubmit
}: SearchFormWithDatePickerRangeProps) => {
    const [values, setValues] = useState<SearchFormValue>({
        formValue: {},
        filterValues: {}
    });

    //tmp State to store data until submit button is clicked to avoid unnecessary re-renders of the form
    const [tmpState, setTmpState] = useState<{ hasChanged: boolean; state: SearchFormValue }>({
        hasChanged: false,
        state: {
            formValue: {},
            filterValues: {}
        }
    });

    // setFiltersValues & filtersValues are optional props (external state),
    // so they are used primarily if specified, otherwise default is setValues & values (internal state)
    const setState = setFiltersValues || setValues;
    const state = filtersValues || values;

    const reset = useReinitializeForm();

    useEffect(() => {
        if (reset) {
            const emptyObj = {
                formValue: {},
                filterValues: {}
            };
            setState(emptyObj);
            setTmpState({ hasChanged: false, state: emptyObj });
            onSubmit && onSubmit(emptyObj);
        }
    }, [reset, setState, onSubmit]);

    return (
        <>
            <DatePickerRange
                dateAfterLabel={dateAfterLabel}
                dateBeforeLabel={dateBeforeLabel}
                setFiltersValues={(newValue) => {
                    setTmpState((tmpState) => ({
                        hasChanged: true,
                        state: {
                            formValue: {
                                ...tmpState.state.formValue,
                                ...(newValue as SearchFormValue).formValue
                            },
                            filterValues: {
                                ...tmpState.state.filterValues,
                                ...(newValue as SearchFormValue).filterValues
                            }
                        }
                    }));
                }}
                filtersValues={tmpState.hasChanged ? tmpState.state : state}
            />
            <SearchForm
                onSubmit={(_: any, rawFiltersValues: SearchFormValue) => {
                    const { created_at_after, created_at_before, ...filterValues } =
                        rawFiltersValues.filterValues;
                    const obj = {
                        ...filterValues,
                        [dateAfterLabel]: rawFiltersValues.filterValues[dateAfterLabel]
                            ? moment(rawFiltersValues.filterValues[dateAfterLabel]).format(
                                  'YYYY-MM-DD'
                              )
                            : null,
                        [dateBeforeLabel]: rawFiltersValues.filterValues[dateBeforeLabel]
                            ? moment(rawFiltersValues.filterValues[dateBeforeLabel]).format(
                                  'YYYY-MM-DD'
                              )
                            : null
                    };
                    const formattedObjToString = getFilterString(obj);
                    setFilters(formattedObjToString);
                    if (tmpState.hasChanged) {
                        setState(tmpState.state);
                        onSubmit && onSubmit(tmpState.state);
                    }
                }}
                fields={fields}
                values={state}
                filtersChange={(_, rawFiltersValues) => {
                    setTmpState({ hasChanged: true, state: rawFiltersValues });
                }}
            />
        </>
    );
};

export default SearchFormWithDatePickerRange;
