import React from "react";
import { BirdzDialog, useDialog, AppPaper, StyledTitle, Item, ListField, FormField, useNotif, BirdzNotif } from '@applications-terrains/birdz-react-library';
import { Link } from "react-router-dom";
import moment from "moment";
import * as Yup from "yup";
import Axios from "axios";
import { Button, Grid, IconButton } from "@mui/material";
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { Delete, Edit } from "@mui/icons-material";
import ListPageWrapper from "../Tools/ListPageWrapper";

const Webhooks = (params: any) => {
  const { confirmDialog, closeDialog, dialogOptions } = useDialog();
  const { notif, notifOptions } = useNotif();

  const transformItemOnSave = (webhookItem: any) => {
    if (webhookItem) {
      if (webhookItem.attached_user) {
        webhookItem.attached_user = webhookItem.attached_user.value;
      }
    }
    return webhookItem;
  }

  const transformItemOnLoad = (webhookItem: any) => {
    webhookItem.attached_user = {
      id: webhookItem.attached_user,
      name: webhookItem.attached_user__name,
    };
    return webhookItem;
  }

  const endpoint = "/api/webhooks/";

  let formFields: FormField[] = [
    {
      name: "wh_url",
      type: "url",
      label: "URL",
      validation: Yup.string()
        .url("Vous devez saisir une url valide")
        .required("Vous devez renseigner l'url du webhook"),
    },
    {
      name: "attached_user",
      type: "autocomplete",
      label: "Utilisateur rattaché",
      validation: Yup
        .object()
        .required(
          "Vous devez renseigner l'utilisateur"
        ).typeError("Vous devez renseigner l'utilisateur"),
      options: {
        source: "/api/lists/users/?ordering=name&",
        searchIsMulti: false,
      },
    },
    {
      name: "wh_user",
      type: "text",
      label: "Utilisateur",
      validation: Yup.string().required(
        "Vous devez renseigner l'utilisateur"
      ),
    },
    {
      name: "wh_password",
      type: "password",
      label: "Password",
      validation: Yup.string().required(
        "Vous devez renseigner le mot de passe"
      ),
    },
    {
      name: "wh_token",
      type: "text",
      label: "Token",
      validation: Yup.string().required("Vous devez renseigner le token"),
    },
    { name: "comment", type: "textarea", label: "Commentaire" },
    {
      name: "is_active",
      type: "radio",
      label: "Etat",
      options: {
        values: [
          { value: true, label: "Actif" },
          { value: false, label: "Inactif" },
        ],
      },
      defaultValue: true,
      permissions: ["IS_ACTIVE"],
    },
    {
      name: "updated_at",
      type: "readonly",
      label: "Dernière modification",
      transform: (value: any) => {
        let date =
          value && moment.unix(value).format("DD/MM/YYYY à HH:mm:ss");
        return date || "";
      },
    },
  ];
  let listFields: ListField[] = [
    { name: "attached_user__name", label: "Utilisateur", orderable: true },
    { name: "wh_url", label: "Url", orderable: true },
    {
      name: "is_active",
      label: "Actif",
      className: "text-center",
      options: { width: "100px" },
      orderable: true,
    },
    {
      name: "updated_at",
      label: "Date de modification",
      options: { width: "220px" },
      orderable: true,
      transform: (value: any) => {
        return (
          (value && moment.unix(value).format("DD/MM/YYYY à HH:mm:ss")) || ""
        );
      },
    },
  ];
  let actions = [
    {
      name: "edit",
      render: (value: any) => {
        return <IconButton component={Link} to={`/admin/webhooks/edit/${value.id}`}>
          <Edit fontSize="small" />
        </IconButton>
      }
    },
    {
      name: "delete-webhook",
      render: (model: any) => {
        return <IconButton onClick={() => {
          confirmDialog({
            title: "Supprimer le webhook",
            content: "Êtes-vous sûr de vouloir supprimer ce webhook?",
            onValidate: () => {
              Axios.delete(`${endpoint}${model.id}/`).then(
                () => {
                  notif({
                    content: "Le webhook a été supprimé avec succès",
                    type: "success",
                  });
                  document.location.reload();
                },
                () => {
                  notif({
                    content: "Une erreur est survenue lors de la suppression",
                    type: "error",
                  });
                }
              )
              closeDialog();
            },
            onCancel: () => {
              closeDialog();
            }
          })
        }}><Delete fontSize="small" /></IconButton>
      }
    },
  ];
  let searchFields = [
    {
      name: "created_by",
      label: "Utilisateur rattaché",
      options: {
        identifier: "id",
        source: "/api/users/?ordering=name&",
        label: "name",
      },
    },
    {
      name: "wh_url",
      label: "URL",
      options: {
        identifier: "wh_url",
        source: "/api/webhooks/",
        label: "wh_url",
      },
    },
  ];

  return (
    <AppPaper>
      {params.action === "list" && (
        <>
          <Grid justifyContent="space-between" container>
            <Grid item>
              <StyledTitle>Liste des webhooks</StyledTitle>
            </Grid>
            <Grid item>
              <Link to="/admin/webhooks/add">
                <Button variant="contained" sx={{ mt: 3 }}>
                  <AddCircleIcon sx={{ mr: 1 }} /> Ajouter un webhook
                </Button>
              </Link>
            </Grid>
          </Grid>

          <ListPageWrapper
            endpoint={endpoint}
            fields={listFields}
            actions={actions}
            searchFields={searchFields}
          />
          <BirdzDialog options={dialogOptions} />
        </>
      )}
      {params.action === "add" && (
        <Item
          action="add"
          endpoint={endpoint}
          fields={formFields}
          name="Ajouter un webhook"
          transformItemOnSave={transformItemOnSave}
          transformItemOnLoad={transformItemOnLoad}
        />
      )}
      {params.action === "edit" && (
        <Item
          action="edit"
          endpoint={endpoint}
          fields={formFields}
          name="Modifier le webhook"
          transformItemOnSave={transformItemOnSave}
          transformItemOnLoad={transformItemOnLoad}
        />
      )}
      <BirdzNotif options={notifOptions} />
    </AppPaper>
  );
}

export default Webhooks;
