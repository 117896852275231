import React from "react";
import { BirdzDialog, useDialog, StyledTitle, AppPaper, Item, FormField, BirdzNotif, useNotif } from '@applications-terrains/birdz-react-library';
import { Link } from "react-router-dom";
import moment from "moment";
import * as Yup from "yup";
import Axios from "axios";
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { Button, Grid, IconButton } from "@mui/material";
import { Delete, Edit } from "@mui/icons-material";
import ListPageWrapper from "../Tools/ListPageWrapper";

const Sections = (params: any) => {
  let endpoint = "/api/sections/";
  const { confirmDialog, closeDialog, dialogOptions } = useDialog();
  const { notif, notifOptions } = useNotif();

  let formFields: FormField[] = [
    {
      name: "description",
      type: "text",
      label: "Description",
      validation: Yup.string().required(
        "Vous devez renseigner le nom de la section"
      ),
    },
    {
      name: "is_active",
      type: "radio",
      label: "Etat",
      options: {
        values: [
          { value: true, label: "Actif" },
          { value: false, label: "Inactif" },
        ],
      },
      defaultValue: true,
      permissions: ["IS_ACTIVE"],
    },
    {
      name: "code",
      type: "text",
      label: "Code technique",
    },
    {
      name: "updated_at",
      type: "readonly",
      label: "Dernière modification",
      transform: (value: any) => {
        let date =
          value && moment.unix(value).format("DD/MM/YYYY à HH:mm:ss");
        return date || "";
      },
    },
  ];
  let listFields = [
    { name: "description", label: "Description", orderable: true },
    {
      name: "code",
      label: "Code section",
      options: { width: "140px" },
      orderable: true,
    },
    {
      name: "is_active",
      label: "Actif",
      className: "text-center",
      options: { width: "100px" },
      orderable: true,
    },
    {
      name: "updated_at",
      label: "Date de modification",
      options: { width: "220px" },
      orderable: true,
      transform: (value: any) => {
        return (
          (value && moment.unix(value).format("DD/MM/YYYY à HH:mm:ss")) || ""
        );
      },
    },
  ];
  let searchFields = [
    {
      name: "description",
      label: "Description",
      options: {
        identifier: "description",
        source: "/api/sections/",
        label: "description",
        formatResponse: (response: any) => {
          return {
            value: response.description,
            label: `${response.description} (${response.code})`
          }
        },
        searchIsMulti: false,
      },
    },
    {
      name: "code",
      label: "Code section",
      options: {
        identifier: "code",
        source: "/api/sections/",
        label: "description",
        formatResponse: (response: any) => {
          return {
            value: response.code,
            label: `${response.description} (${response.code})`
          }
        },
        searchIsMulti: false,
      },
    },
    {
      name: "group",
      label: "Groupe",
      options: {
        identifier: "id",
        source: "/api/lists/groups/?ordering=name&",
        label: "name",
        searchIsMulti: false,
      },
    },
  ];
  let actions = [
    {
      name: "edit",
      render: (value: any) => {
        return <IconButton component={Link} to={`/admin/sections/edit/${value.id}`}>
          <Edit fontSize="small" />
        </IconButton>
      }
    },
    {
      name: "delete-section",
      render: (model: any) => {
        return <IconButton
          onClick={() => {
            confirmDialog({
              title: "Supprimer la section",
              content: "Êtes-vous sûr de vouloir supprimer cette section?",
              onValidate: () => {
                Axios.delete(`${endpoint}${model.id}/`).then(
                  () => {
                    notif({
                      content: 'La section a été supprimée avec succès',
                      type: 'success'
                    });
                    document.location.reload();
                  },
                  () => {
                    notif({
                      content: 'Une erreur est survenue lors de la suppression',
                      type: 'error'
                    });
                  }
                )
                closeDialog();
              },
              onCancel: () => {
                closeDialog();
              }
            })
          }}
        >
          <Delete fontSize="small" />
        </IconButton>
      }
    },
  ];

  return (
    <AppPaper>
      {params.action === "list" && (
        <>
          <Grid justifyContent="space-between" container>
            <Grid item>
              <StyledTitle>Liste des sections</StyledTitle>
            </Grid>
            <Grid item>
              <Link to="/admin/sections/add">
                <Button variant="contained" sx={{ mt: 3 }}>
                  <AddCircleIcon sx={{ mr: 1 }} /> Ajouter une section
                </Button>
              </Link>
            </Grid>
          </Grid>
          <ListPageWrapper
            endpoint={endpoint}
            fields={listFields}
            actions={actions}
            searchFields={searchFields}
            defaultOrder={['code']}
          />
          <BirdzDialog options={dialogOptions} />
        </>
      )}
      {params.action === "add" && (
        <Item
          action="add"
          endpoint={endpoint}
          fields={formFields}
          name="Ajouter une section"
        />
      )}
      {params.action === "edit" && (
        <Item
          action="edit"
          endpoint={endpoint}
          fields={formFields}
          name="Modifier la section"
        />
      )}
      <BirdzNotif options={notifOptions} />
    </AppPaper>
  );
}

export default Sections;
