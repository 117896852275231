import React, { useEffect, useState } from "react";
import { AppPaper, BirdzTitle } from '@applications-terrains/birdz-react-library'
import { useParams } from "react-router-dom";
import OptimisationContractsSelection from "./OptimisationContractsSelection";
import OptimisationForm from "./OptimisationForm";


export const Optimisation = () => {
    const { contractsParam } = useParams<any>();
    const [contracts, setContracts] = useState<string[]>([]);

    useEffect(() => {
        setContracts(contractsParam ? contractsParam.split(',') : []);
    }, [contractsParam]);

    return (
        <AppPaper>
            <BirdzTitle>
                Créer un chantier d'optimisation {contracts?.length > 0 && `(sur ${contracts.length} contrat${contracts?.length > 1 ? `s` : ``})`}
            </BirdzTitle>
            {contracts?.length ? <OptimisationForm contracts={contracts} /> : <OptimisationContractsSelection />}
        </AppPaper>
    )
};


export default Optimisation;
