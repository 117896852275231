import React from 'react'
import { InputField, SelectField } from '@applications-terrains/birdz-react-library';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid } from '@mui/material';
import DatePicker from "react-datepicker";
import { Form, Formik, FormikHelpers } from 'formik';
import dataService from '../../Common/Services/dataService';
import * as Yup from "yup";
import { registerLocale, setDefaultLocale } from "react-datepicker";
import fr from "date-fns/locale/fr";


registerLocale("fr", fr);
setDefaultLocale("fr");

const tokenTypes = [
  { value: 'campaign_creation', label: 'Création de campagne' },
  { value: 'frame_generation', label: 'Génération de trames' }
]

export default function TokemFormModal({ token, onSave, onCancel }: any) {
  return (
    <Formik
      initialValues={token}
      enableReinitialize={true}
      onSubmit={(values: FormikHelpers<any>) => {
        onSave(values)
      }}
      validationSchema={Yup.object().shape({
        type: Yup.string().required("Vous devez renseigner le type"),
        templates: Yup.array().required('Vous devez choisir au moins un modèle')
      })}
    >
      {({
        values,
        setFieldValue,
        submitForm
      }: any) => (
        <Form>
          <Dialog sx={{ '& .MuiDialog-paper': { width: '60%', maxHeight: 435 } }} maxWidth="md" open={true}>
            <DialogTitle>{token?.id ? 'Modifier' : 'Ajouter'} un token</DialogTitle>
            <DialogContent>
              <Grid container spacing={1}>
                <Grid item xs={6}>
                  Modèles autorisés *
                </Grid>
                <Grid item xs={6}>
                  <SelectField
                    name="templates"
                    options={dataService.getSelectData("frameTemplates", {}, { label: 'name' }) || []}
                    isMulti
                  />
                </Grid>

                <Grid item xs={6}>
                  Ajouter une clé avec une validité maximum
                </Grid>
                <Grid item xs={6}>
                  <DatePicker
                    selected={
                      values?.validity_date
                        ? new Date(values.validity_date)
                        : null
                    }
                    onChange={(date: any) => {
                      setFieldValue("validity_date", date);
                    }}
                    locale="fr"
                    dateFormat="dd/MM/yyyy"
                  />
                  (laisser vide si pas de limite de temps)
                </Grid>

                <Grid item xs={6}>
                  Type *
                </Grid>
                <Grid item xs={6}>
                  <SelectField
                    name="type"
                    options={tokenTypes}
                  />
                </Grid>

                <Grid item xs={6}>
                  Opérateurs
                </Grid>
                <Grid item xs={6}>
                  <SelectField
                    name="operators"
                    options={dataService.getSelectData("operators", {}, { label: 'name' }) || []}
                    isMulti
                  />
                </Grid>

                <Grid item xs={6}>
                  Clients
                </Grid>
                <Grid item xs={6}>
                  <SelectField
                    name="customers"
                    options={dataService.getSelectData("customers", {}, { value: 'code', label: 'code' }) || []}
                    isMulti
                  />
                </Grid>

                <Grid item xs={6}>
                  Contrats
                </Grid>
                <Grid item xs={6}>
                  <SelectField
                    name="contracts"
                    options={dataService.getData("contracts").map((contract: any) => { return { label: `${contract?.code} ${contract?.label}`, value: `${contract?.code}` } }).filter((contract: any) => contract.value && contract.label) || []}
                    isMulti
                  />
                </Grid>

                <Grid item xs={6}>
                  Quota
                </Grid>
                <Grid item xs={6}>
                  <InputField
                    name="purposes_quota"
                    type="number"
                    size="small"
                    fullWidth
                  />
                  <br />(laisser vide si pas de limite de quota)
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions>
              <Button onClick={() => { onCancel() }} key="button-cancel">Annuler</Button>
              <Button
                variant="contained"
                onClick={() => submitForm()}
              >{token?.id ? 'Modifier' : 'Ajouter'}
              </Button>
            </DialogActions>
          </Dialog>
        </Form>
      )}
    </Formik>
  )
}
