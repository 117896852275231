import {
    AppPaper,
    BirdzNotif,
    BirdzTitle,
    useNotif
} from '@applications-terrains/birdz-react-library';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { DiffEditor } from '@monaco-editor/react';
import './ConfigurationCr.scss';
import { CrFilenames } from './ConfigurationCrForm';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    IconButton
} from '@mui/material';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { Search } from '@mui/icons-material';
import { PiFileCsv } from 'react-icons/pi';
import SearchFormWithDatePickerRange from '../Tools/SearchFormWithDatePickerRange';
import ListPageWrapper from '../Tools/ListPageWrapper';

type CR = {
    created_at: number;
    crplus: string;
    error: string;
    filename: string;
    id: number;
    new_config: string;
    original_config: string;
    send_date: string;
    send_sms: boolean;
    source_file_date: string;
    state: string;
    updated_at: number;
};

export const ConfigurationCr = () => {
    const [filters, setFilters] = useState<string>('');
    const [detailCR, setDetailCR] = useState<CR>();
    const today = new Date();
    const filename = `${today.getDate()}/${today.getMonth() + 1}/${today.getFullYear()}-${today.getHours()}h${today.getMinutes()}m${today.getSeconds()}s`;
    const exportFileName = `export-config-CR-${filename}.csv`;
    const { notif, notifOptions } = useNotif();

    const displayField = (field: string, value: string) => {
        return (
            <>
                <Grid item xs={2}>
                    {field}
                </Grid>
                <Grid item xs={10}>
                    {value}
                </Grid>
            </>
        );
    };

    return (
        <div>
            {detailCR?.id}
            <AppPaper>
                <>
                    <Grid justifyContent="space-between" container>
                        <Grid item>
                            <BirdzTitle>Configuration CR</BirdzTitle>
                        </Grid>
                        <Grid item>
                            <Link to="/configuration-cr/new-config">
                                <Button variant="contained" sx={{ mt: 3 }}>
                                    <AddCircleIcon sx={{ mr: 1 }} /> Nouvelle modification
                                </Button>
                            </Link>
                        </Grid>
                    </Grid>

                    <SearchFormWithDatePickerRange
                        dateAfterLabel="send_date_after"
                        dateBeforeLabel="send_date_before"
                        setFilters={setFilters}
                        fields={[
                            {
                                name: 'crplus',
                                label: 'Device',
                                type: 'text'
                            },
                            {
                                name: 'campaign__filename',
                                type: 'select',
                                label: 'Type de fichier',

                                options: {
                                    values: CrFilenames.map((crFile: string) => {
                                        return { value: crFile, label: crFile };
                                    })
                                }
                            },
                            // {
                            //     name: 'send_date_after',
                            //     label: 'Entre le ',
                            //     type: 'datepicker'
                            // },
                            // {
                            //     name: 'send_date_before',
                            //     label: 'et le',
                            //     type: 'datepicker'
                            // },
                            {
                                name: 'campaign__created_by',
                                label: 'Créateur de la modif',
                                options: {
                                    identifier: 'id',
                                    source: '/api/users/?ordering=name&',
                                    label: 'name'
                                }
                            },
                            {
                                name: 'state',
                                label: 'Etat',
                                type: 'select',
                                options: {
                                    values: [
                                        { value: '', label: '' },
                                        { value: 'SENT', label: 'Envoyé' },
                                        { value: 'PROCESSING', label: 'Traitement en cours' },
                                        { value: 'ERROR', label: 'Erreur' }
                                    ]
                                }
                            }
                        ]}
                    />

                    <ListPageWrapper
                        filters={filters}
                        endpoint={'/api/cr-plus/config-updates/'}
                        fields={[
                            { name: 'crplus', label: 'CR', orderable: true },
                            {
                                name: 'type',
                                label: 'Type',
                                transform: (item: any) => {
                                    return 'CR+';
                                }
                            },
                            { name: 'filename', label: 'Type de fichier', orderable: true },
                            {
                                name: 'source_file_date',
                                label: 'Date fichier source',
                                orderable: true,
                                transform: (item: any) => {
                                    return item
                                        ? moment(item).format('DD/MM/YYYY à HH:mm:ss')
                                        : '-';
                                }
                            },
                            {
                                name: 'send_date',
                                label: 'Date envoi',
                                orderable: true,
                                transform: (item: any) => {
                                    return item
                                        ? moment(item).format('DD/MM/YYYY à HH:mm:ss')
                                        : '-';
                                }
                            },
                            {
                                name: 'send_sms',
                                label: 'Envoi SMS / Retour',
                                className: 'text-center'
                            },
                            {
                                name: 'state',
                                label: 'Etat',
                                orderable: true,
                                transform: (value: any) => {
                                    switch (value) {
                                        case 'SENT':
                                            return 'Envoyé';
                                        case 'PROCESSING':
                                            return 'Traitement en cours';
                                        case 'ERROR':
                                            return 'Erreur';
                                        default:
                                            return value;
                                    }
                                }
                            }
                        ]}
                        actions={[
                            {
                                name: 'see',
                                label: 'Voir',
                                render: (CR: CR) => {
                                    return (
                                        <IconButton
                                            onClick={() => {
                                                setDetailCR(CR);
                                            }}>
                                            <Search fontSize="small" />
                                        </IconButton>
                                    );
                                }
                            }
                        ]}
                        exportButtons={[
                            {
                                name: 'Exporter en csv',
                                fileName: exportFileName,
                                type: 'csv',
                                icon: <PiFileCsv />,
                                requestEndpoint: '/api/cr-plus/config-updates/export/',
                                requestMethod: 'POST',
                                responseType: 'json',
                                keepQueryParams: false,
                                onSuccess: () => {
                                    notif({
                                        type: 'success',
                                        content: "L'export va vous être envoyé par mail."
                                    });
                                },
                                additionalParams: `timezone=${Intl.DateTimeFormat().resolvedOptions().timeZone}`
                            }
                        ]}
                    />
                    <BirdzNotif options={notifOptions} />
                </>
            </AppPaper>

            {detailCR?.id && (
                <Dialog
                    className="configCr"
                    sx={{ '& .MuiDialog-paper': { width: '80%', maxHeight: 435 } }}
                    maxWidth="xl"
                    open={true}>
                    <DialogTitle>CR {detailCR.crplus}</DialogTitle>
                    <DialogContent dividers>
                        <Grid container spacing={1}>
                            {displayField('Fichier', detailCR.filename)}
                            {displayField('SMS', detailCR.send_sms ? 'Oui' : 'Non')}
                            {displayField(
                                'Date fichier source',
                                detailCR.source_file_date
                                    ? moment(detailCR.source_file_date).format(
                                          'DD/MM/YYYY à HH:mm:ss'
                                      )
                                    : '-'
                            )}
                            {displayField(
                                'Date envoi',
                                detailCR.send_date
                                    ? moment(detailCR.send_date).format('DD/MM/YYYY à HH:mm:ss')
                                    : '-'
                            )}

                            {detailCR.error && displayField('Erreurs', detailCR.error)}
                        </Grid>

                        <div className="diffeditor">
                            <div>Fichier source</div>
                            <div>Fichier modifié</div>
                        </div>
                        <DiffEditor
                            height="40vh"
                            original={detailCR?.original_config}
                            modified={detailCR?.new_config}
                            originalLanguage="xml"
                            modifiedLanguage="xml"
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button variant="contained" onClick={() => setDetailCR(undefined)}>
                            Fermer
                        </Button>
                    </DialogActions>
                </Dialog>
            )}
        </div>
    );
};

export default ConfigurationCr;
