import { ReactNode, createContext, useContext, useState } from 'react';

type ResetFormContextType = {
    reset: boolean;
    setReset: (value: boolean) => void;
};

const ResetFormContext = createContext<ResetFormContextType | undefined>(undefined);

export const useResetFormContext = () => {
    const context = useContext(ResetFormContext);

    if (context === undefined) {
        throw new Error('useResetFormContext must be used with a ResetFormContext');
    }

    return context;
};

export const ResetFormContextProvider = ({ children }: { children: ReactNode }) => {
    const [reset, setReset] = useState<boolean>(false);

    const value = {
        reset,
        setReset,
    };

    return (
        <ResetFormContext.Provider value={value}>
            {children}
        </ResetFormContext.Provider>
    );
};
