import { AppPaper, StyledTitle } from '@applications-terrains/birdz-react-library'
import moment from "moment";
import React from "react";
import { useNavigate } from "react-router-dom";
import ChartFailedInstructions from "../Chart/ChartFailedInstructions";
import ChartSuccessFail from "../Chart/ChartSuccessFail";
import { ninetyDaysAgo } from "../Chart/ChartUtils";
import { PiFileXls } from "react-icons/pi";
import { Search } from "@mui/icons-material";
import { IconButton } from '@mui/material';
import ListPageWrapper from '../../Tools/ListPageWrapper';


export const DeletedLinks = () => {
    const navigate = useNavigate();

    return (
        <AppPaper>
            <StyledTitle>Statut des commandes de nettoyage de répéteur(s) sur les 90 derniers jours</StyledTitle>
            <h5 className="text-center">Évolution des succès et des échecs</h5>
            <ChartSuccessFail type="NET" height={150} />
            <hr />
            <h5 className="text-center">Répartition des statuts en échec</h5>
            <ChartFailedInstructions type="NET" height={150} />

            <ListPageWrapper
                name=""
                endpoint="/api/racsup/instructions/"
                fields={[
                    { name: "contract", label: "Contrat", orderable: true },
                    { name: "name", label: "Chantier", orderable: true },
                    {
                        name: "end_date", label: "Date de fin", orderable: true, transform: (value: any) => {
                            let date =
                                value && moment(value).format("DD/MM/YYYY à HH:mm:ss");
                            return date || "";
                        }
                    }
                ]}
                filters={"&type=NET&created_at_after=" + ninetyDaysAgo}
                exportButtons={[
                    {
                        name: "Exporter en xlsx",
                        fileName: "Suivi des commandes de nettoyage.xlsx",
                        type: "xlsx",
                        icon: <PiFileXls />
                    }
                ]}
                paginationOptions={{ pageSize: 100 }}
                actions={[
                    {
                        name: 'see-campaign',
                        render: (value: any) => <IconButton onClick={() => {
                            navigate('/sent-list?tab=purposes&campaign=' + value.campaign)
                        }}><Search fontSize="small" /></IconButton>,
                        label: "Récap des envois",
                        isVisible: (value: any) => {
                            return value?.campaign;
                        }
                    }
                ]}
            />
        </AppPaper>
    );
};

export default DeletedLinks;
