import React, { useEffect, useState } from "react";
import axios from "axios";
import "./MessageList.scss";
import { Card, CardContent, CardHeader, Typography } from "@mui/material";
import moment from "moment";

type Message = {
  title: string;
  content: string;
  created_at: number;
  updated_at: number;
}

const MessageList = () => {
  const [messages, setMessages] = useState<Message[]>([]);

  useEffect(() => {
    axios.get("/api/messages/?is_active=true&ordering=-updated_at").then((response: any) => {
      let data = response.data;
      if (data.results) {
        setMessages(data.results);
      }
    });
  }, [])

  return (
    <div className="messages">
      {messages.map((message: any) => (
        <Card key={message.id} sx={{ mb: 2 }} variant="outlined">
          <CardHeader
            title={message.title}
            subheader={moment.unix(message.created_at).format('DD/MM/YYYY')}
          />
          <CardContent>
            <Typography dangerouslySetInnerHTML={{ __html: message.content }} />
          </CardContent>
        </Card>
      ))}
    </div>
  );
}
export default MessageList;
