import React from "react";
import { useNavigate } from "react-router-dom";
import { Login as LoginForm } from '@applications-terrains/birdz-react-library';
import { Box } from "@mui/material";
import { authService } from "../..";

const Login = () => {
  const navigate = useNavigate();
  return <Box className="loginForm">
    <LoginForm
      onSubmit={(credentials: any) => {
        return new Promise((resolve, reject) => {
          authService.login(credentials.email, credentials.password).then(
            () => {
              resolve();
              navigate('/');
            },
            (error: any) => {
              reject(typeof error === 'string' ? error : 'Une erreur serveur est survenue');
            }
          );
        })
      }}
      onSSOButtonClick={() => {
        authService.login_sso();
      }}
      options={{
        logo: <img src="/logo-transparent-horizontal.png" className="img-fluid" alt="Birdz" style={{ width: '100%' }} />,
        //eslint-disable-next-line
        backgroundUrl: process.env.PUBLIC_URL + '/murmuration-blue.png'
      }
      }
    />
  </Box>
}

export default Login;