import { TextField } from "@mui/material";
import React, { useCallback, useState } from "react";
import Parameters from "./Parameters/Parameters";

export const Sandbox = () => {
  const defaultPayload = {
    // Device_id: 'blabla',
    // Conf_Protocol_Radio: '10100'
  }

  const [payload, setPayload] = useState<any>(defaultPayload);
  const [text, setText] = useState<string>('');
  const [payloadErrors, setPayloadErrors] = useState<any>({});

  const onChange = useCallback((parameters: any, errors: any) => {
    setPayload(parameters)
  }, []);

  const onErrors = useCallback((errors: any) => {
    setPayloadErrors(errors)
  }, []);

  return <>
    <pre>{JSON.stringify(payload, null, 2)}</pre>
    <pre>{JSON.stringify(payloadErrors, null, 2)}</pre>

    <Child
      // codec='/API-BI_ENC_Halima/AS51_GET_INFO' 
      // codec='/Gx_ENC/SIHR_G4_CONF1'
      codec='/Gx_ENC/AS51_LPWAN'
      payload={payload}
      onChange={onChange}
      onErrors={onErrors}
    />

    <TextField
      type={"text"}
      size="small"
      fullWidth
      onChange={(e: any) => {
        setText(e.target.value)
      }}
    />
    --{text}--
  </>
}

const Child = React.memo(({ codec, payload, onChange, onErrors }: any) => {
  return <Parameters
    codec={codec}
    values={payload}
    onChange={onChange}
    onErrors={onErrors}
  />
});