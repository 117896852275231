import { useEffect } from 'react';
import { useResetFormContext } from '../contexts/ResetFormContext';

const useReinitializeForm = () => {
    const { reset, setReset } = useResetFormContext();
    useEffect(() => {
        if (reset) {
            setReset(false);
        }
        const button = document.getElementById('reinitialize');
        const handleReinitializeClick = () => {
            setReset(true);
        };
        if (
            button 
        ) {
            button.addEventListener('click', handleReinitializeClick);
        }
        return () => {
            button && button.removeEventListener('click', handleReinitializeClick);
        };
    }, [reset, setReset]);

    return reset;
};

export default useReinitializeForm;
