import React from "react";
import "react-datepicker/dist/react-datepicker.css";
import "./DashboardDetails.scss";
import ChartSuccessFail from "./Chart/ChartSuccessFail";
import ChartFailedInstructions from "./Chart/ChartFailedInstructions";

export const DashboardDetails = () => {
    return (
        <span className="dashboard-details">
            <h2>Primo-raccordement(s) - Re-raccordement(s) - Renforcement(s) - Gestion directe</h2>
            <ChartSuccessFail title="Évolution des succès et des échecs sur les 90 derniers jours" />
            <hr />
            <ChartFailedInstructions title="Répartition des statuts en échec sur les 90 derniers jours" />
        </span>
    );
};

export default DashboardDetails;
