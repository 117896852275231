import { SearchFormValue } from "@applications-terrains/birdz-react-library";
import moment from "moment";

export type SearchFilter = {
  name: string;
  value: any;
};

export class SearchFilters {
  private _filters: SearchFilter[] = [];

  //   constructor() {}

  get filters(): SearchFilter[] {
    return this._filters;
  }

  set filters(newFilters: SearchFilter[]) {
    this._filters = newFilters;
  }

  reset() {
    this._filters = [];
  }

  add(filter: SearchFilter, position: number | undefined = undefined) {
    // si le filtre existe déjà => update
    if (this.getPosition(filter.name) !== -1) {
      this.update(filter.name, filter.value);
    }
    // sinon ajout
    else {
      if (position !== undefined) {
        this._filters.splice(position, 0, filter);
      } else {
        this._filters.push(filter);
      }
    }
  }

  update(filterName: string, filterValue: any) {
    this._filters = this._filters.map((searchFilter) => {
      if (searchFilter.name === filterName) {
        searchFilter.value = filterValue;
      }
      return searchFilter;
    });
  }

  addAfter(filter: SearchFilter, filterName: string) {
    const filterPosition = this.getPosition(filterName) + 1;
    this.add(filter, filterPosition);
  }

  remove(filterName: string) {
    this._filters = this._filters.filter((searchFilter) => {
      if (searchFilter.name !== filterName) {
        return searchFilter;
      }
      return null;
    });
  }

  getPosition(filterName: string) {
    return this._filters
      .map((searchFilter) => searchFilter.name)
      .indexOf(filterName);
  }

  rename(filterNameToRename: string, newName: string) {
    this._filters = this._filters.map((searchFilter) => {
      if (searchFilter.name === filterNameToRename) {
        searchFilter.name = newName;
      }
      return searchFilter;
    });
  }

  // FROM [{name:'filterName', value: filterValue}]; ==> TO {'filterName': filterValue};
  formatToSearchForm(): SearchFormValue {
    let formValue: Record<string, any> = {};
    let filterValues: Record<string, any> = {};

    this._filters.forEach((searchFilter) => {
      formValue[searchFilter.name] = searchFilter.value;
      filterValues[searchFilter.name] = this.getFilterValue(searchFilter);
    });

    return {
      formValue,
      filterValues,
    };
  }

  getFilterValue(searchFilter: SearchFilter) {
    if (searchFilter.value) {
      if (Array.isArray(searchFilter.value)) {
        return searchFilter.value.map((itemValue: any) => {
          return itemValue.value || itemValue;
        });
      }
      return searchFilter?.value?.value || searchFilter?.value;
    }
  }

  // FROM {'filterName': filterValue}; ===> TO [{name:'filterName', value: filterValue}];
  formatFiltersFromSearchForm(searchFormFilters: any) {
    if (searchFormFilters && Object.keys(searchFormFilters).length) {
      Object.keys(searchFormFilters).forEach((searchFormFilterName) => {
        const value = searchFormFilters[searchFormFilterName];
        if (value !== undefined && value !== null && value?.value !== null) {
          this.add({
            name: searchFormFilterName,
            value: value,
          });
        }
      });
    }
  }

  // return string filter1=value1&filter2=value2 ...
  getFilterString() {
    const filterString = this._filters
      .map((filter) => {
        let filterValue = this.getFilterValue(filter);

        // if value is a date string. ie: "Thu Dec 01 2022 11:45:00 GMT+0100 (heure normale d’Europe centrale)"
        if (Array.isArray(filterValue)) {
          filterValue = filterValue.join(",");
        } else if (
          typeof filterValue === "object" &&
          !isNaN(Date.parse(filterValue))
        ) {
          filterValue =
            moment(new Date(filterValue))
              .utcOffset(0)
              .format("YYYY-MM-DDTHH:mm:ss") + "Z";
        }

        if (filterValue === undefined) {
          return "";
        }
        return `${filter.name}=${filterValue}`;
      })
      .join("&");
    return filterString ? `&${filterString}` : ``;
  }
}
