import React, { useEffect, useState } from "react";
import { Formik, Form, FormikHelpers, useFormikContext, FormikValues } from "formik";
import Select from "react-select";
import Axios from "axios";
import moment from "moment";
import "./ConfigurationCrForm.scss";
import Editor, { DiffEditor } from "@monaco-editor/react";
import { useNavigate } from "react-router-dom";
import { Loader } from "../Common/Layout/Loader";
import { Alert, Box, Button, Checkbox, FormControlLabel, Grid, IconButton, Paper, Radio, RadioGroup, Table, TableBody, TableCell, TableContainer, TableHead, TextField } from "@mui/material";
import { AppPaper, AutocompleteField, BirdzNotif, FormField, StyledTableBodyRow, StyledTableHeaderRow, StyledTitle, useNotif } from "@applications-terrains/birdz-react-library";
import { ArrowLeft, ArrowRight } from "@mui/icons-material";

export const CrFilenames = [
    "hr_coo_rf.cfgalarms.default.xml",
    "hr_coo_rf.cfgalarms.local.xml",
    "hr_coo_rf.cfgalarms.product.xml",
    "hr_coo_rf.cfgbeacons.default.xml",
    "hr_coo_rf.cfgbeacons.local.xml",
    "hr_coo_rf.cfgbeacons.product.xml",
    "hr_coo_rf.cfgcards.default.xml",
    "hr_coo_rf.cfgcards.local.xml",
    "hr_coo_rf.cfgcards.product.xml",
    "hr_coo_rf.cfghrdevices.default.xml",
    "hr_coo_rf.cfghrdevices.local.xml",
    "hr_coo_rf.cfghrdevices.product.xml",
    "hr_coo_rf.cfgnetwork.default.xml",
    "hr_coo_rf.cfgnetwork.local.xml",
    "hr_coo_rf.cfgnetwork.product.xml",
    "hr_coo_rf.cfgmanuf.default.xml",
    "hr_coo_rf.cfgmanuf.local.xml",
    "hr_coo_rf.cfgmanuf.product.xml",
    "hr_coo_rf.cfgcalls.default.xml",
    "hr_coo_rf.cfgcalls.local.xml",
    "hr_coo_rf.cfgcalls.product.xml"
];

type Device = {
    module_address: string
    source_file_date: string
}

const SelectFileName = ({ onChange, onError }: any) => {
    const {
        values, touched, setFieldValue, getFieldProps, isSubmitting, errors
    }: any = useFormikContext();

    const getConfig = (filename: string) => {
        const firstModuleAddress = values?.devices && values?.devices[0]?.module_address;
        Axios.post('/api/cr-plus/get-config/', { module_address: firstModuleAddress, filename }).then((response: any) => {
            onChange(response.data)
        }, (error) => {
            onError(error?.response?.data?.error || "")
        })
    };


    return <>
        <div
            className={
                (touched.filename || isSubmitting) &&
                    errors.filename
                    ? "select-container is-invalid"
                    : "select-container"
            }
        >
            <Select
                options={CrFilenames.sort().map((filename: string) => { return { value: filename, label: filename } })}
                {...getFieldProps("filename")}
                onChange={(selectedFilename: any) => {
                    setFieldValue("filename", selectedFilename);
                    getConfig(selectedFilename.value);
                }}
                value={values?.filename || null}
                loadingMessage={() => "Chargement en cours..."}
                noOptionsMessage={() =>
                    "Aucune valeur à sélectionner"
                }
                placeholder=""
            />
        </div>
        {(touched.filename || isSubmitting) && errors.filename &&
            <Box sx={{ color: 'red' }}>{errors.filename}</Box>
        }
    </>
}



type SearchDevicesProps = {
    onChange(devices: Device[]): void;
    onError(error: string): void;
}
const SearchDevices = ({ onChange, onError }: SearchDevicesProps) => {
    const { values }: any = useFormikContext();

    return <Button variant="contained" disabled={!values?.devicesString} onClick={async () => {
        try {
            const response = await Axios.post('/api/cr-plus/search/', { devices: values?.devicesString })
            const devices = response.data?.devices;
            onChange(devices)
        }
        catch (error: any) {
            const errorMessage = error?.response?.data?.error || "";
            onError(errorMessage)
        }
    }}>
        Rechercher
    </Button>
}

// type TemplateErrors = {
//     [moduleAddress: string]: string
// }
// type Config = {
//     config?: string;
//     filename?: string;
//     source_file_date?: string;
//     error?: string;
// }
// type PreviewDiff = {
//     [moduleAddress: string]: string
// }

export const ConfigurationCrForm = () => {
    const navigate = useNavigate();
    const [showPreviewMode, setShowPreviewMode] = useState<boolean>(false);
    const [showTemplateSelection, setShowTemplateSelection] = useState<boolean>(false);
    const [displayEditor, setDisplayEditor] = useState<boolean>(true);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [previewIsLoading, setPreviewIsLoading] = useState<boolean>(false);
    const { notif, notifOptions } = useNotif();

    const refreshEditor = () => {
        const currentState = displayEditor;
        setDisplayEditor(!currentState);
        setTimeout(() => {
            setDisplayEditor(currentState);
        })
    }


    const PreviewPrevCR = ({ onChange }: any) => {
        const { values }: any = useFormikContext();
        const CRList = values?.devices.map((device: Device) => device.module_address);
        const previewCR = values?.preview_cr;
        const currentCrPosition = previewCR ? CRList.indexOf(previewCR) : 0;
        const currentCrIsFirstPosition = currentCrPosition === 0;

        const goToPrev = () => {
            if (!currentCrIsFirstPosition) {
                const prevCr = CRList[currentCrPosition - 1];
                if (prevCr) {
                    onChange(prevCr)
                }
            }
        }

        useEffect(() => {
            const handle = (e: any) => {
                if (e.key === 'ArrowLeft') {
                    goToPrev();
                }
            };

            window.addEventListener('keydown', handle)
            return () => window.removeEventListener('keydown', handle)
            //eslint-disable-next-line
        }, [])

        return !currentCrIsFirstPosition ? <IconButton onClick={goToPrev} sx={{ mr: 1 }}><ArrowLeft fontSize="small" /></IconButton> : <></>
    }


    const PreviewNextCR = ({ onChange }: any) => {
        const { values }: any = useFormikContext();
        const CRList = values?.devices.map((device: Device) => device.module_address);
        const previewCR = values?.preview_cr;
        const currentCrPosition = previewCR ? CRList.indexOf(previewCR) : 0;
        const currentCrIsLastPosition = currentCrPosition + 1 === CRList.length;

        const goToNext = () => {
            if (!currentCrIsLastPosition) {
                const nextCr = CRList[currentCrPosition + 1];
                if (nextCr) {
                    onChange(nextCr);
                }
            }
        }

        useEffect(() => {
            const handle = (e: any) => {
                if (e.key === 'ArrowRight') {
                    goToNext();
                }
            };

            window.addEventListener('keydown', handle)
            return () => window.removeEventListener('keydown', handle)
            //eslint-disable-next-line
        }, [])

        return !currentCrIsLastPosition ? <IconButton onClick={goToNext} sx={{ ml: 1 }}><ArrowRight fontSize="small" /></IconButton> : <></>
    }

    const computeDiff = async (sourceFile: string, newFile: string) => {
        try {
            const responseDiff: any = await Axios.post('/api/cr-plus/compute-diff/', {
                source_file: sourceFile,
                new_file: newFile
            });
            return responseDiff?.data?.actions
        } catch (e: any) {
            notif({
                type: 'error',
                content: 'Erreur lors du calcul du diff' + e?.response?.data?.error ?? ''
            })
            throw e;
        }
    }

    const getPreviewDiff = (moduleAddress: string, values: FormikValues): Promise<{ preview: string; actions: string }> => new Promise(async (resolve, reject) => {
        const sourceFile = values?.source_file
        const newFile = values?.new_file;
        const fileName = values?.filename?.value;
        let actions = values?.actions;

        if (sourceFile && newFile) {
            setPreviewIsLoading(true);
            if (!actions) {
                try {
                    actions = await computeDiff(sourceFile, newFile);
                } catch (e: any) {
                    reject(e?.response?.data?.error ?? 'Erreur lors du calcul du diff');
                }
            }

            if (actions) {
                try {
                    const previewDiffResponse: any = await Axios.post('/api/cr-plus/preview-diff/', {
                        module_address: moduleAddress,
                        filename: fileName,
                        actions
                    });
                    resolve({ preview: previewDiffResponse?.data?.config_preview, actions })
                } catch (e: any) {
                    reject(e?.response?.data?.error ?? 'Erreur lors de la prévisualisation');
                }

                setPreviewIsLoading(false)
            } else {
                setPreviewIsLoading(false)
                resolve({ preview: "", actions: "" });
            }
        } else {
            reject('Fichier source manquant');
        }
    })

    return <>
        <Formik
            initialValues={{ devicesString: "", type: 'DIFF' }}
            enableReinitialize={true}
            onSubmit={async (values, { setSubmitting }: FormikHelpers<any>) => {
                setIsLoading(true);
                let actions = values?.actions ?? "";

                if (!actions) {
                    try {
                        actions = await computeDiff(values?.source_file, values?.new_file);
                    } catch (err) {
                        notif({
                            type: "error",
                            content: "Impossible d'appliquer ces modifications"
                        });
                        return;
                    } finally {
                        setIsLoading(false);
                    }
                }


                const payload = {
                    "type": values.type,
                    "devices": values.devices.map((device: Device) => { return device.module_address }),
                    "filename": values.filename?.value,
                    actions,
                    "original_file": values?.new_file,
                    "send_sms": values.send_sms ?? false
                };

                if (actions === "") {
                    notif({
                        type: "warning",
                        content: "Aucune modification sur la configuration n'a été détectée, par conséquent l'opération a été annulée"
                    });
                    return;
                }
                try {
                    await Axios.post('/api/cr-plus/config-updates-campaign/', payload);
                    notif({
                        content: "La nouvelle configuration a bien été mise à jour",
                        type: "success",
                    });
                    setTimeout(() => {
                        navigate("/configuration-cr/");
                    }, 2000);
                } catch (err: any) {
                    notif({
                        content: "Une erreur est survenue lors de la mise à jour:<br />" + err?.response?.data?.error,
                        type: "error",
                    })
                } finally {
                    setIsLoading(false)
                }
            }}
            validateOnBlur={false}
        >
            {({
                values,
                getFieldProps,
                setFieldValue,
                setFieldError,
                errors
            }: any) => {
                const resetConfig = () => {
                    // setFieldValue('source_file', null);
                    // setFieldValue('new_file', null);
                    // setFieldValue('send_sms', null);
                    // setFieldError('config', '');
                }

                const previewDiff = async (cr: string) => {
                    setFieldValue('preview_cr', cr);
                    try {
                        const { preview, actions } = await getPreviewDiff(cr, values);
                        setFieldValue('preview_cr_result', preview)
                        setFieldValue('actions', actions)
                    } catch (e) {
                        if (typeof e === "string") {
                            setFieldError('preview_cr_result', e)
                        }
                    }
                }
                return (
                    <AppPaper>
                        <Form noValidate>
                            <StyledTitle>Nouvelle modification</StyledTitle>
                            <AppPaper>
                                <h3>Étape 1 : rechercher dans l'historique</h3>
                                <Grid container>
                                    <Grid item xs={4}>
                                        Liste des CR cibles
                                    </Grid>
                                    <Grid item xs={8}>
                                        <TextField
                                            type={"textarea"}
                                            multiline
                                            rows={4}
                                            fullWidth
                                            placeholder="532204C61322000E"
                                            {...getFieldProps("devicesString")}
                                            error={errors && typeof errors['devices'] !== "undefined"}
                                            helperText={errors && errors['devices']}
                                        />
                                    </Grid>
                                </Grid>

                                <Box sx={{ textAlign: 'center', m: 2 }}>
                                    <SearchDevices onChange={(devices) => {
                                        resetConfig();
                                        setFieldValue('filename', null);
                                        setFieldValue('devices', devices);

                                    }} onError={(error: any) => {
                                        resetConfig();
                                        setFieldValue('filename', null);
                                        setTimeout(() => {
                                            setFieldError('devices', error);
                                        })

                                    }} />
                                </Box>

                                {values?.devices && values?.devices.length > 0 && (
                                    <Grid container>
                                        <Grid item xs={4}>
                                            Fichier à modifier
                                        </Grid>
                                        <Grid item xs={8}>
                                            <SelectFileName
                                                onError={(error: any) => {
                                                    resetConfig();
                                                    setTimeout(() => {
                                                        setFieldError('config', error)
                                                    })
                                                    setShowTemplateSelection(true);
                                                    setFieldValue('source_file', '');
                                                    setFieldValue('new_file', '');
                                                }}
                                                onChange={(config: any) => {
                                                    setShowTemplateSelection(false);
                                                    setFieldValue('source_file', config?.config);
                                                    setFieldValue('new_file', config?.config);
                                                    setFieldValue('actions', '')
                                                    setFieldError('config', '');
                                                }} />
                                        </Grid>
                                    </Grid>
                                )}
                            </AppPaper>
                            {
                                values?.filename && <>
                                    <AppPaper sx={{ mt: 1 }}>
                                        <h3>Étape 2 : résumé équipement cible</h3>
                                        <TableContainer component={Paper}>
                                            <Table size="small">
                                                <TableHead>
                                                    <StyledTableHeaderRow>
                                                        <TableCell>CR</TableCell>
                                                        <TableCell>Date fichier source</TableCell>
                                                    </StyledTableHeaderRow>
                                                </TableHead>
                                                <TableBody>
                                                    {values?.devices?.map((device: Device) => {
                                                        return <StyledTableBodyRow key={device.module_address}><TableCell>{device.module_address}</TableCell>
                                                            <TableCell>{moment(device.source_file_date).format(
                                                                "DD/MM/YYYY à HH:mm:ss"
                                                            )}</TableCell>
                                                        </StyledTableBodyRow>
                                                    })}
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                    </AppPaper>
                                    <AppPaper sx={{ mt: 1 }}>
                                        <h3>Étape 3: édition fichiers globaux</h3>
                                        <>
                                            {showTemplateSelection && <>
                                                {errors?.config && <Alert severity="error">{errors?.config}</Alert>}

                                                <Grid container spacing={1} sx={{ mt: 1 }}>
                                                    <Grid item xs={6}>Vous pouvez choisir un template à partir duquel créer une nouvelle configuration pour les modules ci-dessus</Grid>
                                                    <Grid item xs={6}>
                                                        <AutocompleteField
                                                            field={{
                                                                name: "template",
                                                                label: "Templates disponibles",
                                                                options: {
                                                                    source: "/api/cr-plus/get-template-config-list/",
                                                                    acceptEmptyInput: true,
                                                                    formatResponse: (response: any) => {
                                                                        return { value: response.name, label: response.name }
                                                                    },
                                                                    resultsField: 'templates',
                                                                    searchIsMulti: false
                                                                },
                                                            } as FormField}
                                                            onSelect={(selectedTemplate: any) => {
                                                                setShowPreviewMode(false);
                                                                setFieldValue("template", selectedTemplate);
                                                                // setFieldValue('filename', selectedTemplate)
                                                                Axios.get('/api/cr-plus/get-template-config/?name=' + selectedTemplate?.value).then((response: any) => {
                                                                    setFieldValue('source_file', response.data);
                                                                    setFieldValue('new_file', response.data);
                                                                    setFieldValue('actions', '')
                                                                    refreshEditor();
                                                                })
                                                            }
                                                            }
                                                            value={values?.template || null}
                                                        />
                                                    </Grid>
                                                </Grid>
                                            </>}

                                            {values?.source_file && <>
                                                <RadioGroup
                                                    row
                                                    {...getFieldProps("type")}
                                                    onChange={(e) => {
                                                        setFieldValue("type", e.target.value);
                                                    }}
                                                    value={values['type']}
                                                >
                                                    <FormControlLabel value="DIFF" control={<Radio />} label="Différentiel"
                                                        sx={{ '& .MuiSvgIcon-root': { fontSize: 18 }, '& .MuiFormControlLabel-label': { fontSize: '15px' } }} />
                                                    <FormControlLabel value="REPLACE" control={<Radio />} label="Remplacement complet"
                                                        sx={{ '& .MuiSvgIcon-root': { fontSize: 18 }, '& .MuiFormControlLabel-label': { fontSize: '15px' } }} />
                                                </RadioGroup>
                                                {values?.type === 'DIFF' ? <>
                                                    <Button size="small" variant="outlined" onClick={async () => {
                                                        setShowPreviewMode(!showPreviewMode);
                                                        if (!showPreviewMode) {
                                                            const firstModuleAddress = values?.devices[0]?.module_address;
                                                            await previewDiff(firstModuleAddress);
                                                        }
                                                    }} sx={{ mb: 2 }}
                                                    >Comparer avec le(s) fichier(s) source(s)</Button>
                                                    {showPreviewMode ? <>
                                                        <div style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
                                                            <PreviewPrevCR onChange={async (cr: string) => {
                                                                await previewDiff(cr)
                                                            }} />
                                                            <div style={{ width: '300px' }}>
                                                                <Select
                                                                    options={values?.devices ? values.devices.map((device: Device) => { return { value: device.module_address, label: device.module_address } }) : []}
                                                                    onChange={async (selectedValue: any) => {
                                                                        const moduleAddress = selectedValue.value;
                                                                        await previewDiff(moduleAddress);
                                                                    }}
                                                                    value={values?.preview_cr ? { value: values.preview_cr, label: values.preview_cr } : null}
                                                                    loadingMessage={() => "Chargement en cours..."}
                                                                    noOptionsMessage={() => "Aucune valeur à sélectionner"}
                                                                    placeholder="Choisir un CR à prévisualiser"
                                                                />
                                                            </div>
                                                            <PreviewNextCR onChange={async (cr: string) => {
                                                                await previewDiff(cr)
                                                            }} />
                                                        </div>

                                                        {previewIsLoading ?
                                                            <Loader /> :
                                                            <>
                                                                {values?.source_file && values?.preview_cr_result ? <>
                                                                    <div className="diffeditor">
                                                                        <div>Fichier source</div>
                                                                        <div>Fichier modifié</div>
                                                                    </div>
                                                                    <DiffEditor
                                                                        height="60vh"
                                                                        original={values?.source_file}
                                                                        modified={values?.preview_cr_result}
                                                                        originalLanguage="xml"
                                                                        modifiedLanguage="xml"
                                                                    />
                                                                </> : <>
                                                                    {errors?.preview_cr_result ?
                                                                        <Alert severity="error">Erreur lors de la prévisualisation: {errors?.preview_cr_result}</Alert> :
                                                                        <Alert severity="info">Aucun diff à afficher pour ce CR</Alert>}
                                                                </>}
                                                            </>
                                                        }
                                                    </> :
                                                        <Editor
                                                            height="60vh"
                                                            defaultLanguage="xml"
                                                            defaultValue={values?.new_file}
                                                            value={values?.new_file}
                                                            onChange={(value: string | undefined) => {
                                                                setFieldValue('new_file', value);
                                                                setFieldValue('actions', '')
                                                            }}
                                                        />}
                                                </> :
                                                    <Editor
                                                        height="60vh"
                                                        defaultLanguage="xml"
                                                        defaultValue={values?.new_file}
                                                        value={values?.new_file}
                                                        onChange={(value: string | undefined) => {
                                                            setFieldValue('new_file', value);
                                                            setFieldValue('actions', '')
                                                        }}
                                                    />
                                                }

                                                <Grid container spacing={1} alignItems={'center'}>
                                                    <Grid item xs={2}>
                                                        Envoyer un sms de réveil
                                                    </Grid>
                                                    <Grid item xs={10}>
                                                        <Checkbox
                                                            onChange={(e: any) => {
                                                                setFieldValue('send_sms', e.target.checked)
                                                            }}
                                                            inputProps={{ 'aria-label': 'controlled' }}
                                                        />
                                                    </Grid>
                                                </Grid>
                                            </>}
                                        </>
                                    </AppPaper>

                                    <Box sx={{ textAlign: 'center' }}>
                                        <Button variant="contained" type="submit" disabled={isLoading} sx={{ mt: 1 }}>Modifier</Button>
                                    </Box>
                                </>
                            }
                        </Form>
                    </AppPaper>
                )
            }}
        </Formik>
        <BirdzNotif options={notifOptions} />
    </>
};

export default ConfigurationCrForm;
