import { BirdzDialog, useDialog, AppPaper, BirdzTitle, useNotif, BirdzNotif } from '@applications-terrains/birdz-react-library';
import { Link, useNavigate } from "react-router-dom";
import moment from "moment";
import dataService from "../Common/Services/dataService";
import Axios from "axios";
import { DeviceType } from "../types";
import { authService } from "../..";
import { Button, Grid, IconButton } from "@mui/material";
import { AddCircle, ContentCopy, Delete, Edit } from "@mui/icons-material";
import ListPageWrapper from '../Tools/ListPageWrapper';

interface ModelsProps {
  action: "list" | "add" | "edit";
}

const Models = ({ action }: ModelsProps) => {
  const endpoint = "/api/frame-templates/";
  const navigate = useNavigate();
  const { confirmDialog, closeDialog, dialogOptions } = useDialog();
  const { notif, notifOptions } = useNotif();

  const copyModel = (model: any) => {
    confirmDialog({
      title: "Dupliquer un modèle",
      content: "Souhaitez-vous vraiment copier ce modèle?",
      onValidate: () => {
        Axios.get("/api/frame-templates/" + model.id + "/").then((response) => {
          const modelToCopy = Object.assign({}, response.data);
          modelToCopy.name += " (copie)";
          delete modelToCopy.id;
          Axios.post("/api/frame-templates/", modelToCopy).then(
            (copiedModel) => {
              notif({
                content:
                  "Le modèle `" +
                  response.data.name +
                  "` a bien été copié. Vous allez être redirigé sur la page d'édition",
                type: "success",
              });

              setTimeout(() => {
                navigate("/models/edit/" + copiedModel.data.id);
              }, 2000);
            }
          );
        });
      },
      onCancel: () => {
        closeDialog();
      }
    });
  }
  const listFields = [
    { name: "id", label: "Id", options: { width: "30px" } },
    { name: "name", label: "Nom", orderable: true },
    {
      name: "device_types",
      label: "Matériel",
      options: { width: "150px" },
      orderable: true,
      orderFieldName: 'device_types_count',
      transform: (values: number[]) => {
        const dataServiceDeviceTypes = dataService.getData("deviceTypes") as DeviceType[];
        const deviceTypes = values.map((value) => {
          const deviceType = dataServiceDeviceTypes.find(
            (dataServiceDeviceType) => dataServiceDeviceType.id === value
          );
          return deviceType?.name || value;
        });
        return deviceTypes.join(", ");
      },
    },
    {
      name: "protocol",
      label: "Protocol",
      options: { width: "150px" },
      orderable: true,
      transform: (value: any) => {
        const protocol = dataService
          .getData("protocols")
          .find(
            (dataServiceProtocol: any) => dataServiceProtocol.id === value
          );
        return protocol?.name || "";
      },
    },
    {
      name: "type",
      label: "Type",
      orderable: true,
      transform: (value: any) => {
        return value.substring(0, 1).toUpperCase() + value.substring(1);
      },
      options: { width: "100px" },
    },
    {
      name: "updated_at",
      type: "readonly",
      label: "Dernière modification",
      orderable: true,
      transform: (value: any) => {
        const date =
          value && moment.unix(value).format("DD/MM/YYYY à HH:mm:ss");
        return date || "";
      },
    },
  ];

  const searchFields = [
    {
      name: "name",
      label: "Nom",
      options: {
        identifier: "name",
        source: endpoint,
        label: "name",
      }
    },
    {
      name: "device_types",
      label: "Type de matériel",
      options: {
        source: "/api/device-types/?ordering=name&",
        searchIsMulti: false,
      }
    },
    {
      name: "protocol",
      label: "Protocol",
      options: {
        source: "/api/protocols/?ordering=name&",
        searchIsMulti: true,
      }
    }
  ];

  const actions = [
    {
      name: "edit",
      render: (value: any) => {
        return <IconButton component={Link} to={`/models/edit/${value.id}`}>
          <Edit fontSize="small" />
        </IconButton>
      }
    },
    {
      name: "copy",
      render: (model: any) => {
        return <IconButton onClick={() => {
          copyModel(model);
        }}>
          <ContentCopy fontSize="small" />
        </IconButton>
      }
    },
    {
      name: "delete-model",
      render: (model: any) => {
        return <IconButton onClick={() => {
          confirmDialog({
            title: "Supprimer le modèle",
            content: "Êtes-vous sûr de vouloir supprimer ce modèle?",
            onValidate: () => {
              Axios.delete(`${endpoint}${model.id}/`).then(
                () => {
                  notif({
                    content: "Le modèle a été supprimé avec succès",
                    type: "success",
                  });
                  document.location.reload();
                },
                () => {
                  notif({
                    content: "Une erreur est survenue lors de la suppression",
                    type: "error",
                  });
                }
              )
              closeDialog();
            },
            onCancel: () => {
              closeDialog();
            }
          })
        }}><Delete fontSize="small" /></IconButton>
      }
    },
  ];

  return (
    <AppPaper>
      {action === "list" && (
        <>
          <Grid justifyContent="space-between" container>
            <Grid item>
              <BirdzTitle>Récapitulatif des modèles</BirdzTitle>
            </Grid>
            {authService.canAccess("WRITE_PARAMETERS") && (
              <Grid item>
                <Link to="/models/add">
                  <Button variant="contained" sx={{ mt: 3 }}>
                    <AddCircle sx={{ mr: 1 }} /> Ajouter un modèle
                  </Button>
                </Link>
              </Grid>
            )}
          </Grid>

          <ListPageWrapper
            endpoint={endpoint}
            fields={listFields}
            actions={authService.canAccess("WRITE_TEMPLATE") ? actions : []}
            searchFields={searchFields}
          />
          <BirdzDialog options={dialogOptions} />
        </>
      )}
      <BirdzNotif options={notifOptions} />
    </AppPaper>
  );
};

export default Models;