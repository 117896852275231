import { useState } from 'react';
import { campaignService } from '../CampaignService';
import CampaignDeviceErrors from '../CampaignDeviceErrors';
import CampaignDeviceWarning from '../CampaignDeviceWarning';
import { CampaignType, Device } from '../../types';
import CampaignDevicesString from './CampaignDevicesString';
import CampaignDevicesValidationButton from './CampaignDevicesValidationButton';

type CampaignUploadDevicesListProps = {
    onChange(devices: Device[], devicesString: string, operator?: number): void; // if only one operator in devicesList, return it
    campaignType: CampaignType;
    operatorId?: number;
};

const csvSeparator = ';';

export const CampaignUploadDevicesList = ({
    onChange,
    campaignType,
    operatorId
}: CampaignUploadDevicesListProps) => {
    const [errorDevices, setErrorDevices] = useState<string[]>();
    const [warningDevices, setWarningDevices] = useState<string[]>();
    const [devicesString, setDevicesString] = useState<string>('');
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const checkValidityCSV = (devicesString: string) => {
        const csvLines: string[] = devicesString ? devicesString.split('\n') : [];
        let errors: string[] = [];
        if (csvLines.length <= 1) {
            errors.push(`Vous devez spécifier l'entête ainsi qu'au moins une ligne d'équipements`);
        }
        const csvHeader = csvLines.shift();
        if (csvHeader) {
            const csvHeaderColumns = csvHeader
                .split(csvSeparator)
                .map((column: string) => column.trim());
            const csvHeaderColumnsLength = csvHeaderColumns.length;
            csvLines.forEach((csvLine: string, index: number) => {
                const csvBodyColumns = csvLine.split(csvSeparator);
                const csvBodyColumnsLength = csvBodyColumns.length;
                if (csvLine.trim() === '') {
                    return;
                }
                if (csvBodyColumnsLength !== csvHeaderColumnsLength) {
                    errors.push(
                        `Il manque des colonnes sur la ligne ${index + 1} '${csvLine}' (${csvHeaderColumnsLength} colonne${csvHeaderColumnsLength > 1 ? 's' : ''} dans les headers, ${csvBodyColumnsLength} dans cette ligne)`
                    );
                }

                if (campaignType === 'affiliation') {
                    const affiliateColumnIndex = csvHeaderColumns.indexOf('affiliate');
                    const affiliateDevice = csvBodyColumns[affiliateColumnIndex] || '';
                    if (
                        affiliateColumnIndex !== -1 &&
                        !checkModuleString(csvBodyColumns[affiliateColumnIndex])
                    ) {
                        errors.push(
                            `Le device affilié de la ligne ${index + 1} (reçu: '${affiliateDevice}') doit avoir exactement 16 caractères hexadécimaux`
                        );
                    }
                }
            });
        }

        return errors;
    };

    const checkModuleString = (module: string) => {
        return /^([0-9a-fA-F]){16}$/.test(module);
    };

    const checkDevices = () => {
        const errors = checkValidityCSV(devicesString);
        if (errors.length > 0) {
            setErrorDevices(errors);
            return;
        }

        setIsLoading(true);
        campaignService
            .checkDevices({
                devices_text: devicesString,
                campaign_type: campaignType,
                operator: operatorId
            })
            .then(
                ([devices, warningDevices]) => {
                    const devicesOperators = devices
                        .map((device) => device.operator)
                        .filter((operator) => operator);
                    let operator;
                    if (devicesOperators.length === 1) {
                        operator = devicesOperators[0];
                    }
                    onChange(devices, devicesString, operator);
                    setErrorDevices(undefined);
                    setWarningDevices(warningDevices);
                },
                (errors: string[]) => {
                    setErrorDevices(errors);
                    setWarningDevices(undefined);
                    onChange([], '');
                }
            )
            .finally(() => {
                setIsLoading(false);
            });
    };

    return (
        <>
            <CampaignDevicesString
                onChange={(devicesString: string) => {
                    setDevicesString(devicesString);
                }}
                operatorId={operatorId}
                campaignType={campaignType}
            />

            <CampaignDeviceErrors errors={errorDevices} />
            <CampaignDeviceWarning warnings={warningDevices} />

            <CampaignDevicesValidationButton
                disabled={!devicesString || isLoading}
                onClick={() => checkDevices()}
                isLoading={isLoading}
            />
        </>
    );
};
