import * as React from 'react';
import { useState, useEffect } from 'react';
import { Operator, Protocol } from '../../types';
import { CampaignFrameTemplate } from '../CampaignFrameTemplate';
import dataService from '../../Common/Services/dataService';
import { Device } from '../../types';
import _ from "lodash";
import { Alert } from '@mui/material';
import { ScheduleOptions } from '../../Common/ScheduleOptions/ScheduleOptions';

type CampaignMultiProtocolsProps = {
    devices: Device[];
    type: string;
    hasCombinedAddress?: boolean;
    onChange(value: any): void
}

type DeviceTypeByProtocol = {
    [operatorId: number]: number[]
}

type CampaignValues = {
    [operatorId: number]: any
}

const CampaignMultiProtocols = ({ devices, onChange, type, hasCombinedAddress }: CampaignMultiProtocolsProps) => {
    const [deviceTypesByProtocol, setDevicesTypesByProtocol] = useState<DeviceTypeByProtocol>();
    const [protocols, setProtocols] = useState<Protocol[]>([]);
    const [operators, setOperators] = useState<Operator[]>([]);
    const [campaignValues, setCampaignValues] = useState<CampaignValues>();

    useEffect(() => {
        let _deviceTypesByProtocol: DeviceTypeByProtocol = {};

        devices.forEach((device) => {
            // const operatorId: number = device?.operator;
            const protocolId: number = device?.protocol;
            const deviceType: number = device?.device_type;
            if (protocolId && deviceType) {
                _deviceTypesByProtocol[protocolId] = _deviceTypesByProtocol[protocolId] || [];
                if (!_deviceTypesByProtocol[protocolId].includes(deviceType)) {
                    _deviceTypesByProtocol[protocolId].push(deviceType);
                }

            }
        })
        setDevicesTypesByProtocol(_deviceTypesByProtocol);

        const newProtocol: Protocol[] = [];
        _.uniq(devices.map(device => device?.protocol).filter((protocolId: number | undefined) => protocolId !== undefined)).forEach((protocolId: number) => {
            newProtocol.push(dataService.getProtocol(protocolId));
        });
        setProtocols(newProtocol);

        const newOperator: Operator[] = [];
        _.uniq(devices.map(device => device?.operator).filter((operatorId: number | undefined) => operatorId !== undefined)).forEach((operatorId: number) => {
            newOperator.push(dataService.getOperator(operatorId));
        });
        setOperators(newOperator);

    }, [devices]);

    useEffect(() => {
        onChange(campaignValues);
        //eslint-disable-next-line
    }, [campaignValues])

    const updateValue = (operatorId: number, value: any) => {
        const initialValue = campaignValues && campaignValues[operatorId] ? Object.assign(campaignValues[operatorId]) : {};
        let campaignValue = Object.assign(initialValue, value);
        setCampaignValues({
            ...campaignValues,
            ...{ [operatorId]: campaignValue }
        });
    }

    return (
        <>
            {protocols && protocols.length > 0 && <>
                {protocols.map(protocol => (
                    <div className="block" key={protocol.id}>
                        <strong>{protocol.name}</strong>

                        <CampaignFrameTemplate
                            onChange={(selectedFrameTemplate: any) => {
                                updateValue(protocol.id, { frame_template: selectedFrameTemplate })
                            }}
                            type={type}
                            protocolId={protocol.id}
                            device_types={deviceTypesByProtocol && deviceTypesByProtocol[protocol.id] ? deviceTypesByProtocol[protocol.id] : undefined}
                            hasCombinedAddress={hasCombinedAddress}
                        />

                        <ScheduleOptions
                            onChange={(campaignType: any) => {
                                updateValue(protocol.id, { schedule: { scheduled_date: campaignType.scheduledDate, sending_schedule: campaignType.type } })
                            }}
                            value={campaignValues && campaignValues[protocol.id] && campaignValues[protocol.id].schedule ? {
                                type: campaignValues[protocol.id].schedule?.sending_schedule,
                                scheduledDate: campaignValues[protocol.id].schedule?.scheduled_date
                            } : {
                                type: undefined,
                                scheduledDate: undefined,
                            }}
                            protocolId={protocol.id}
                        />
                    </div>
                ))}
                <Alert severity="info">{operators.length} campagnes seront crées pour les opérateurs suivants: {operators.map(operator => operator.name).join(', ')}</Alert>
            </>}
        </>
    )
}

export default CampaignMultiProtocols;