import { SearchField, SearchFormValue } from '@applications-terrains/birdz-react-library';

import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import PurposesList from './PurposesList';
import {
    commonFilters,
    searchFields,
    getAvailableSearchFilters,
    purposeFilters
} from '../search.config';
import { SearchFilters } from '../search.service';
import { useTranslation } from 'react-i18next';
import dataService from '../../../Common/Services/dataService';
import moment from 'moment';
import SearchFormWithDatePickerRange from '../../../Tools/SearchFormWithDatePickerRange';
import useConvertedDatesForFilters from '../../../../hooks/useConvertedDatesForFilters';
import useReinitializeForm from '../../../../hooks/useReinitializeForm';

type SearchPurposesProps = {
    filters: SearchFormValue;
    onFiltersChange(searchFilters: SearchFormValue): void;
};

function useQuery() {
    return new URLSearchParams(useLocation().search);
}

const SearchPurposes = ({ filters, onFiltersChange }: SearchPurposesProps) => {
    const [filtersValues, setFiltersValues] = useState<SearchFormValue>({
        formValue: {},
        filterValues: {}
    });
    const [filtersString, setFiltersString] = useState<string>('');
    const [searchFilters] = useState(new SearchFilters());
    const [purposesSearchFields, setPurposesSearchFields] = useState<SearchField[]>([]);
    const { t } = useTranslation();
    const query = useQuery();
    const { search } = useLocation();
    const navigate = useNavigate();
    const [countHandleSearch, setCountHandleSearch] = useState<number>(0);

    useConvertedDatesForFilters({ filters, setFiltersString });

    const reset = useReinitializeForm();

    const getCampaignQuery = () => {
        return query.get('campaign');
    };

    const handleSearchFields = () => {
        let purposesSearchFields = [...searchFields];

        // Rename common fields
        purposesSearchFields = purposesSearchFields.map((searchField) => {
            if (
                commonFilters.includes(searchField.name) &&
                purposeFilters.indexOf(searchField.name) === -1
            ) {
                searchField.name = 'campaign__' + searchField.name;
            }
            return searchField;
        });

        // Add specific purposes search fields
        const availableSearchFilters = getAvailableSearchFilters(t);
        purposesSearchFields.unshift(
            availableSearchFilters['purposeState'],
            availableSearchFilters['nackCause']
        );

        // const campaignNameFilterPosition = purposesSearchFields
        //     .map((searchField) => searchField.name)
        //     .indexOf('campaign__name');

        // purposesSearchFields.splice(
        //     campaignNameFilterPosition + 1,
        //     0,
        //     availableSearchFilters['campaignId']
        // );

        const purposeCreatedByPosition = purposesSearchFields
            .map((searchField) => searchField.name)
            .indexOf('campaign__created_by__in');
        purposesSearchFields.splice(
            purposeCreatedByPosition + 1,
            0,
            availableSearchFilters['purposeDevice']
        );

        // State is common to two tabs but it's not the same filter
        const deviceAddressFilterPosition = purposesSearchFields
            .map((searchField) => searchField.name)
            .indexOf('device__address');
        let campaignStateFilter = Object.assign({}, availableSearchFilters['campaignState'], {
            name: 'campaign__state__in'
        });
        purposesSearchFields.splice(deviceAddressFilterPosition + 1, 0, campaignStateFilter);
        purposesSearchFields.splice(
            deviceAddressFilterPosition + 2,
            0,
            availableSearchFilters['txreject__reject_id__in']
        );
        purposesSearchFields.push(availableSearchFilters['purposeId']);
        setPurposesSearchFields(purposesSearchFields);
        setCountHandleSearch((count) => count + 1);
    };

    const handleSearchFilters = () => {
        searchFilters.reset();

        if (countHandleSearch === 0 && !search.includes('&campaign=')) {
            const oneMonthAgo = new Date(moment().add(-1, 'months').format('YYYY-MM-DD'));
            onFiltersChange({
                formValue: {
                    campaign__created_at_after:
                        filters.filterValues.created_at_after || oneMonthAgo,
                    campaign__created_at_before: filters.filterValues.created_at_before,
                    campaign__state__in: filters.formValue.state__in
                },
                filterValues: {
                    campaign__created_at_after:
                        filters.filterValues.created_at_after || oneMonthAgo,
                    campaign__created_at_before: filters.filterValues.created_at_before,
                    campaign__state__in: filters.filterValues.state__in
                }
            });
            searchFilters.add({
                name: 'campaign__created_at_after',
                value: filters.filterValues.created_at_after || oneMonthAgo
            });
            filters.filterValues.created_at_before &&
                searchFilters.add({
                    name: 'campaign__created_at_before',
                    value: filters.filterValues.created_at_before
                });
        } else if (search.includes('&campaign=')) {
            onFiltersChange({
                formValue: {},
                filterValues: {}
            });
            searchFilters.remove('campaign__created_at_after');
            searchFilters.remove('campaign__created_at_before');
        }

        // Add campaign filters
        const campaignId = getCampaignQuery();

        if (campaignId) {
            searchFilters.add({
                name: 'campaign',
                value: campaignId
            });
        }
        // Rename filters
        if (filters?.formValue && Object.keys(filters.formValue).length) {
            searchFilters.formatFiltersFromSearchForm(filters.formValue);

            //Filters keys that are the same between campaings and purposes, so we need to keep them untouched (and not prefix them with campaign__)
            const purposesFiltersToKeep = ['state__in', 'created_at_before', 'created_at_after'];

            searchFilters.filters.forEach((filter) => {
                if (
                    !purposesFiltersToKeep.includes(filter.name) &&
                    commonFilters.includes(filter.name)
                ) {
                    searchFilters.rename(filter.name, 'campaign__' + filter.name);
                }
            });
        }

        setFiltersValues(searchFilters.formatToSearchForm());
        setCountHandleSearch((count) => count + 1);
        //eslint-disable-next-line
    };

    useEffect(() => {
        const init = async () => {
            if (!dataService.dataStoreInDataService('nackCauses')) {
                dataService.dataToLoad.push({
                    name: 'nackCauses',
                    endpoint: '/api/nack-causes/'
                });
                await dataService.refreshData('nackCauses');
            }
            setCountHandleSearch(0);
            handleSearchFields();
            handleSearchFilters();
        };
        init();

        //eslint-disable-next-line
    }, [filters]);

    useEffect(() => {
        if (reset) {
            onFiltersChange({
                formValue: {},
                filterValues: {}
            });
            search.includes('&campaign=') && navigate(`/sent-list?tab=purposes`);
        }
    }, [
        reset,
        search,
        navigate,
        onFiltersChange,
    ]);

    return (
        <>
            <div className="block">
                <SearchFormWithDatePickerRange
                    dateAfterLabel="campaign__created_at_after"
                    dateBeforeLabel="campaign__created_at_before"
                    setFiltersValues={setFiltersValues}
                    filtersValues={filtersValues}
                    fields={purposesSearchFields}
                    setFilters={setFiltersString}
                    onSubmit={(rawFiltersValues: any) => {
                        const formValueCampaignIn = rawFiltersValues?.formValue?.campaign__in;
                        if (formValueCampaignIn === null) {
                            const params = new URLSearchParams(search);
                            params.delete('campaign');
                            // history.push({ search: params.toString() })
                            navigate({ pathname: '', search: params.toString() });
                        } else if (formValueCampaignIn && formValueCampaignIn.length === 1) {
                            const params = new URLSearchParams(search);
                            params.set('campaign', formValueCampaignIn[0]?.value);
                            // history.push({ search: params.toString() })
                            navigate({ pathname: '', search: params.toString() });
                        }
                        // onFiltersChange(rawFiltersValues);
                    }}
                />
            </div>
            {countHandleSearch === 2 && purposesSearchFields.length > 0 && (
                <PurposesList filters={filtersString} />
            )}
        </>
    );
};

export default SearchPurposes;
