import { difference, get } from "lodash";
import dataService from "./dataService";


type Choices = {
    [label: string]: string | number
} | number[];

export type XMetadata = {
    label: string,
    web_type: "text" | "ddl" | "bitmask" | "calendar" | "slider" | "text" | "checkbox" | "radio",
    choices?: Choices,
    min?: number | string, // number or hexa
    max?: number, // number or hexa
    step?: number,
    length?: number,
    random?: boolean,
    mandatory?: boolean // is field is required?
    if?: string // conditionnal fields
}

type EncoderProperties = {
    [propertyName: string]: {
        type: 'number' | 'string',
        format?: 'binary' | 'integer' | 'hexadecimal',
        nullable?: boolean,
        id?: string,
        label?: string,
        properties?: EncoderProperties,
        mandatory?: boolean,
        "x-metadata": XMetadata
    }
}

type EncoderType = 'encoder' | 'ack';

type Encoder = {
    properties: EncoderProperties,
    label: string,
    type: EncoderType,
    encryption?: boolean,
    device_type?: string | string[],
    default_pin?: boolean,
    frame_type?: string,
    protocol?: string,

}

type Encoders = {
    [encoderName: string]: Encoder
};

type EncoderSpecs = {
    paths: Encoders,
    version: "string",
    commit: "string",
    header_config: Encoder
}

type FiltersEncoder = {
    // type?: EncoderType;
    // protocol?:string;
    // device_type?:string;
    [key in 'type' | 'protocol' | 'device_type']?: string | string[]
}

type ConditionalField = [string, '==' | '!=', string | number | boolean];

type GetEncoderResult = {
    id: string;
    label: string;
    encoder: Encoder;
}

class EncoderService {
    private currentEncoder: Encoder | undefined;
    private encoderSpecs: EncoderSpecs | undefined;
    private formatedData: any;

    public constructor() {
        this.encoderSpecs = dataService.getData("encodersSpecs");
    }

    public loadEncoder = (encoderName: string) => {
        //just for test sandbox, remove after
        this.encoderSpecs = dataService.getData("encodersSpecs");

        if (!this.encoderSpecs || !this.encoderSpecs?.paths) {
            return;
        }

        if (encoderName === 'header_config' && this.encoderSpecs?.header_config) {
            this.currentEncoder = this.encoderSpecs?.header_config;
            this.formatData();
        } else if (this.encoderSpecs?.paths[encoderName]) {
            this.currentEncoder = this.encoderSpecs?.paths[encoderName];
            this.formatData();
        }
        return;
    }

    // le format de réponse change de getAllEncoders => à répércuter

    public getEncoders = (filters: FiltersEncoder = {}): GetEncoderResult[] => {
        let encoders: GetEncoderResult[] = [];

        for (let encoderName in this.encoderSpecs?.paths) {
            const encoder = this.encoderSpecs?.paths[encoderName] as Encoder;
            let encoderResult = {
                id: encoderName,
                label: encoder?.label,
                encoder: encoder
            }

            // if no filters
            if (!Object.keys(filters)) {
                encoders.push(encoderResult);
            }
            else {
                let filterIsMatch: boolean = true;
                Object.keys(filters).forEach((filter) => {
                    const filterKey = filter as keyof FiltersEncoder;
                    const filterValue = filters[filterKey];
                    if (filterKey && filterValue && encoder[filterKey] !== undefined) {
                        const filterMatch = (filterValue: string) => {
                            let filterIsMatch = true;
                            if (
                                (typeof encoder[filterKey] === 'string' && encoder[filterKey] !== filterValue) ||
                                (Array.isArray(encoder[filterKey]) && !encoder[filterKey]?.includes(filterValue))
                            ) {
                                filterIsMatch = false;
                            }
                            return filterIsMatch;
                        }

                        // si le filtre demandé est une chaine
                        if (typeof filterValue === 'string') {
                            if (!filterMatch(filterValue)) {
                                filterIsMatch = false;
                            }
                        }
                        // si le filtre demandé est un tableau
                        else if (Array.isArray(filterValue)) {
                            let totalSatisfyedConditions = 0;
                            filterValue.forEach((value => {
                                if (filterMatch(value)) {
                                    totalSatisfyedConditions++;
                                }
                            }));
                            if (totalSatisfyedConditions === 0) {
                                filterIsMatch = false;
                            }
                        }
                    }
                })

                if (filterIsMatch) {
                    encoders.push(encoderResult);
                }
            }
        }

        return encoders;
    }

    public getEncoderHeaderConfig = () => {
        return this.encoderSpecs?.header_config;
    }

    /**
     * 
     * @param property 
     * @param parentIds 
     * 
     * Formate la donnée brute issue de l'encoder specs pour y ajouter une propriété id
     */
    public formatData = (properties: EncoderProperties = {}, parentIds: string[] = []) => {
        let formatedData: any = {};
        this.formatedData = {}; // store in this property for cache

        // First call, no params setted
        if (Object.keys(properties).length === 0 && parentIds.length === 0) {
            if (this.currentEncoder?.properties) {
                formatedData['properties'] = this.formatData(this.currentEncoder?.properties, []);
                formatedData['label'] = this.currentEncoder?.label;
            }
        }
        // Other recursive calls
        else {
            for (let propertyName in properties) {
                const propertyValue = properties[propertyName];
                const metadata = propertyValue['x-metadata'];

                formatedData[propertyName] = propertyValue;

                // Set x-metatada data at the root of item
                if (metadata && Object.keys(metadata).length) {
                    for (let metadataKey in metadata) {
                        formatedData[propertyName][metadataKey] = metadata[metadataKey as keyof XMetadata];
                    }
                }

                // Manage conditional fields
                if (metadata?.if) {
                    formatedData[propertyName]['if'] = this.parseConditionalFields(metadata.if);
                }

                // Standardize "choices" attribute which are array or object
                if (metadata?.choices) {
                    if (Array.isArray(metadata?.choices)) {
                        formatedData[propertyName]['possible_value'] = formatedData[propertyName]['possible_value'] || {};
                        metadata.choices.forEach((choice: string | number) => {
                            formatedData[propertyName]['possible_value'][choice] = choice;
                        })
                    } else {
                        formatedData[propertyName]['possible_value'] = metadata?.choices;
                    }
                }

                // Generate ID
                let newParentIds = [...parentIds, propertyName];
                formatedData[propertyName]['id'] = newParentIds.join('.');

                // Recursive call for nested properties
                if (formatedData[propertyName]?.properties) {
                    formatedData[propertyName].properties = this.formatData(formatedData[propertyName].properties, newParentIds)
                }
            }
        }
        this.formatedData = formatedData;
        return formatedData;
    }

    /**
     * Parse une chaine conditionelle simple (ex: 'Header.Type == 0') ou plus complexe (ex: 'Header.Type == 0 OR Header.Type == 1') 
     * 3 formats de retours possible
     * Condition simple: [['champ_a_comparer','==','valeur_a_comparer']]
     * Condition ET: [['champ_a_comparer','==','valeur_a_comparer'],['champ2_a_comparer','==','valeur2_a_comparer']]
     * Condition OU: [[['champ_a_comparer','==','valeur_a_comparer'],['champ2_a_comparer','==','valeur2_a_comparer']]]
     */
    public parseConditionalFields = (conditionalString: string) => {
        let isComplexCondition = ['OR', 'AND'].some((operand: string) => conditionalString.includes(operand));
        let result: any = [];

        if (isComplexCondition) {
            if (conditionalString.includes('OR')) {
                let splitedConditions = conditionalString.split('OR')
                splitedConditions.forEach(splitedCondition => {
                    result.push(this.parseConditionalField(splitedCondition));
                })
            }
            if (conditionalString.includes('AND')) {
                let splitedConditions = conditionalString.split('AND')
                splitedConditions.forEach(splitedCondition => {
                    result[0] = result[0] || [];
                    result[0].push(this.parseConditionalField(splitedCondition));
                })
            }
        } else {
            result.push(this.parseConditionalField(conditionalString));
        }

        return result;
    }
    /**
     * Parse une string conditionelle issue du codec en un format plus exploitable
     * @param conditionalField : ie: 'Header.Type == 0'
     * @return parsedField ['champ_a_comparer','==','valeur']
     */
    public parseConditionalField = (conditionalString: string): ConditionalField[] => {
        const regexCondition = /([^ ]*)[ ]{0,1}(==|!=){1}[ ]{0,1}['|"]{0,1}([a-zA-Z0-9-/]*)['|"]{0,1}/;

        let result: any = conditionalString.match(regexCondition);
        result?.shift(); // remove 1st item which is equal to conditionalString

        // Cast conditional value
        if (result && result[2]) {
            let testValue = result[2];
            if (["true", "false"].includes(result[2])) {
                result[2] = result[2] === "true";
            }
            if (parseInt(testValue).toString() === testValue) {
                result[2] = parseInt(result[2]);
            }
        }

        return result as Array<any>;
    }


    private isValidCondition = (condition: ConditionalField): boolean => {
        if (condition.length !== 3 || typeof condition[0] !== 'string' || typeof condition[1] !== 'string') {
            return false;
        }
        return true;
    }

    public conditionIsSatisfyed = (condition: any[], dataToTest: any): boolean => {
        let conditionSatisfyed = false;
        let conditionIsComplex = condition[0] instanceof Array;

        if (conditionIsComplex) {
            let andConditionIsSatisfyed = true;
            condition.forEach((subCondition: any) => {
                // OR Condition
                if (this.isValidCondition(subCondition) && this.conditionIsSatisfyed(subCondition, dataToTest)) {
                    conditionSatisfyed = true;
                }
                // AND Condition
                else if (subCondition[0] instanceof Array) {
                    subCondition.forEach((subSubCondition: any) => {
                        if (!this.conditionIsSatisfyed(subSubCondition, dataToTest)) {
                            andConditionIsSatisfyed = false;
                        }
                    });
                    conditionSatisfyed = andConditionIsSatisfyed;
                }
            })
        }
        else {
            let conditionValue = get(dataToTest, condition[0]);
            let conditionTest = condition[1];
            let conditionValueToCompare = condition[2];

            switch (conditionTest) {
                case "==":
                    if (conditionValue === conditionValueToCompare) {
                        conditionSatisfyed = true;
                    }
                    break;
                case "!=":
                    if (conditionValue !== conditionValueToCompare) {
                        conditionSatisfyed = true;
                    }
            }
        }
        return conditionSatisfyed;
    }


    /**
     * Convert string "a.b.c" with value "value" to object a:{b:{c:"value"}}
     * @param key "a.b.c"
     * @param value value with any format
     * @returns 
     */
    public convertSplitNotationInObject = (key: string, value: any) => {
        let i, resultObject: any = {}, splittedKeys: string[] = key.split(".");

        let copyObject = resultObject;
        for (i = 0; i < splittedKeys.length - 1; i++) {
            copyObject = copyObject[splittedKeys[i]] = {};
        }

        copyObject[splittedKeys[i]] = value;
        return resultObject;
    };

    /**
     * Reverse of convertSplitNotationInObject(), convert object a:{b:{c:"value"}} to {"a.b.c":"value"}
     * From  https://stackoverflow.com/a/13218838
     * @param value 
     * @returns 
     */
    public convertNestedObjectToSplitNotation = (objectValue: any) => {
        let result: any = {};
        (function recurse(obj: any, current: any = null) {
            for (let key in obj) {
                let value = obj[key];
                let newKey = current ? current + "." + key : key; // joined key with dot
                if (value && typeof value === "object" && !Array.isArray(value)) {
                    recurse(value, newKey); // it's a nested object, so do it again
                } else {
                    result[newKey] = value; // it's not an object, so set the property
                }
            }
        })(objectValue);
        return result;
    };


    public isObject(item: any) {
        return item && typeof item === "object" && !Array.isArray(item);
    }

    public mergeDeep(target: any, source: any) {
        let output = Object.assign({}, target);
        if (this.isObject(target) && this.isObject(source)) {
            Object.keys(source).forEach((key) => {
                if (this.isObject(source[key])) {
                    if (!(key in target)) Object.assign(output, { [key]: source[key] });
                    else output[key] = this.mergeDeep(target[key], source[key]);
                } else {
                    Object.assign(output, { [key]: source[key] });
                }
            });
        }
        return output;
    }

    /**
     * Transforme l'objet "split notation" dans un format accepté par l'API
     * Ex:
     * {
     *    "a" : "value1"
     *    "b.c" : "value2"
     *    "d" : "value3"
     * }
     * 
     * renverra
     * 
     * {
     *    "a" : "value1",
     *    "b" : {
     *       "c" : {
     *          "value2"
     *       }
     *    },
     *    "d" : "value3"
     * }
     * @param dataToSave 
     * @returns 
     */
    public formatToApi = (dataToSave: any) => {
        let result: any = {};
        for (let prop in dataToSave) {
            if (prop.indexOf(".") !== -1) {
                let obj = this.convertSplitNotationInObject(prop, dataToSave[prop]);
                result = this.mergeDeep(result, obj);
            } else {
                if (result[prop]) {
                    result[prop] = this.mergeDeep(result[prop], dataToSave[prop]);
                } else {
                    result[prop] = dataToSave[prop];
                }
            }
        }
        return result;
    };


    /**
     * Retourne un champ en fonction de id en version notation split
     * @param fieldId ex: prop1.subProp1
     * @param properties 
     * @returns 
     */
    public getFieldById(fieldId: string, properties: EncoderProperties = {}) {
        if (Object.keys(properties).length === 0 && this.currentEncoder?.properties) {
            properties = this.currentEncoder?.properties;
        }

        for (let property in properties) {
            if (properties[property]?.id === fieldId) {
                return properties[property];
            }

            if (properties[property].hasOwnProperty('properties')) {
                let fieldFound: any = this.getFieldById(fieldId, properties[property].properties);
                if (fieldFound) {
                    return fieldFound;
                }
            }
        }

        return null;
    }

    /**
     * Return true if a field or his parent has property "mandatory=false"
     * @param fieldId ex: prop1.subProp1
     * @returns boolean
     */
    public fieldIsOptional(fieldId: string) {
        // by default all fields are required, except those whose mandatory property is false
        return this.fieldHasProperty(fieldId, 'mandatory', false);
    }

    /**
     * Return true if a field or his parent has "if" property
     * @param fieldId ex: prop1.subProp1
     * @returns boolean
     */
    public fieldIsConditional(fieldId: string) {
        // A field is conditional if he (or one of his parent) has field property
        return this.fieldHasProperty(fieldId, 'if');
    }

    /**
     * 
     * @param fieldId  ex: prop1.subProp1
     * @param propertyName ex: mandatory
     * @param propertyValue ex: false
     * @returns 
     */
    public fieldHasProperty(fieldId: string, propertyName: string, propertyValue: any = null) {
        const field = this.getFieldById(fieldId);

        if (!field) {
            return false;
        }

        // if property value is defined, we check if field[propertyName] === property. Else we just check if property if present
        if ((propertyValue && field[propertyName] === propertyValue) || (!propertyValue && field.hasOwnProperty(propertyName))) {
            return true;
        }
        else {
            const splitFieldId = fieldId.split('.');
            splitFieldId.pop();
            const parentFieldId = splitFieldId.join('.');
            if (parentFieldId) {
                if (this.fieldHasProperty(parentFieldId, propertyName, propertyValue)) {
                    return true;
                }
            }
        }

        return false;
    }
    /**
     * Reste à implémenter
     * - getAllFieldsWithProps
     * - getHeaderConfigField
     */
    public getMandatoryFields(mandatoryCheckboxes: string[]) {
        const isMandatoryField = (properties: EncoderProperties, mandatoryCheckboxes: string[], parentIsMandatory: boolean | null = null) => {
            let allFields: string[] = [];
            for (let propertyName in properties) {
                const propertyValue = properties[propertyName];
                const propertyId = propertyValue?.id;

                if (propertyId) {
                    let isMandatory = parentIsMandatory === null ? true : parentIsMandatory;

                    // Item has properties
                    if (propertyValue && propertyValue.properties) {
                        if (propertyValue?.mandatory === false && !mandatoryCheckboxes.includes(propertyId)) {
                            isMandatory = false;
                        }

                        let x: string[] = isMandatoryField(propertyValue.properties, mandatoryCheckboxes, isMandatory);
                        allFields = allFields.concat(x);
                    }
                    // item without properties
                    else {
                        if (propertyValue?.label && isMandatory) {
                            // Champ non facultatif OU champ facultatif dont la checkbox est cochée
                            if (propertyValue?.mandatory !== false || (propertyValue?.mandatory === false && mandatoryCheckboxes.includes(propertyId))) {
                                allFields.push(propertyId);
                            }
                        }
                    }
                }
            }

            return allFields;
        }

        const result = isMandatoryField(this.formatedData.properties, mandatoryCheckboxes)
        return result;
    }


    public getAllFields = (properties: EncoderProperties = {}): string[] => {
        let allFields: string[] = [];

        if (Object.keys(properties).length === 0 && this.formatedData.properties) {
            properties = this.formatedData?.properties;
        }

        for (let propertyName in properties) {
            const propertyValue = properties[propertyName];
            const propertyId = propertyValue?.id;

            if (propertyId) {
                // Item has properties
                if (propertyValue && propertyValue.properties) {

                    let x: string[] = this.getAllFields(propertyValue.properties);
                    allFields = allFields.concat(x);
                }
                // item without properties
                else {
                    if (propertyValue?.label) {
                        allFields.push(propertyId)
                    }
                }
            }
        }

        return allFields;
    }

    public getOptionalFields = (mandatoryCheckboxes: string[]): string[] => {
        const allFields = this.getAllFields();
        const mandatoryFields = this.getMandatoryFields(mandatoryCheckboxes);
        return difference(allFields, mandatoryFields);
    }

    public getFieldsWithSpecificProperty = (property: string, properties: any = {}): string[] => {
        let allFields: string[] = [];

        if (Object.keys(properties).length === 0 && this.formatedData?.properties) {
            properties = this.formatedData.properties;
        }

        for (let propertyName in properties) {
            const propertyValue = properties[propertyName];
            const propertyId = propertyValue?.id;
            if (propertyId) {
                // Item has properties
                if (propertyValue?.[property]) {
                    allFields.push(propertyId)
                }

                if (propertyValue && propertyValue.properties) {

                    let x: string[] = this.getFieldsWithSpecificProperty(property, propertyValue.properties);
                    allFields = allFields.concat(x);
                }
            }
        }

        return allFields;
    }

    /**
     * Décompose un fieldId en un tableau de parents (ex: 'a.b.c.d' renverra ['a','a.b','a.b.c'])
     * @param fieldId 
     * @returns string[] parentIds
     */
    public getAllParentIds = (fieldId: string): string[] => {
        let res: string[] = [];
        let parent = fieldId.split('.');

        if (parent.length > 1) {
            parent.pop();
            res.unshift(parent.join('.'));
            res = this.getAllParentIds(parent.join('.')).concat(res)
        }
        return res;
    }

    /**
     * Permet de savoir si une case à cocher (champ mandatory=false) possède un parent qui a aussi une case à cocher qui serait non cochée
     * De ce fait, la case à cocher en question n'est activable que si la case parente est cochée.
     * @param fieldId 
     * @param mandatoryCheckboxes 
     * @returns 
     */
    public mandatoryCheckboxCanBeCheck = (fieldId: string, mandatoryCheckboxes: string[]) => {
        const allParentIds = this.getAllParentIds(fieldId);

        let canBeCheck = true;
        allParentIds.forEach(parentId => {
            // if a parent is optional (mandatory=false) and it's associated checkbox is not checked
            if ((this.fieldIsOptional(parentId) && !mandatoryCheckboxes.includes(parentId))) {
                canBeCheck = false
            }
        })

        return canBeCheck;
    }


    /**
     * Retourne le nom du champ qui a une propriété header_config dans le codec
     * @returns fieldId 
     */
    public getHeaderConfigField = (): string => {
        const headerConfigFieldName = this.getFieldsWithSpecificProperty('header_config');
        if (headerConfigFieldName.length) {
            return headerConfigFieldName[0];
        }
        return "";
    }
}

export default EncoderService;