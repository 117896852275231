import React from "react";
import "react-datepicker/dist/react-datepicker.css";
import { Chart } from "./Chart/Chart";
import "./Dashboard.scss";
import { Grid } from "@mui/material";

export const Dashboard = () => {
    return (
        <><h2>Dashboard</h2>
            <Grid container className="chart-header">
                <Grid item xs={3}></Grid>
                <Grid item xs={3} className="text-center">90 jours</Grid>
                <Grid item xs={3} className="text-center">30 jours</Grid>
                <Grid item xs={3} className="text-center">7 jours</Grid>
            </Grid>
            <Grid container className="chart-row">
                <Grid item><Chart endpoint={"/api/racsup/purposes-stats/"} title={"Global"} detailsUrl={"/racsup/dashboard-details"} /></Grid>
            </Grid>
            <Grid container className="chart-row">
                <Grid item><Chart endpoint={"/api/racsup/purposes-stats/?type=PRIMO"} title={"Primo-raccordement(s)"} detailsUrl={"/racsup/created-links"} /></Grid>
            </Grid>
            <Grid container className="chart-row">
                <Grid item><Chart endpoint={"/api/racsup/purposes-stats/?type=RERAC"} title={"Re-raccordement(s)"} detailsUrl={"/racsup/changed-links"} /></Grid>
            </Grid>
            <Grid container className="chart-row">
                <Grid item><Chart endpoint={"/api/racsup/purposes-stats/?type=RENFO"} title={"Renforcement(s)"} detailsUrl={"/racsup/appended-links"} /></Grid>
            </Grid>
            <Grid container className="chart-row">
                <Grid item><Chart endpoint={"/api/racsup/purposes-stats/?type=NET"} title={"Nettoyage des répéteurs"} detailsUrl={"/racsup/deleted-links"} /></Grid>
            </Grid>
        </>
    );
};

export default Dashboard;
