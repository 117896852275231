import { ArrowDropDown, ArrowDropUp } from "@mui/icons-material";
import { TableCell } from "@mui/material";
import React, { useEffect, useState } from "react";

export type Sort = {
  field: string;
  way: string;
}
type SortItemsProps = {
  field: string;
  label: string;
  onSort(sort: Sort): void;
  currentSort: Sort | undefined | null
};

export default function SortItems({
  field,
  label,
  onSort,
  currentSort
}: SortItemsProps) {
  const [sortField, setSortField] = useState<string>("");
  const [sortWay, setSortWay] = useState<string>('');

  const sorting = (column: string) => {
    let newSortWay: "" | "asc" | "desc" = 'asc';

    if (column === sortField) {
      if (sortWay === 'asc') {
        newSortWay = 'desc';
      } else if (sortWay === 'desc') {
        newSortWay = '';
      }
    }
    setSortField(column);
    setSortWay(newSortWay);
  };

  useEffect(() => {
    setSortField(currentSort?.field || "");
    setSortWay(currentSort?.way || "");
  }, [currentSort]);

  useEffect(() => {
    onSort({ field: sortField, way: sortWay });
    //eslint-disable-next-line
  }, [sortField, sortWay])
  return (
    <TableCell onClick={() => { sorting(field) }}>
      {label}
      {sortWay === "asc" && sortField === field && (
        <span className="ordering">
          <ArrowDropUp fontSize="small" />
        </span>
      )}
      {sortWay === "desc" && sortField === field && (
        <span className="ordering">
          <ArrowDropDown fontSize="small" />
        </span>
      )}
      {(sortWay === "" || (sortField !== field)) && (
        <span className="ordering">
          <ArrowDropUp fontSize="small" />
          <ArrowDropDown fontSize="small" />
        </span>
      )}
    </TableCell>
  );
}
