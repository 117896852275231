import { AutocompleteField, FormField } from "@applications-terrains/birdz-react-library";
import { Grid } from "@mui/material";
import React from "react";

type CampaignOperatorProps = {
  onChange(properatorotocol: any): any;
  operator: any;
  filter?: any;
  allowNullOperator?: boolean
};

export const CampaignOperator = ({
  onChange,
  operator,
  filter,
  allowNullOperator = true
}: CampaignOperatorProps) => {
  const defaultValue = { value: null, label: "Sélection automatique" };

  return (
    <Grid container spacing={1}>
      <Grid item xs={3}>
        Opérateur
      </Grid>
      <Grid item xs={9}>
        <AutocompleteField
          field={{
            options: {
              source: `/api/lists/operators/?ordering=name&${filter || ""}`,
              searchIsMulti: false,
              defaultOptions: allowNullOperator === false ? null : [defaultValue]
            },
            name: "operator",
          } as FormField}
          onSelect={(selectedOperator: any) => {
            onChange(selectedOperator);
          }}
          value={operator === null && allowNullOperator === true ? defaultValue : operator}
        />
      </Grid>
    </Grid>
  );
};
