import React from 'react';
import { AppPaper, StyledTitle } from '@applications-terrains/birdz-react-library';
import { Grid } from '@mui/material';
import { Outlet, useLocation } from 'react-router-dom';

const SpreadSendingsDashboard = () => {

    const {pathname} = useLocation()
    const page = pathname.split('/').pop()
    let title = ""
    switch (page) {
        case 'concentrator-statuses':
            title = ' (statuts des concentrateurs)'
            break;
        case 'hr-sendings':
            title = ' (envois HR)'
            break;
        default:
            break;
    }

    return (
        <AppPaper>
            <Grid justifyContent="space-between" container>
                <Grid item>
                    <StyledTitle>Récapitulatif des envois étalés {title}</StyledTitle>
                </Grid>
            </Grid>
            <Outlet />
        </AppPaper>
    );
};

export default SpreadSendingsDashboard;
