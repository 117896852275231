import { subDays, startOfToday, lightFormat, addDays } from 'date-fns'

export const fillEmptyValues = (data: any) => {
    let newData: any[] = [];

    let today = startOfToday();

    // 1st date: today - 90 days
    let currentDate = subDays(today, 90);

    while (currentDate < today) {
        let formatedDate = lightFormat(currentDate, 'yyyy-MM-dd') + 'T00:00:00Z';
        let found = data && data.find((date: any) => date.day === formatedDate)
        if (!found) {
            newData.push({
                date: formatedDate,
                name: lightFormat(currentDate, 'dd/MM')
            });
        } else {
            newData.push({ ...found, ...{ name: lightFormat(currentDate, 'dd/MM') } });
        }
        currentDate = addDays(currentDate, 1);
    }
    return newData;
}


export const getReasonLabel = (reasonId: number) => {
    const reasonsLabels: any = {
        0: 'Reject incomplete',
        1: 'Reject mismatch',
        10: 'Reject device length',
        2: 'Reject outdated',
        20: 'Reject quota length',
        21: 'Reject quota power',
        22: 'Reject quota duty cycle'
    };

    return reasonsLabels[reasonId] || "";
}

export const getReasonColor = (reasonId: number) => {
    const reasonsColors: any = {
        0: "#b1d4ec",
        1: "#f5c492",
        10: "#f5c49280",
        2: "#0f89ac",
        20: "#ec7d8d",
        21: "#d9ac9b",
        22: "#61db7cb3"
    }

    return reasonsColors[reasonId] || "#5c5c5c"
}

export const ninetyDaysAgo = lightFormat(subDays(startOfToday(), 90), 'yyyy-MM-dd') + 'T00:00:00Z';