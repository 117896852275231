import React from "react";
import CampaignsList from "../Search/Campaigns/CampaignsList";
import { AppPaper, BirdzTitle } from "@applications-terrains/birdz-react-library";

export const ArchivedCampaigns = () => {
    return (
        <AppPaper>
            <BirdzTitle>Campagnes archivées</BirdzTitle>
            <CampaignsList filters={""} archived={true} />
        </AppPaper>
    );
};
export default ArchivedCampaigns;
