import React, { useEffect, useState } from "react";

type ParametersBitmaskProps = {
  field: any;
  value: any;
  onFieldChange: any;
  disabled: boolean;
};

export const ParametersBitmask = ({
  field,
  value,
  onFieldChange,
  disabled
}: ParametersBitmaskProps) => {
  const [fieldValue, setFieldValue] = useState<any>();
  const length = field.choices ? field.choices.length : 8;

  useEffect(() => {
    let newFieldValue = value ? value.toString().split("") : [];

    // field value smaller than spec, complete length with 0 values
    if (newFieldValue.length < length) {
      let diff = length - newFieldValue.length;
      [...Array(diff)].forEach((x) => newFieldValue.unshift(0));
    }
    // field value bigger than spec, reduce it
    else if (newFieldValue.length > length) {
      newFieldValue = newFieldValue.slice(-length);
    }
    setFieldValue(newFieldValue);

  }, [value, length]);

  if (!fieldValue) {
    return <></>
  }

  return (
    <div className="bitmask" key={field.id}>
      {[...Array(length)].map((value: any, index: number) => {
        return (
          <div
            key={index}
            onClick={() => {
              if (disabled) {
                return;
              }

              let sendFieldValue = [...fieldValue];
              sendFieldValue[index] = parseInt(fieldValue[index]) === 1 ? 0 : 1;
              onFieldChange(field.id, sendFieldValue.join(""));
              setFieldValue(sendFieldValue)
            }}
          >
            {field.choices[index]}
            <div className="bit">{fieldValue[index]}</div>
          </div>
        );
      })}
    </div>
  );
};
