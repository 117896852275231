import React, { useState } from 'react';
import { Formik, Form, FormikHelpers } from 'formik';
import axios from 'axios';
import { Box, Button, Grid, IconButton, TextField } from '@mui/material';
import {
    AppPaper,
    BirdzDialog,
    BirdzNotif,
    BirdzTitle,
    showFriendlyErrorsHTML,
    useDialog,
    useNotif
} from '@applications-terrains/birdz-react-library';
import { Delete } from '@mui/icons-material';
import ListPageWrapper from '../Tools/ListPageWrapper';

export const ContractExclusionList = () => {
    const { confirmDialog, closeDialog, dialogOptions } = useDialog();
    const { notif, notifOptions } = useNotif();
    const endpoint = '/api/optimisation/contract-exclusions/';
    const [refresh, setRefresh] = useState<boolean>(false);

    const addExclusion = (values: any) => {
        if (values.contract) {
            axios
                .post('/api/optimisation/contract-exclusions/', {
                    contract: values.contract
                })
                .then(
                    () => {
                        refreshData();
                        notif({
                            title: 'Succès',
                            content:
                                "L'exclusion a bien été ajoutée pour le contrat " + values.contract,
                            type: 'success'
                        });
                    },
                    (error: any) => {
                        // if (error?.response?.data) {
                        //   let errorMessage = "<ul>";
                        //   Object.keys(error.response.data).forEach((fieldError: string) => {
                        //     errorMessage += `<li>${error.response.data[fieldError]}</li>`
                        //   })
                        //   errorMessage += '</ul>';

                        //   notif({
                        //     content: errorMessage,
                        //     type: "error",
                        //   });
                        // }
                        const errors = error?.response?.data || [];
                        notif({
                            type: 'error',
                            content: showFriendlyErrorsHTML(
                                errors,
                                "Une erreur est survenue lors de l'ajout de l'exclusion pour le contrat " +
                                    values.contract
                            )
                        });
                    }
                );
        }
    };

    const refreshData = () => {
        setRefresh(true);
        setTimeout(() => {
            setRefresh(false);
        }, 200);
    };

    const actions = [
        {
            name: 'delete-group',
            render: (model: any) => {
                return (
                    <IconButton
                        onClick={() => {
                            confirmDialog({
                                title: "Supprimer l'exclusion",
                                content: 'Êtes-vous sûr de vouloir supprimer cette exclusion?',
                                onValidate: () => {
                                    axios.delete(`${endpoint}${model.id}/`).then(
                                        () => {
                                            notif({
                                                content: "L'exclusion a été supprimée avec succès",
                                                type: 'success'
                                            });
                                            refreshData();
                                            // document.location.reload();
                                        },
                                        () => {
                                            notif({
                                                content:
                                                    'Une erreur est survenue lors de la suppression',
                                                type: 'error'
                                            });
                                        }
                                    );
                                    closeDialog();
                                },
                                onCancel: () => {
                                    closeDialog();
                                }
                            });
                        }}>
                        <Delete fontSize="small" />
                    </IconButton>
                );
            }
        }
    ];

    return (
        <AppPaper>
            <BirdzTitle>Ajouter une exclusion</BirdzTitle>

            <Formik
                initialValues={{}}
                enableReinitialize={true}
                onSubmit={(values, { setSubmitting }: FormikHelpers<any>) => {
                    addExclusion(values);
                }}>
                {({ values, setFieldValue }) => (
                    <Form>
                        <Grid container>
                            <Grid item xs={4}>
                                Contrat
                            </Grid>
                            <Grid item xs={8}>
                                <TextField
                                    type={'text'}
                                    size="small"
                                    fullWidth
                                    value={values?.contract || ''}
                                    onChange={(e: any) => {
                                        setFieldValue('contract', e.target.value);
                                    }}
                                />
                            </Grid>
                        </Grid>

                        <Box sx={{ textAlign: 'center', mt: 2 }}>
                            <Button variant="contained" type="submit">
                                Ajouter
                            </Button>
                        </Box>
                    </Form>
                )}
            </Formik>

            {
                <ListPageWrapper
                    endpoint={endpoint}
                    fields={[{ name: 'contract', label: 'Contrat' }]}
                    actions={actions}
                    refresh={refresh}
                />
            }
            <BirdzNotif options={notifOptions} />
            <BirdzDialog options={dialogOptions} />
        </AppPaper>
    );
};

export default ContractExclusionList;
