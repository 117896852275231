import React, { useState } from 'react';
import { AppPaper } from '@applications-terrains/birdz-react-library';
import { Trans } from 'react-i18next';
import moment from 'moment';
import { LogsDetail } from './LogDetail';
import { Box, IconButton } from '@mui/material';
import { Search } from '@mui/icons-material';
import SearchFormWithDatePickerRange from '../Tools/SearchFormWithDatePickerRange';
import ListPageWrapper from '../Tools/ListPageWrapper';

type Log = {
    id: number;
    date: any;
    ip: string;
    content: string;
    type: number;
    type_label: string;
    user_agent: string;
    user__name: string;
};

const endpoint = '/api/activity-logs/';

const Logs = (params: any) => {
    const [logDetail, setLogDetail] = useState<any>();
    const [filters, setFilters] = useState<string>('');

    let listFields = [
        { name: 'id', label: '#', options: { width: '30px' } },
        {
            name: 'date',
            label: 'Date',
            orderable: true,
            transform: (value: any) => {
                return (value && moment(value).format('DD/MM/YYYY à HH:mm:ss')) || '';
            },
            options: { width: '150px' }
        },
        {
            name: 'user__name',
            label: 'Utilisateur',
            className: 'text-center',
            options: { width: '150px' },
            orderable: true
        },
        {
            name: 'type_label',
            label: 'Type',
            className: 'text-center',
            options: { width: '150px' }
        },
        {
            name: 'message',
            label: 'Message',
            className: 'text-center'
        }
    ];

    let searchFields = [
        {
            name: 'user',
            label: 'Utilisateur',
            options: {
                identifier: 'id',
                source: '/api/users/?ordering=name&',
                label: 'name',
                searchIsMulti: false
            }
        },
        {
            name: 'type',
            label: 'Type',
            type: 'select-multiple',
            options: {
                values: [
                    { value: 1, label: 'Connexion' },
                    { value: 2, label: 'INFO' },
                    { value: 3, label: 'WARNING' },
                    { value: 4, label: 'ERROR' }
                ]
            }
        }
        // {
        //     name: 'date_after',
        //     label: 'Entre le ',
        //     type: 'datepicker'
        // },
        // {
        //     name: 'date_before',
        //     label: 'et le',
        //     type: 'datepicker'
        // }
    ];

    let actions = [
        {
            name: 'detail',
            label: 'Détail',
            render: (logDetail: Log) => {
                return (
                    <IconButton
                        onClick={() => {
                            setLogDetail(logDetail);
                        }}>
                        <Search fontSize="small" />
                    </IconButton>
                );
            }
        }
    ];

    return (
        <div>
            {params.action === 'list' && (
                <AppPaper>
                    <h2>
                        <Trans>Liste des logs</Trans>
                    </h2>
                    <Box sx={{mb:5}}>
                        <SearchFormWithDatePickerRange
                            dateAfterLabel="date_after"
                            dateBeforeLabel="date_before"
                            setFilters={setFilters}
                            fields={searchFields}
                        />
                    </Box>
                    <ListPageWrapper
                        endpoint={endpoint}
                        fields={listFields}
                        actions={actions}
                        defaultOrder={['-date']}
                        filters={filters}
                    />
                </AppPaper>
            )}
            {logDetail && (
                <LogsDetail
                    logItem={logDetail}
                    onClose={() => {
                        setLogDetail(null);
                    }}
                />
            )}
        </div>
    );
};
export default Logs;
