import { BrowserRouter, Route, Routes } from 'react-router-dom';
import './App.scss';
import './assets/scss/date-picker.scss';
import { Loader } from './components/Common/Layout/Loader';
import routes from './routes';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import fr from 'date-fns/locale/fr';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { mainTheme } from './theme';
import { ThemeProvider } from '@mui/material';
import { authService } from '.';
import * as _ from 'lodash';
import { IsLoadingContextProvider } from './contexts/IsLoadingContext';
import Notification from './components/Common/Layout/Notification';

const App = () => {
    const userPermissions = authService?.user?.permissions || [];

    const displayRoutes = (routes: any) => {
        return routes.map((route: any, index: number) => {
            if (route.permissions && authService?.isAuthenticated) {
                const permissionsGranted =
                    _.intersection(userPermissions, route.permissions).length ===
                    route.permissions.length;

                if (!permissionsGranted) {
                    return null;
                }
            }

            if (route.path) {
                return (
                    <Route key={route.path + index} path={route.path} element={route.element}>
                        {route.children && route.children.length > 0
                            ? displayRoutes(route.children)
                            : null}
                    </Route>
                );
            } else {
                return <Route index element={route.element} key={`route-index-${index}`} />;
            }
        });
    };

    return (
        <ThemeProvider theme={mainTheme}>
            <LocalizationProvider adapterLocale={fr} dateAdapter={AdapterDateFns}>
                <IsLoadingContextProvider>
                    <Notification />
                    <Loader />
                    <BrowserRouter>
                        <Routes>{displayRoutes(routes)}</Routes>
                    </BrowserRouter>
                </IsLoadingContextProvider>
            </LocalizationProvider>
        </ThemeProvider>
    );
};

export default App;
