import { AppPaper, StyledTitle } from '@applications-terrains/birdz-react-library'
import React from "react";
import moment from "moment";
import dataService from "../../Common/Services/dataService";
import ChartSuccessFail from "../Chart/ChartSuccessFail";
import ChartFailedInstructions from "../Chart/ChartFailedInstructions";
import { ninetyDaysAgo } from "../Chart/ChartUtils";
import { Operator } from "../../types";
import { useNavigate } from "react-router-dom";
import { PiFileXls } from 'react-icons/pi';
import { Search } from '@mui/icons-material';
import { IconButton } from '@mui/material';
import ListPageWrapper from '../../Tools/ListPageWrapper';


export const CreatedLinks = () => {
    const navigate = useNavigate();

    return (
        <AppPaper>
            <StyledTitle>Statut des commandes primo-raccordement(s) sur les 90 derniers jours</StyledTitle>
            <h5 className="text-center">Évolution des succès et des échecs</h5>
            <ChartSuccessFail type="PRIMO" height={150} />
            <hr />
            <h5 className="text-center">Répartition des statuts en échec</h5>
            <ChartFailedInstructions type="PRIMO" height={150} />

            <ListPageWrapper
                endpoint="/api/racsup/instructions/"
                fields={[
                    { name: "contract", label: "Contrat", orderable: true },
                    { name: "name", label: "Chantier", orderable: true },
                    { name: "description", label: "Description", orderable: true },
                    {
                        name: "operator", label: "Opérateur", transform: (value: any) => {
                            let operator = (dataService.getData('operators') as Operator[]).find((operator: any) => operator.id === value);
                            return operator?.name || value;
                        }
                    },
                    {
                        name: "end_date", label: "Date de fin", orderable: true, transform: (value: any) => {
                            let date =
                                value && moment(value).format("DD/MM/YYYY à HH:mm:ss");
                            return date || "";
                        }
                    }
                ]}
                filters={"&type=PRIMO&created_at_after=" + ninetyDaysAgo}
                exportButtons={[
                    {
                        name: "Exporter en xlsx",
                        fileName: "Suivi des commandes primo-raccordement(s).xlsx",
                        type: "xlsx",
                        icon: <PiFileXls />
                    }
                ]}
                paginationOptions={{ pageSize: 100 }}
                actions={[
                    {
                        name: 'see-campaign',
                        label: "Récap des envois",
                        render: (value: any) => <IconButton onClick={() => {
                            navigate('/sent-list?tab=purposes&campaign=' + value.campaign)
                        }}><Search fontSize="small" /></IconButton>,
                        isVisible: (value: any) => {
                            return value?.campaign;
                        }
                    }
                ]}
            />
        </AppPaper>
    );
};

export default CreatedLinks;
