import React, { useEffect, useState } from 'react';
import { Trans } from 'react-i18next';
import 'react-datepicker/dist/react-datepicker.css';
import { campaignService } from '../CampaignService';
import { CampaignSaveButtons } from '../CampaignSaveButtons';
import { useNavigate } from 'react-router-dom';
import { CampaignOperator } from '../CampaignOperator';
import { CampaignFrameTemplate } from '../CampaignFrameTemplate';
import { CampaignDeviceBroadcast } from './CampaignDeviceBroadcast';
import { CampaignType } from '../../types';
import dataService from '../../Common/Services/dataService';
import { ScheduleOptions } from '../../Common/ScheduleOptions/ScheduleOptions';
import { TextField } from '@mui/material';
import {
    AppPaper,
    BirdzDialog,
    BirdzNotif,
    BirdzTitle,
    useDialog,
    useNotif
} from '@applications-terrains/birdz-react-library';
import { useDuplicateCampaignContext } from '../../../contexts/DuplicateCampaignContext';

const BROADCAST_CAMPAIGN: CampaignType = 'broadcast';
const DEFAULT_CAMPAIGN = {
    type: BROADCAST_CAMPAIGN
};

export const BroadcastCampaign = () => {
    const [campaign, setCampaign] = useState<any>(DEFAULT_CAMPAIGN);
    const [hasCombinedAddress, setHasCombinedAddress] = useState<boolean>(false);
    const { confirmDialog, closeDialog, dialogOptions } = useDialog();
    const { notif, notifOptions } = useNotif();

    const navigate = useNavigate();
    const { duplicate } = useDuplicateCampaignContext();

    useEffect(() => {
        setCampaign(
            duplicate
                ? { ...duplicate.campaign, name: duplicate.campaign.name + '_COPIE' }
                : DEFAULT_CAMPAIGN
        );
    }, [duplicate]);

    const resetForm = () => {
        confirmDialog({
            title: 'Souhaitez-vous vraiment annuler vos saisies?',
            onValidate: () => {
                setCampaign(DEFAULT_CAMPAIGN);
                closeDialog();
            },
            onCancel: () => {
                closeDialog();
            }
        });
    };

    const operator = campaign?.operator ? dataService.getOperator(campaign?.operator) : null;
    const devicesProtocols = campaignService.getDevicesProtocols(campaign.devices);
    const campaignProtocol =
        devicesProtocols.length === 1 ? devicesProtocols[0] : operator?.protocol;

    return (
        <AppPaper>
            <BirdzTitle>
                <Trans>Création d'une intention de broadcast</Trans>
            </BirdzTitle>
            <AppPaper>
                <h3>
                    <Trans>Étape 1: équipement CR cible</Trans>
                </h3>

                <CampaignOperator
                    onChange={(operator: any) => {
                        setCampaign({
                            ...campaign,
                            ...{
                                operator: operator?.value,
                                scheduled_date: duplicate ? campaign.scheduled_date : undefined,
                                sending_schedule: duplicate ? campaign.sending_schedule : undefined
                            }
                        });
                    }}
                    operator={operator ? { value: operator.id, label: operator.name } : undefined}
                    filter="?protocol=1&"
                    allowNullOperator={false}
                />

                {campaign.operator !== undefined && (
                    <CampaignDeviceBroadcast
                        onChange={(broadcastDevices, broadcastCode, devicesString) => {
                            setCampaign({
                                ...campaign,
                                devices: broadcastDevices,
                                broadcast_code: broadcastCode
                            });
                            setHasCombinedAddress(devicesString?.includes('combined_address'));
                        }}
                        operatorId={campaign.operator}
                    />
                )}
            </AppPaper>

            {campaign.devices && campaign.devices.length > 0 && (
                <>
                    <AppPaper sx={{ mt: 1 }}>
                        <h3>
                            <Trans>Étape 2: choisir un modèle de broadcast</Trans>
                        </h3>

                        <CampaignFrameTemplate
                            onChange={(selectedFrameTemplate: any) => {
                                if (selectedFrameTemplate) {
                                    setCampaign({
                                        ...campaign,
                                        ...{ frame_template: selectedFrameTemplate.value }
                                    });
                                }
                            }}
                            type="broadcast"
                            hasCombinedAddress={hasCombinedAddress}
                        />
                    </AppPaper>

                    {campaign.frame_template && (
                        <>
                            <AppPaper sx={{ mt: 1 }}>
                                <h3>
                                    <Trans>Étape 3: action sur cible</Trans>
                                </h3>

                                <ScheduleOptions
                                    onChange={(campaignType: any) => {
                                        setCampaign((campaign: any) => {
                                            return {
                                                ...campaign,
                                                ...{
                                                    scheduled_date: campaignType.scheduledDate,
                                                    sending_schedule: campaignType.type
                                                }
                                            };
                                        });
                                    }}
                                    value={{
                                        type: campaign.sending_schedule,
                                        scheduledDate: campaign.scheduled_date
                                    }}
                                    operatorId={campaign?.operator}
                                    protocolId={campaignProtocol}
                                />
                            </AppPaper>

                            <AppPaper sx={{ mt: 1 }}>
                                <h3>
                                    <Trans>
                                        Étape{' '}
                                        {campaign?.operator &&
                                        campaignService.operatorUseHRProtocol(campaign?.operator)
                                            ? '5'
                                            : '4'}
                                        : nommer la campagne
                                    </Trans>
                                </h3>

                                <TextField
                                    type={'text'}
                                    size="small"
                                    fullWidth
                                    onChange={(e: any) => {
                                        setCampaign({
                                            ...campaign,
                                            ...{ name: e.target.value }
                                        });
                                    }}
                                    value={campaign.name || ''}
                                />
                            </AppPaper>
                        </>
                    )}

                    <CampaignSaveButtons
                        onReset={() => resetForm()}
                        onSave={() => {
                            const {
                                id,
                                created_at,
                                created_by_token,
                                error_message,
                                state,
                                updated_at,
                                ...campaignToDuplicate
                            } = campaign;
                            const campaignToSave = duplicate ? campaignToDuplicate : campaign;
                            campaignService.save(campaignToSave).then(
                                () => {
                                    notif(campaignService.successSavingNotification());

                                    setTimeout(() => {
                                        navigate('/sent-list?tab=campaigns');
                                    }, 3000);
                                },
                                (error) => notif(campaignService.errorSavingNotification(error))
                            );
                        }}
                        saveBtnDisabled={!campaign.name}
                    />
                </>
            )}
            <BirdzNotif options={notifOptions} />
            <BirdzDialog options={dialogOptions} />
        </AppPaper>
    );
};

export default BroadcastCampaign;
