import React from "react";
import "./SentDashboard.scss";
import moment from "moment";
import SendingStats from "./SendingStats";
import { Grid } from "@mui/material";
import { AppPaper, BirdzTitle } from "@applications-terrains/birdz-react-library";

export const SentDashboard = () => {
  return (
    <AppPaper>
      <BirdzTitle>Tableau de bord des envois</BirdzTitle>

      <Grid container spacing={1}>
        <Grid item xs={4}>
          <SendingStats
            filters={
              "created_at_after=" + moment().subtract(3, "month").format("YYYY-MM-DDTHH:mm:ss")
            }
            title="90 jours"
          />
        </Grid>
        <Grid item xs={4}>
          <SendingStats
            filters={
              "created_at_after=" + moment().subtract(1, "month").format("YYYY-MM-DDTHH:mm:ss")
            }
            title="30 jours"
          />
        </Grid>
        <Grid item xs={4}>
          <SendingStats
            filters={"created_at_after=" + moment().subtract(7, "day").format("YYYY-MM-DDTHH:mm:ss")}
            title="7 jours"
          />
        </Grid>
      </Grid>
    </AppPaper>
  );
};

export default SentDashboard;
