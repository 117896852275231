import { Paper, Table, TableCell, TableContainer, TableRow } from "@mui/material";
import React from "react";

export const tooltipTechno = [
    <TableContainer component={Paper}>
        <Table size="small" className="techno">
            <TableRow>
                <TableCell>Technologie</TableCell>
                <TableCell>Valeur</TableCell>
            </TableRow>
            <TableRow>
                <TableCell>LoraWan</TableCell>
                <TableCell className="strong">LW</TableCell>
            </TableRow>
            <TableRow>
                <TableCell>HomeRider</TableCell>
                <TableCell className="strong">HR</TableCell>
            </TableRow>
            <TableRow>
                <TableCell>SigFox</TableCell>
                <TableCell className="strong">SF</TableCell>
            </TableRow>
        </Table>
    </TableContainer>,
];

export const tooltipOperator = [
    <TableContainer component={Paper}>
        <Table size="small" className="operator">
            <TableRow>
                <TableCell colSpan={2}>LORAWAN</TableCell>
                <TableCell colSpan={2} className="border-left border-right">HOMERIDER</TableCell>
                <TableCell colSpan={2}>SIGFOX</TableCell>
            </TableRow>
            <TableRow>
                <TableCell className="strong">LVO</TableCell>
                <TableCell>Live Objects Orange</TableCell>
                <TableCell className="strong border-left">OBS</TableCell>
                <TableCell className="border-right">Orange Business Service</TableCell>
                <TableCell className="strong">SFF</TableCell>
                <TableCell>Sigfox France</TableCell>
            </TableRow>
            <TableRow>
                <TableCell className="strong">OBJ</TableCell>
                <TableCell>Objenious</TableCell>
                <TableCell className="strong border-left">BIR</TableCell>
                <TableCell className="border-right">Birdz</TableCell>
                <TableCell className="strong">MOCK</TableCell>
                <TableCell>MOCK</TableCell>
            </TableRow>
            <TableRow>
                <TableCell className="strong">MOCK</TableCell>
                <TableCell>MOCK</TableCell>
                <TableCell className="strong border-left">MOCK</TableCell>
                <TableCell className="border-right">MOCK</TableCell>
                <TableCell colSpan={2} className="border-left"></TableCell>
            </TableRow>
            <TableRow>
                <TableCell className="strong">ACTST</TableCell>
                <TableCell>Actility Smart Territory</TableCell>
                <TableCell colSpan={4} className="border-left"></TableCell>
            </TableRow>
            <TableRow>
                <TableCell className="strong">ACTEDC</TableCell>
                <TableCell>Actility Eau des Collines</TableCell>
                <TableCell colSpan={4} className="border-left"></TableCell>
            </TableRow>
            <TableRow>
                <TableCell className="strong">ACTSB</TableCell>
                <TableCell>Actility Smart Building</TableCell>
                <TableCell colSpan={4} className="border-left">&nbsp;</TableCell>
            </TableRow>
            <TableRow>
                <TableCell className="strong">ACTRO</TableCell>
                <TableCell>Actility Roaming</TableCell>
                <TableCell colSpan={4} className="border-left"></TableCell>
            </TableRow>
        </Table>
    </TableContainer>,
];

export const modelTypes = [
    { value: "configuration", label: "Configuration" },
    {
        value: "affiliate",
        label: "Affiliation",
    },
    { value: "broadcast", label: "Broadcast" },
    { value: "clock_synchro", label: "Synchro d'horloge" },
    { value: "gutermann", label: "Gutermann" },
];