import React, { useState } from 'react';
import dataService from '../../Common/Services/dataService';
import { useTranslation } from 'react-i18next';
import { CampaignUploadDevicesList } from '../CampaignUploadDevicesList/CampaignUploadDevicesList';
import { Device } from '../../types';
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead } from '@mui/material';
import { StyledTableHeaderRow } from '@applications-terrains/birdz-react-library';
import useExtendableList from '../../../hooks/useExtendableList';

type CampaignDeviceGutermannProps = {
    onChange(devices: Device[], deviceString: string, operator?: number): void;
    operatorId: number;
};

export const CampaignDeviceGutermann = ({ onChange, operatorId }: CampaignDeviceGutermannProps) => {
    const { t } = useTranslation();
    const [devices, setDevices] = useState<Device[]>([]);
    const { listToDisplay, SeeMore } = useExtendableList(devices);

    return (
        <>
            <CampaignUploadDevicesList
                campaignType="gutermann"
                onChange={(devices, deviceString, operator) => {
                    onChange(devices, deviceString, operator);
                    setDevices(devices);
                }}
                operatorId={operatorId}
            />

            {devices && devices.length > 0 && (
                <TableContainer component={Paper}>
                    <Table size="small">
                        <TableHead>
                            <StyledTableHeaderRow>
                                <TableCell>{t('module_address')}</TableCell>
                                <TableCell>gut_cre_v</TableCell>
                                <TableCell>gut_cre_t</TableCell>
                                <TableCell>gut_tx_p</TableCell>
                                <TableCell>gut_rx_p24_s</TableCell>
                                <TableCell>gut_rx_p24_d</TableCell>
                                <TableCell>{t('module_type')}</TableCell>
                                <TableCell>{t('protocol')}</TableCell>
                                <TableCell>{t('operator')}</TableCell>
                                <TableCell>{t('type')}</TableCell>
                                <TableCell>{t('concentrator')}</TableCell>
                            </StyledTableHeaderRow>
                        </TableHead>
                        <TableBody>
                            {(listToDisplay as Device[]).map((device) => {
                                return (
                                    <tr key={device.module_address + device.clock_synchro}>
                                        <TableCell>{device?.module_address}</TableCell>
                                        <TableCell>{device?.gut_cre_v}</TableCell>
                                        <TableCell>{device?.gut_cre_t}</TableCell>
                                        <TableCell>{device?.gut_tx_p}</TableCell>
                                        <TableCell>{device?.gut_rx_p24_s}</TableCell>
                                        <TableCell>{device?.gut_rx_p24_d}</TableCell>
                                        <TableCell>
                                            {dataService.getDeviceType(device?.device_type)}
                                        </TableCell>
                                        <TableCell>
                                            {dataService.getProtocol(device?.protocol)?.name}
                                        </TableCell>
                                        <TableCell>
                                            {dataService.getOperator(device?.operator)?.name}
                                        </TableCell>
                                        <TableCell>{device?.type}</TableCell>
                                        <TableCell>
                                            {dataService.getProtocol(device?.protocol)?.name ===
                                                'Homerider' && device?.concentrator
                                                ? device.concentrator
                                                : '-'}
                                        </TableCell>
                                    </tr>
                                );
                            })}
                        </TableBody>
                    </Table>
                    <SeeMore />
                </TableContainer>
            )}
        </>
    );
};
