import React, { useEffect, useState } from "react";
import "react-datepicker/dist/react-datepicker.css";
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { curveMonotoneX } from 'd3-shape';
import axios from "axios";
import { fillEmptyValues, getReasonColor, getReasonLabel } from "./ChartUtils";
import "./ChartFailedInstructions.scss";

type ChartFailedInstructionsProps = {
    title?: string;
    type?: string;
    width?: number | string;
    height?: number | string;
}

export const ChartFailedInstructions = ({ title, type, width, height }: ChartFailedInstructionsProps) => {
    const [allReasons, setAllReasons] = useState<number[]>([]);

    useEffect(() => {
        getFailedChartData();
        // eslint-disable-next-line
    }, []);

    const [failedData, setFailedData] = useState<any>([]);

    const getFailedChartData = () => {
        let filterType = type ? "?type=" + type : ''
        axios.get('/api/racsup/rejects-stats/?days=90&' + filterType).then((response: any) => {
            let data = response.data;
            let newdata: any = {};

            // Extract all different existing reasons in data
            let tmpAllReasons: number[] = Array.from(new Set(data?.map((item: any) => item.txreject__reject_label)));
            setAllReasons(tmpAllReasons);

            data.forEach((item: any) => {
                if (newdata[item.day]) {
                    newdata[item.day][item.txreject__reject_label] = item.reason_count
                } else {
                    newdata[item.day] = { [item.txreject__reject_label]: item.reason_count }
                }
            })

            let newFormatedData: any[] = []; Object.keys(newdata).forEach(date => {
                let item: any = {
                    day: date
                }

                Object.keys(newdata[date]).forEach((field: string) => {
                    item[field] = newdata[date][field];
                })

                newFormatedData.push(item)
            });

            newFormatedData = fillEmptyValues(newFormatedData);
            newFormatedData = newFormatedData.map((item: any) => {
                tmpAllReasons.forEach((reason: number) => {
                    item[reason] = item[reason] || 0;
                })

                return item;
            })

            setFailedData(newFormatedData)
        });
    }

    return (
        <div className="chart-failed-instructions">
            {failedData && failedData.length > 0 && (<div style={{ width: width || '100%', height: height || 300 }}>
                {title && <h3 className="text-center">{title}</h3>}
                <ResponsiveContainer>
                    <LineChart
                        data={failedData}
                        margin={{
                            top: 5,
                            right: 30,
                            left: 20,
                            bottom: 5,
                        }}
                    >
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey="name" />
                        <YAxis label={{ value: 'Nb de commandes uniques', angle: -90, position: 'insideBottomLeft' }} />
                        <Tooltip />
                        <Legend />
                        {allReasons.map((reason: any) => {
                            return <Line type={curveMonotoneX} name={getReasonLabel(reason)} dataKey={reason} stroke={getReasonColor(reason)} dot={false} strokeWidth={2} />
                        })}
                    </LineChart>
                </ResponsiveContainer>
            </div>)}
        </div>
    );
};

export default ChartFailedInstructions;
