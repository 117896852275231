import { Box, Pagination, Typography } from '@mui/material';
import { PaginationType } from './PageSizeSelector';

const PaginationComponent = ({
    displayResultsNumber = false,
    pagination,
    setPagination
}: {
    displayResultsNumber?: boolean;
    pagination: PaginationType;
    setPagination: (a: PaginationType) => void;
}) => {
    const totalPages = Math.ceil(pagination.totalItems / pagination.pageSize);

    return totalPages > 1 ? (
        <Box
            display="flex"
            justifyContent={displayResultsNumber ? 'space-between' : 'flex-end'}
            sx={{ my: 2 }}>
            {displayResultsNumber && (
                <Typography fontSize="20px">
                    {pagination?.totalItems} résultat{pagination?.totalItems !== 1 && 's'}
                </Typography>
            )}
            <Pagination
                count={totalPages}
                page={pagination.currentPage}
                onChange={(event: any, page: number) => {
                    setPagination({ ...pagination, currentPage: page });
                }}
            />
        </Box>
    ) : null;
};

export default PaginationComponent;