import React from "react";
import LinkSearchContracts from "./LinkSearchContracts";
import { Box, Button, Tooltip } from "@mui/material";

type LinkContractsListProps = {
    children: any,
    endpoint: string,
    onFilterContracts: (contracts: string[]) => void,
    selectedContracts: string[],
    onCreateLink: () => void,
    resultsField?: string,
    formatResponse?: (response: any) => any,
}

const LinkContractsList = ({ children, endpoint, onFilterContracts, selectedContracts, onCreateLink, resultsField, formatResponse }: LinkContractsListProps) => {
    return <>
        <LinkSearchContracts
            onFilter={(contracts: any[]) => {
                onFilterContracts(contracts.map(contract => contract.value));
            }}
            endpoint={endpoint}
            resultsField={resultsField}
            formatResponse={formatResponse}
        />

        <Box sx={{ mt: 1, textAlign: 'right' }} >
            <Tooltip title={`Contrats sélectionnés: ${selectedContracts.join(', ')}`}>
                <Button
                    variant="contained"
                    disabled={!selectedContracts.length}
                    onClick={() => onCreateLink()}>
                    {selectedContracts.length === 1 && "Créer un chantier sur le contrat sélectionné"}
                    {selectedContracts.length !== 1 && "Créer des chantiers sur les " + selectedContracts.length + " contrats sélectionnés"}
                </Button>
            </Tooltip>
        </Box>


        {children}
    </>;
};

export default LinkContractsList;