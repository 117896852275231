import { Autocomplete, TextField } from '@mui/material';
import { useEffect, useState } from 'react';

type ParametersDropdownListProps = {
    field: any;
    value: any;
    onFieldChange: any;
    disabled: boolean;
};

type Option = { value: any; label: string } | null;

export const ParametersDropdownList = ({
    field,
    value,
    onFieldChange,
    disabled
}: ParametersDropdownListProps) => {
    const [selectedValue, setSelectedValue] = useState<Option>(null);
    const [possibleValues, setPossibleValues] = useState<{ [key: string]: any }[]>([]);

    const buildValues = (field: any) => {
        // If no "choices" available, try to construct choices list if min and max value are setted
        let possibleValues: any = [];
        if (
            !field.possible_value &&
            typeof field.min !== 'undefined' &&
            typeof field.max !== 'undefined'
        ) {
            let step = field.step || 1;

            for (let i = field.min; i <= field.max; i = i + step) {
                possibleValues.push({ label: i.toString(), value: i });
            }
        }

        if (field.possible_value) {
            let possibleValuesKeys = Object.keys(field.possible_value);

            // On ne trie que si on a un step (pour ne pas avoir les décimales à la fin de la liste)
            if (field.step) {
                possibleValuesKeys = possibleValuesKeys.sort((a: any, b: any) => a - b);
            }

            possibleValuesKeys.forEach((possibleValueName: any) => {
                possibleValues.push({
                    label: possibleValueName,
                    value: field.possible_value[possibleValueName]
                });
            });
        }

        setPossibleValues(possibleValues);
    };

    useEffect(() => {
        if (field.possible_value) {
            const hasLabelAndValueKeys = (obj: object) => {
                return 'label' in obj && 'value' in obj;
            };
            if (typeof field.possible_value === 'object' && field.possible_value !== null) {
                if (hasLabelAndValueKeys(field.possible_value)) {
                    setPossibleValues(field.possible_value);
                } else {
                    const formattedOptions: { label: string; value: number }[] = [];
                    for (const property in field.possible_value) {
                        formattedOptions.push({
                            label: property,
                            value: field.possible_value[property]
                        });
                    }
                    setPossibleValues(formattedOptions);
                }
            }
        } else buildValues(field);
    }, [field]);

    useEffect(() => {
        if (possibleValues.length > 0) {
            const option = possibleValues.find((el) => el.value === value);
            if (option)
                setSelectedValue({
                    value,
                    label: option.label
                });
        }
    }, [value, possibleValues]);

    return (
        <Autocomplete
            filterSelectedOptions
            value={selectedValue || null}
            onChange={(_, selectedOption: Option) => {
                onFieldChange(field.id, selectedOption?.value);
                setSelectedValue(selectedOption);
            }}
            options={possibleValues}
            renderInput={(params: any) => <TextField {...params} variant="outlined" size="small" />}
            renderOption={(props, option: any, index) => {
                const key = `${field.name}-${option.label}-${option.value}`;
                return (
                    <li {...props} key={key}>
                        {option.label}
                    </li>
                );
            }}
            isOptionEqualToValue={(option: any, value: any) => {
                return option.value === value.value;
            }}
            noOptionsText="Aucune valeur à sélectionner"
            loadingText="Chargement en cours..."
            disabled={disabled}
        />
    );
};
