import {
    Tooltip,
    TooltipProps,
    styled,
    tooltipClasses
} from '@mui/material';

const LightTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip
        {...props}
        classes={{ popper: className }}
        componentsProps={{
            tooltip: {
                sx: {
                    maxWidth: '600px',
                    py: 0,
                    px: 2
                }
            }
        }}
        slotProps={{
            popper: {
                modifiers: [
                    {
                        name: 'offset',
                        options: {
                            offset: [0, -10]
                        }
                    }
                ]
            }
        }}
    />
))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: theme.palette.common.white,
        color: 'rgba(0, 0, 0, 0.8)',
        boxShadow: theme.shadows[3]
    }
}));

export default LightTooltip;