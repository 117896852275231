import React, { useEffect, useState } from "react";
import "react-datepicker/dist/react-datepicker.css";
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { curveMonotoneX } from 'd3-shape';
import axios from "axios";
import { fillEmptyValues } from "./ChartUtils";
import "./ChartSuccessFail.scss";

type ChartSuccessFailProps = {
    title?: string;
    type?: string;
    width?: number | string;
    height?: number | string;
}

export const ChartSuccessFail = ({ title, type, width, height }: ChartSuccessFailProps) => {
    useEffect(() => {
        getChartData()
        // eslint-disable-next-line
    }, []);

    const [data, setData] = useState<any>([]);
    const getChartData = () => {
        let filterType = type ? '&type=' + type : '';
        axios.get('/api/racsup/purposes-stats/?details=1' + filterType).then((response: any) => {
            let newData = fillEmptyValues(response.data);

            newData = newData.map((data: any) => {
                data.success_count = typeof data.success_count === 'number' ? data.success_count : 0;
                data.failure_count = typeof data.failure_count === 'number' ? data.failure_count : 0;
                return data;
            })
            setData(newData)
        })
    }

    return (
        <div className="chart-success-fail">
            {data && data.length > 0 && (<div style={{ width: width || '100%', height: height || 300 }}>
                {title && <h3 className="text-center">{title}</h3>}
                <ResponsiveContainer>
                    <LineChart
                        data={data}
                        margin={{
                            top: 5,
                            right: 30,
                            left: 20,
                            bottom: 5,
                        }}
                    >
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey="name" />
                        <YAxis label={{ value: 'Nb de commandes uniques', angle: -90, position: 'insideBottomLeft' }} />
                        <Tooltip />
                        <Legend />
                        <Line type={curveMonotoneX} name="Succès" dataKey="success_count" stroke="#61da7c" dot={false} activeDot={{ r: 8 }} strokeWidth={2} />
                        <Line type={curveMonotoneX} name="Échec" dataKey="failure_count" stroke="#ec7d8d" dot={false} strokeWidth={2} />
                    </LineChart>
                </ResponsiveContainer>
            </div>)}
        </div>
    );
};

export default ChartSuccessFail;
