import React from "react";
import { BirdzDialog, useDialog, AppPaper, StyledTitle, useNotif, BirdzNotif } from '@applications-terrains/birdz-react-library';
import { Link } from "react-router-dom";
import moment from "moment";
import Axios from "axios";
import "./Token.scss";
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { Box, Button, Grid, IconButton } from "@mui/material";
import { ContentCopy, Delete, Edit } from "@mui/icons-material";
import ListPageWrapper from "../../Tools/ListPageWrapper";

const Tokens = () => {
    const { confirmDialog, closeDialog, dialogOptions } = useDialog();
    const { notif, notifOptions } = useNotif();


    const endpoint = "/api/tokens/"
    let listFields = [
        {
            name: "contact__contact",
            label: "Nom",
            orderable: true,
            options: {
                width: '140px'
            }
        },
        {
            name: "contact__company",
            label: "Client",
            orderable: true,
            options: {
                width: '140px'
            }
        },
        {
            name: 'token',
            label: 'Token',
            orderable: true,
            transform: (token: string) => {
                return <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <Box title={token}>{token.substring(0, 6)}...</Box>
                    <IconButton onClick={() => {
                        navigator.clipboard.writeText(token);
                        notif({
                            content: "Token copié dans le presse-papier",
                            type: "success",
                        });
                    }}>
                        <ContentCopy fontSize="small" />
                    </IconButton>
                </Box>
            },
            options: {
                width: '100px'
            }
        },
        {
            name: 'templates_name',
            label: 'Modèles autorisés',
            orderable: true
        },
        {
            name: 'is_active',
            label: 'Actif',
            orderable: true,
            options: {
                width: '160px'
            }
        },
        {
            name: 'last_used',
            label: 'Dernière utilisation',
            transform: (last_used: string) => {
                moment(last_used).format("DD/MM/YYYY")
            },
            orderable: true,
            options: {
                width: '250px'
            }
        }
    ];

    let searchFields = [
        {
            name: "contact__name",
            label: "Contact",
            options: {
                source: "/api/tokens-contacts/?ordering=name&",
                label: "contact__name",
                identifier: "contact",
                searchIsMulti: false,
                formatResponse: (contact: any) => {
                    return {
                        value: contact.contact,
                        label: contact.contact
                    }
                },
            }
        },
    ];

    let actions = [
        {
            name: "edit",
            render: (value: any) => {
                return <IconButton component={Link} to={`/admin/tokens/edit/${value.contact}`}>
                    <Edit fontSize="small" />
                </IconButton>
            }
        },
        {
            name: "delete-token",
            render: (token: any) => {
                return <IconButton onClick={() => {
                    confirmDialog({
                        title: "Supprimer le token",
                        content: "Êtes-vous sûr de vouloir supprimer ce token?",
                        onValidate: () => {
                            Axios.delete(`${endpoint}${token.id}/`).then(
                                () => {
                                    notif({
                                        content: "Le token a été supprimé avec succès",
                                        type: "success",
                                    });
                                    document.location.reload();
                                },
                                () => {
                                    notif({
                                        content: "Une erreur est survenue lors de la suppression",
                                        type: "error",
                                    });
                                }
                            )
                            closeDialog();
                        },
                        onCancel: () => {
                            closeDialog();
                        }
                    })
                }}>
                    <Delete fontSize="small" />
                </IconButton>
            }
        },
    ];

    return (
        <AppPaper>
            <Grid justifyContent="space-between" container>
                <Grid item>
                    <StyledTitle>Liste des tokens</StyledTitle>
                </Grid>
                <Grid item>
                    <Link to="/admin/tokens/add">
                        <Button variant="contained" sx={{ mt: 3 }}>
                            <AddCircleIcon sx={{ mr: 1 }} /> Ajouter un contact token
                        </Button>
                    </Link>
                </Grid>
            </Grid>

            <ListPageWrapper
                endpoint={endpoint}
                fields={listFields}
                actions={actions}
                searchFields={searchFields}
                defaultOrder={['contact']}
            />
            <BirdzDialog options={dialogOptions} />
            <BirdzNotif options={notifOptions} />
        </AppPaper>
    );
}

export default Tokens;
