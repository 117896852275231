import { useEffect, useState } from 'react';
import OptimisationTabPanel from './OptimisationTabPanel';
import axios from 'axios';
import {
    FilterValuesType,
    FiltersData,
    FiltersDataType,
    OptimisationTabPanelWrapperProps
} from '../types';
import { getDefaultValue } from '../utils';

const OptimisationTabPanelWrapper = ({
    contracts,
    instructions,
    valueTab,
    setFilters,
    filters,
    setTotals
}: OptimisationTabPanelWrapperProps) => {
    const [filtersData, setFiltersData] = useState<FiltersDataType>(
        Object.fromEntries(contracts.map((contract) => [contract, null]))
    );
    const [filterValues, setFilterValues] = useState<FilterValuesType>(
        getDefaultValue(contracts, { filterValues: {}, formValue: {} })
    );

    useEffect(() => {
        const getFilters = (contract: string) => {
            axios
                .get(`/api/optimisation/devices-filters/?contract_code=${contract}`)
                .then((res: { data: FiltersData }) => {
                    setFiltersData((data) => ({ ...data, [contract]: res.data }));
                });
        };
        if (filtersData[contracts[valueTab]] === null) {
            getFilters(contracts[valueTab]);
        } else {
            const filtersDataArr = Object.entries(filtersData);
            contracts.forEach((contract, index) => {
                if (index > 0) {
                    if (filtersDataArr[index][1] === null && filtersDataArr[index - 1][1] !== null)
                        getFilters(contract);
                } else if (index === 0 && filtersDataArr[index][1] === null) {
                    getFilters(contract);
                }
            });
        }
    }, [contracts, filtersData, valueTab]);

    return (
        <>
            {contracts?.map((contract: string, tabIndex: number) => {
                const instruction = instructions[contract];
                return (
                    <div
                        style={{
                            // keep all tabs mounted in DOM so they don't reload and re-send requests each time we switch tabs
                            display: tabIndex === valueTab ? 'block' : 'none'
                        }}
                        key={'tab' + contract + tabIndex}>
                        <OptimisationTabPanel
                            contract={contract}
                            instruction={instruction}
                            filtersData={filtersData[contract] as FiltersData}
                            setFilterValues={setFilterValues}
                            filterValues={filterValues}
                            setFilters={setFilters}
                            filters={filters}
                            setTotals={setTotals}
                        />
                    </div>
                );
            })}
        </>
    );
};

export default OptimisationTabPanelWrapper;
