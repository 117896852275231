import useRequestStatus from '../../../hooks/useRequestStatus';
import { Alert, Snackbar } from '@mui/material';

export const Notification = () => {
    const { notification, setNotification } = useRequestStatus();

    return (
        <Snackbar
            open={notification !== null}
            autoHideDuration={4000}
            onClose={() => setNotification(null)}
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}>
            <Alert severity={notification?.type || 'info'} sx={{ width: '100%' }}>
                <>{notification?.content}</>
            </Alert>
        </Snackbar>
    );
};

export default Notification;