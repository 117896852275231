import { Box, Grid } from '@mui/material';
import { Instruction } from './types';

const ContractDetails = ({
    instruction,
    contract
}: {
    instruction: Instruction;
    contract: string;
}) => {
    
    return (
        <Grid container item sx={{ mb: 5 }}>
            <Box sx={{ color: 'primary.main', pr: 5 }}>
                <p>CONTRAT</p>
                <p>NOM DE CHANTIER</p>
                <p>EQUIPEMENTS</p>
            </Box>
            <Box>
                <p>{contract}</p>
                <p>{instruction?.name}</p>
                <ul style={{ margin: 0, paddingLeft: '1rem' }}>
                    <li>Affiliations : {instruction?.affiliation}</li>
                    <li>
                        Désaffiliations :{' '}
                        {
                            instruction?.desaffiliation
                        }
                    </li>
                    <li>
                        En exclusion :{' '}
                        {
                            instruction?.exclusion || 0
                        }
                    </li>
                </ul>
            </Box>
        </Grid>
    );
};

export default ContractDetails;
