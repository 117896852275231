import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import LinkForm from "../LinkForm";

import AppendLinkContractsSelection from "./AppendLinkContractsSelection";

export const AppendLink = () => {
    const { contractsParam } = useParams<any>();
    const [contracts, setContracts] = useState<string[]>([]);

    useEffect(() => {
        setContracts(contractsParam ? contractsParam.split(',') : []);
    }, [contractsParam]);

    return <>
        {contracts?.length ? <div className="block">
            <h2>
                Créer un chantier de renforcement (sur {contracts.length} contrat{contracts.length > 1 && 's'})
            </h2>
            <LinkForm contracts={contracts} linkType="renfo"></LinkForm>
        </div> : <AppendLinkContractsSelection />
        }
    </>
};

export default AppendLink;
