import React from "react";
import { ListPage, useDialog, BirdzDialog, Item, FormField, StyledTitle, AppPaper, useNotif, BirdzNotif } from '@applications-terrains/birdz-react-library';
import { Link } from "react-router-dom";
import moment from "moment";
import { DeviceManufacturer } from "../types";
import Axios from "axios";
import { authService } from "../..";
import { Button, Grid, IconButton } from "@mui/material";
import { AddCircle, Delete, Edit } from "@mui/icons-material";


interface Props {
    action: "list" | "add" | "edit";
}

export const DeviceManufacturers = ({ action }: Props) => {
    const endpoint = "/api/device-manufacturers/";
    const { confirmDialog, closeDialog, dialogOptions } = useDialog();
    const { notif, notifOptions } = useNotif();

    const formFields: FormField[] = [
        { name: "name", type: "text", label: "Nom" },
        {
            name: "technical_code",
            type: "text",
            label: "Code technique",
        },
        {
            name: "is_active",
            type: "radio",
            label: "Etat",
            options: {
                values: [
                    { value: true, label: "Actif" },
                    { value: false, label: "Inactif" },
                ],
            },
            defaultValue: true,
            permissions: ["IS_ACTIVE"],
        },
        {
            name: "updated_at",
            type: "readonly",
            label: "Dernière modification",
            transform: (value: any) => {
                let date =
                    value && moment.unix(value).format("DD/MM/YYYY à HH:mm:ss");
                return date || "";
            },
        },
    ];
    const listFields = [
        { name: "name", label: "Nom", orderable: true },
        {
            name: "is_active",
            label: "Actif",
            className: "text-center",
            options: { width: "100px" },
            orderable: true,
        },
        {
            name: "updated_at",
            label: "Date de modification",
            options: { width: "220px" },
            orderable: true,
            transform: (value: any) => {
                return (
                    (value && moment.unix(value).format("DD/MM/YYYY à HH:mm:ss")) || ""
                );
            },
        },
    ];

    const actions = [
        {
            name: "edit",
            render: (deviceManufacturer: DeviceManufacturer) => {
                return <IconButton component={Link} to={`/models/parameters/device-manufacturers/edit/${deviceManufacturer.id}`}>
                    <Edit fontSize="small" />
                </IconButton>
            }
        },
        {
            name: "delete-devicemanufacturer",
            render: (deviceManufacturer: DeviceManufacturer) => {
                return <IconButton onClick={() => {
                    confirmDialog({
                        title: "Supprimer le fabricant",
                        content: "Êtes-vous sûr de vouloir supprimer ce fabricant?",
                        onValidate: () => {
                            Axios.delete(`${endpoint}${deviceManufacturer.id}/`).then(
                                () => {
                                    notif({
                                        content: "Le fabricant a été supprimé avec succès",
                                        type: "success",
                                    });
                                    document.location.reload();
                                },
                                () => {
                                    notif({
                                        content: "Une erreur est survenue lors de la suppression",
                                        type: "error",
                                    });
                                }
                            )
                            closeDialog();
                        },
                        onCancel: () => {
                            closeDialog();
                        }
                    })
                }}><Delete fontSize="small" /></IconButton>
            }
        },
    ];

    return (
        <AppPaper>
            {action === "list" && (
                <>
                    <Grid justifyContent="space-between" container>
                        <Grid item>
                            <StyledTitle>Liste des fabricants</StyledTitle>
                        </Grid>
                        {authService.canAccess("WRITE_PARAMETERS") && (
                            <Grid item>
                                <Link to="/models/parameters/device-manufacturers/add">
                                    <Button variant="contained" sx={{ mt: 3 }}>
                                        <AddCircle sx={{ mr: 1 }} /> Ajouter un fabricant
                                    </Button>
                                </Link>
                            </Grid>
                        )}
                    </Grid>

                    <ListPage
                        endpoint={endpoint}
                        fields={listFields}
                        actions={authService.canAccess("WRITE_PARAMETERS") ? actions : []}
                        defaultOrder={['name']}
                    />
                    <BirdzDialog options={dialogOptions} />
                </>
            )}
            {action === "add" && (
                <Item
                    action="add"
                    endpoint={endpoint}
                    fields={formFields}
                    name="Ajouter un fabricant"
                />
            )}
            {action === "edit" && (
                <Item
                    action="edit"
                    endpoint={endpoint}
                    fields={formFields}
                    name="Modifier le fabricant"
                />
            )}
            <BirdzNotif options={notifOptions} />
        </AppPaper>
    );
}

export default DeviceManufacturers;
