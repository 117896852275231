import { AppPaper, SearchField, SearchFormValue } from '@applications-terrains/birdz-react-library';
import React, { useEffect, useState } from 'react';
import CampaignsList from './CampaignsList';
import {
    commonFilters,
    getAvailableSearchFilters,
    purposeFilters,
    searchFields
} from '../search.config';
import { SearchFilters } from '../search.service';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import './CampaignsList.scss';
import SearchFormWithDatePickerRange from '../../../Tools/SearchFormWithDatePickerRange';
import useConvertedDatesForFilters from '../../../../hooks/useConvertedDatesForFilters';

type SearchCampaignsProps = {
    filters: SearchFormValue;
    onFiltersChange(searchFilters: SearchFormValue): void;
};

const SearchCampaigns = ({ filters, onFiltersChange }: SearchCampaignsProps) => {
    const [filtersValues, setFiltersValues] = useState<SearchFormValue>({
        formValue: {},
        filterValues: {}
    });
    const [filtersString, setFiltersString] = useState<string>('');
    const [searchFilters] = useState(new SearchFilters());
    const [campaignsSearchFields, setCampaignsSearchFields] = useState<SearchField[]>([]);
    const { t } = useTranslation();
    const [countHandleSearch, setCountHandleSearch] = useState<number>(0);

    // 'campaign' property set by default in search.config.ts, but 'id' is necessary here (campaign is for searchPurposes)
    const formatFilterString = (str: string) => str.replace('&campaign=', '&id='); 

    useConvertedDatesForFilters({
        filters,
        setFiltersString: (str) => setFiltersString(formatFilterString(str as string))
    });

    const handleSearchFields = () => {
        let campaignsSearchFields = [...searchFields].map((searchField) => {
            if (searchField.name.startsWith('campaign__')) {
                searchField.name = searchField.name.replace('campaign__', '');
            }
            return searchField;
        });

        // Add specific purposes search fields
        const availableSearchFilters = getAvailableSearchFilters(t);
        const createdByFilterPosition = campaignsSearchFields
            .map((searchField) => searchField.name)
            .indexOf('created_by__in');
        campaignsSearchFields.splice(
            createdByFilterPosition + 1,
            0,
            availableSearchFilters['campaignState']
        );
        setCampaignsSearchFields(campaignsSearchFields);
        setCountHandleSearch((count) => count + 1);
    };

    const handleSearchFilters = () => {
        searchFilters.reset();

        if (countHandleSearch === 0) {
            const oneMonthAgo = new Date(moment().add(-1, 'months').format('YYYY-MM-DD'));
            onFiltersChange({
                formValue: {
                    created_at_after:
                        filters.filterValues.campaign__created_at_after || oneMonthAgo,
                    created_at_before: filters.filterValues.campaign__created_at_before,
                    state__in: filters.formValue.campaign__state__in
                },
                filterValues: {
                    created_at_after:
                        filters.filterValues.campaign__created_at_after || oneMonthAgo,
                    created_at_before: filters.filterValues.campaign__created_at_before,
                    state__in: filters.filterValues.campaign__state__in
                }
            });
            searchFilters.add({
                name: 'created_at_after',
                value: filters.filterValues.campaign__created_at_after || oneMonthAgo
            });
            filters.filterValues.campaign__created_at_before &&
                searchFilters.add({
                    name: 'created_at_before',
                    value: filters.filterValues.campaign__created_at_before
                });
        }

        if (filters?.formValue && Object.keys(filters.formValue).length) {
            searchFilters.formatFiltersFromSearchForm(filters.formValue);
            searchFilters.filters.forEach((filter) => {
                if (commonFilters.includes(filter.name)) {
                    searchFilters.rename('campaign__' + filter.name, filter.name);
                }
                if (filter.name.startsWith('campaign__')) {
                    searchFilters.rename(filter.name, filter.name.replace('campaign__', ''));
                }
            });

            // Suppression des filtres inutiles
            purposeFilters.forEach((purposeFilterName: string) => {
                searchFilters.remove(purposeFilterName);
            });
        }

        setFiltersValues(searchFilters.formatToSearchForm());
        setCountHandleSearch((count) => count + 1);
    };

    useEffect(() => {
        setCountHandleSearch(0);
        handleSearchFields();
        handleSearchFilters();
        //eslint-disable-next-line
    }, [filters]);

    return (
        <>
            <AppPaper sx={{ p: 2, pt: 2, mt: 1 }}>
                <SearchFormWithDatePickerRange
                    setFiltersValues={setFiltersValues}
                    filtersValues={filtersValues}
                    fields={campaignsSearchFields}
                    setFilters={(str) => {
                        setFiltersString(formatFilterString(str as string));
                    }}
                    onSubmit={onFiltersChange}
                />
            </AppPaper>
            {countHandleSearch === 2 && campaignsSearchFields.length > 0 && (
                <CampaignsList filters={filtersString} />
            )}
        </>
    );
};

export default SearchCampaigns;
