const DisplayCampaignsName = ({ campaigns }: { campaigns: { id: number; name: string }[] }) => (
    <div className="d-flex">
        {campaigns.length > 0
            ? campaigns.map((el, index) => (
                  <a
                      href={`/sent-list?tab=purposes&campaign=${el.id}`}
                      style={{ textDecoration: 'none', whiteSpace: 'nowrap' }}
                      key={el.id + el.name + index}>
                      {el.name}
                      {index < campaigns.length - 1 ? ',' : ''}
                      &nbsp;
                  </a>
              ))
            : '-'}
    </div>
);

export default DisplayCampaignsName