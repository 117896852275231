import React from "react";
import moment from "moment";
import { Link } from "react-router-dom";
import { BirdzDialog, useDialog, AppPaper, StyledTitle, useNotif, BirdzNotif } from '@applications-terrains/birdz-react-library';
import Axios from "axios";
import { Button, Grid, IconButton } from "@mui/material";
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { Delete, Edit } from "@mui/icons-material";
import ListPageWrapper from "../Tools/ListPageWrapper";

export const providers = [
    { value: "", label: "" },
    { value: 'BIR', label: 'HR Birdz' },
    { value: 'OBS', label: 'Orange Business Service' },
    { value: 'LVO', label: 'Live Objects' },
    { value: 'OBJ', label: 'Objenious' },
    { value: 'ACT', label: 'Actility' },
    { value: 'MOCK', label: 'Mock' }
];

export const Operators = () => {
    const { confirmDialog, closeDialog, dialogOptions } = useDialog();
    const { notif, notifOptions } = useNotif();
    const endpoint = "/api/operators/";
    const listFields = [
        {
            name: 'id',
            label: 'Id',
            orderable: true,
            options: { width: "80px" }
        },
        {
            name: "name",
            label: "Nom",
            orderable: true,
        },
        {
            name: "technical_code",
            label: "Code opérateur OSS",
            orderable: true,
        },
        {
            name: "provider",
            label: "Provider",
            orderable: true,
            transform: (providerCode: string) => {
                return providers.find(provider => provider.value === providerCode)?.label || providerCode;
            }
        },
        {
            name: "type",
            label: "Type",
            options: { width: "150px" },
            orderable: true,
        },
        {
            name: "is_active",
            label: "Actif",
            className: "text-center",
            options: { width: "100px" },
            orderable: true,
        },
        {
            name: "updated_at",
            label: "Date de modification",
            transform: (value: any) => {
                return (
                    (value && moment.unix(value).format("DD/MM/YYYY à HH:mm:ss")) || ""
                );
            },
            options: { width: "220px" },
            orderable: true,
        },
    ];

    const searchFields = [
        {
            name: "name",
            label: "Nom opérateur",
            options: {
                identifier: "name",
                source: "/api/operators/",
                label: "name",
                searchIsMulti: false,
            },
        },
    ];

    const actions = [
        {
            name: "edit",
            render: (value: any) => {
                return <IconButton component={Link} to={`/admin/operators/edit/${value.id}`}>
                    <Edit fontSize="small" />
                </IconButton>
            }
        },
        {
            name: "delete-operator",
            render: (model: any) => {
                return <IconButton onClick={() => {
                    confirmDialog({
                        title: "Supprimer l'opérateur",
                        content: "Êtes-vous sûr de vouloir supprimer cet opérateur?",
                        onValidate: () => {
                            Axios.delete(`${endpoint}${model.id}/`).then(
                                () => {
                                    notif({
                                        content: "L'opérateur a été supprimé avec succès",
                                        type: "success",
                                    });
                                    document.location.reload();
                                },
                                () => {
                                    notif({
                                        content: "Une erreur est survenue lors de la suppression",
                                        type: "error",
                                    });
                                }
                            )
                            closeDialog();
                        },
                        onCancel: () => {
                            closeDialog();
                        }
                    })
                }}> <Delete fontSize="small" />
                </IconButton>
            }
        },
    ];

    return (
        <AppPaper>
            <Grid justifyContent="space-between" container>
                <Grid item>
                    <StyledTitle>Liste des opérateurs</StyledTitle>
                </Grid>
                <Grid item>
                    <Link to="/admin/operators/add">
                        <Button variant="contained" sx={{ mt: 3 }}>
                            <AddCircleIcon sx={{ mr: 1 }} /> Ajouter un opérateur
                        </Button>
                    </Link>
                </Grid>
            </Grid>

            <ListPageWrapper
                endpoint={endpoint}
                fields={listFields}
                actions={actions}
                searchFields={searchFields}
                defaultOrder={['name']}
            />
            <BirdzDialog options={dialogOptions} />
            <BirdzNotif options={notifOptions} />
        </AppPaper >
    );

};

export default Operators;
