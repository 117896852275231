import dataService from "../Common/Services/dataService";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import Select from "react-select";
import { EligibleModule } from "./EligibleModules";
import { Grid } from "@mui/material";

type ModulesProps = {
  modules: EligibleModule[];
  onFilter(filters: EligibleModulesFilters): void;
  filtersModules: EligibleModulesFilters;
};


export type EligibleModulesFilters = {
  communes?: any[],
  modules_deviceTypes?: any[],
  repeaters_deviceTypes?: any[]
}

export default function EligibleModulesSearch({
  modules,
  onFilter,
  filtersModules = {}
}: ModulesProps) {
  const [communesOptions, setCommunesOptions] = useState<{ value: string, label: string }[]>([]);
  const [modulesDeviceTypesOptions, setModulesDeviceTypesOptions] = useState<{ value: number, label: string }[]>([]);
  const [repeatersDeviceTypesOptions, setRepeatersDeviceTypesOptions] = useState<{ value: number, label: string }[]>([]);

  useEffect(() => {

    const communes = _.uniqBy(modules, "commune");
    const communeNames = communes.map((item: any) => {
      return { label: item.commune, value: item.commune };
    })
    setCommunesOptions(_.sortBy(communeNames, ['label']));

    let uniqueModulesDeviceTypes = _.uniqBy(modules, "device_type");
    setModulesDeviceTypesOptions(
      uniqueModulesDeviceTypes.map((module) => {
        return { value: module.device_type, label: dataService.getDeviceType(module.device_type) }
      })
    )

    let uniqueRepeatersDeviceTypes = _.uniqBy(modules, "repeater_device_type");
    setRepeatersDeviceTypesOptions(uniqueRepeatersDeviceTypes.map((module) => {
      return { value: module.repeater_device_type, label: dataService.getDeviceType(module.repeater_device_type) }
    }))
  }, [modules, filtersModules]);

  return (
    <Grid container spacing={1}>
      <Grid item xs={3}>Filtrer par:</Grid>
      <Grid item xs={3}>
        <Select
          options={communesOptions}
          onChange={(values: any) => {
            onFilter({ ...filtersModules, ...{ communes: values } })
          }}
          value={filtersModules.communes}
          isMulti
          name="communes"
          placeholder="Commune"
        />
      </Grid>
      <Grid item xs={3}>
        <Select
          options={modulesDeviceTypesOptions}
          onChange={(values: any) => {
            onFilter({ ...filtersModules, ...{ modules_deviceTypes: values } })
          }}
          isMulti
          value={filtersModules.modules_deviceTypes}
          name="module_deviceTypes"
          placeholder="Device type du module"
        />
      </Grid>
      <Grid item xs={3}>
        <Select
          options={repeatersDeviceTypesOptions}
          onChange={(values: any) => {
            onFilter({ ...filtersModules, ...{ repeaters_deviceTypes: values } })
          }}
          isMulti
          value={filtersModules.repeaters_deviceTypes}
          name="repeaters_deviceTypes"
          placeholder="Device type répéteur"
        />
      </Grid>
    </Grid>
  );
}
