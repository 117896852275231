import SortableSelect from './SortableSelect';
import { Value } from './types';

const SortableSelectWrapper = ({
    onChange = () => {},
    values = [],
    hideMenu,
    placeholder
}: {
    onChange: (data: string[]) => void;
    values: string[];
    hideMenu: boolean;
    placeholder?: string | null;
}) => {
    return (
        <SortableSelect
            values={values?.map((el: string) => ({ label: el, value: el }))}
            setValues={(values: Value[]) => {
                onChange(values?.map((val) => val.value) || []);
            }}
            hideMenu={hideMenu}
            placeholder={placeholder}
        />
    );
};

export default SortableSelectWrapper;
